import { createSelector } from "reselect";
import * as raw from "./raw";
import * as examples from "./examples";
import { fromTags } from "../../utils/breadcrumbs";

export const tags = raw.tags;
export const tag = raw.tag;

export const tagsBasic = createSelector(
    raw.tags,
    _tags => {
        return Object.values(_tags)
            .filter(i => !i.hideInSelect)
            .reduce((output, i) => {
                output[i.id] = i;
                return output;
            }, {});
    },
);

export const tagsWithStats = createSelector(
    raw.tags,
    examples.examplesCountByTag,
    (_tags, _examples) => {
        return Object.values(_tags).reduce((output, tag) => {
            const { id, description = "" } = tag;
            tag.descriptionLength = description.length;
            tag.examplesCount = _examples[id] || 0;
            output[id] = tag;
            return output;
        }, {});
    },
);

export const tagWikiBreadcrumbs = createSelector(
    raw.tag,
    raw.tags,
    (_tag, _tags) => {
        const { id } = _tag || {};
        return fromTags(_tags, id);
    },
);

export const tagWikiChildren = createSelector(
    raw.tag,
    raw.tags,
    (_tag, _tags) => {
        const { id } = _tag || {};

        return Object.values(_tags).filter(tag => tag.parentId === id);
    },
);

export const tagWiki = createSelector(
    raw.tag,
    examples.examplesForTag,
    (_tag, _examples) => {
        return _tag;
    },
);
