import actions from "../sparkActions";

export default dispatch => {
    return {
        getPendings: () => {
            return dispatch(actions.getPendings());
        },
        patchPending: (pendingId, pending) => {
            return dispatch(actions.patchPending(pendingId, pending));
        },
        deletePendings: () => {
            // deletes only the deleted pendings
            return dispatch(actions.deletePendings());
        },
    };
};
