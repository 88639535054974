export default {
    startingDate: "date",
    status: "status",
    duration: "text",
    languages: "text",
    webLink: "url",
    facebookLink: "url",
    medium: "text",
    nextGoal: "text",
    numberOfParticipants: "number",
    hours: "hours",
};
