// @flow
import React, { Component } from "react";
import "./ContentBlank.scss";
import contextTypes from "../contextTypes";

export class ContentBlank extends Component {
    state = {};

    render() {
        return (
            <div className="ContentBlank-container">
                <div className="line line-1" />
                <div className="line line-2" />
                <div className="line line-3" />
                <div className="line line-4" />
                <div className="line line-5" />
            </div>
        );
    }
}

ContentBlank.contextTypes = contextTypes;

export default ContentBlank;
