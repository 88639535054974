import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "GET_EXAMPLES":
            data = await globals.fetch(
                pathFromParams("/examples/:layerId", action),
                "GET",
            ); // prettier-ignore;
            store.dispatch(actions.setExamples(action.layerId, data.examples));
            break;

        case "ADD_EXAMPLE":
            data = await globals.fetch(
                pathFromParams("/examples/:layerId/", action),
                "POST",
                { example: value },
            ); // prettier-ignore;
            action.value = data.example || {};
            action.exampleId = action.value.id;
            break;

        case "PATCH_EXAMPLE":
            data = await globals.fetch(
                pathFromParams("/examples/:layerId/:exampleId", action),
                "PUT",
                { example: value },
            ); // prettier-ignore;
            break;

        case "DELETE_EXAMPLE":
            data = await globals.fetch(
                pathFromParams("/examples/:layerId/:exampleId", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        default:
    }
    return data;
};
