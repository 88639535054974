import { put, take, select, call } from "redux-saga/effects";
import actions from "../sparkActions";
import * as appState from "../selectors/appState";
import { isMobile } from "../../utils/screen";
import { getDebates } from "../../utils/firebase-rest";
import globals from "../../utils/globals";

function* root() {
    try {
        while (true) {
            yield take("START");

            const mobile = isMobile();
            const overlay = yield select(appState.overlay);
            const offline = yield select(appState.offline);

            if (!offline) {
                yield getDebates();
            }

            yield put(actions.getDebates());
            yield put(actions.getPersonas());
            yield put(actions.getExamples(overlay));
            yield put(actions.getTags("lofs"));
            yield put(actions.getTags("nvcs"));
            yield put(actions.patchAppState({ mobile }));

            const res = yield call(globals.fetch, "/site");
            yield put(actions.patchAppState(res));
        }
    } finally {
    }
}

export default root;
