// @flow
import React, { Component } from "react";
import "./BlogBoxes.scss";
import contextTypes from "../contextTypes";
import BlogBox from "../blog-box/BlogBox";
import BlogStripe from "../blog-stripe/BlogStripe";

export class BlogBoxes extends Component {
    state = {};

    get items() {
        const { items } = this.props;
        return items;
    }

    render() {
        return (
            <div className="BlogBoxes-container">
                <div className="row">
                    <BlogBox size={1} blogPost={this.items[0]} />
                    <BlogBox size={2} blogPost={this.items[1]} />
                </div>
                <div className="row">
                    <BlogBox size={2} blogPost={this.items[2]} />
                    <BlogBox size={1} flip={true} blogPost={this.items[3]} />
                </div>
                <div className="row">
                    <BlogBox size={3} blogPost={this.items[4]} />
                    <BlogBox size={3} blogPost={this.items[5]} />
                    <BlogBox size={3} blogPost={this.items[6]} />
                </div>
                <BlogStripe blogPost={this.items[7]} />
            </div>
        );
    }
}

BlogBoxes.contextTypes = contextTypes;

export default BlogBoxes;
