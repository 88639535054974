const marginBottom = 22;

export default {
    1: {
        type: "rounded-rect",
        style: {
            x: 40,
            y: 10,
            width: 470,
            height: 300,
            r: 10,
            color: "white",
            dropShadow: "0 0 20px rgba(0,0,0,0.1)",
        },
    },
    2: {
        field: "authorImageUrl",
        type: "avatar",
        style: {
            x: 442,
            y: 30,
            width: 40,
            clip: true,
        },
    },

    3: {
        field: "authorName",
        type: "text",
        style: {
            x: 432,
            y: 46,
            textAlign: "right",
            fontColor: "#365899",
            fontSize: 14,
            fontWeight: 600,
            direction: "rtl",
        },
    },
    4: {
        field: "dateDebate",
        type: "date",
        style: {
            x: 432,
            y: 62,
            textAlign: "right",
            fontColor: "#616770",
            fontSize: 12,
            direction: "rtl",
        },
    },
    5: {
        field: "content",
        type: "content",
        style: {
            x: 483,
            y: 70,
            lineHeight: 22,
            textAlign: "right",
            fontSize: 14,
            fontColor: "#1d2129",
            direction: "rtl",
        },
    },
    6: {
        field: "imageUrl",
        type: "image",
        style: {
            x: 55,
            y: 90,
            width: 430,
            yAddByLine: ["content"],
        },
    },
    7: {
        type: "image",
        style: {
            x: 460,
            y: 90 + marginBottom,
            yAddByLine: ["content", "imageUrl"],
            width: 18,
            height: 18,
            url: "/images/thumb-up.svg",
            color: "green",
            opacity: 0.7,
        },
    },
    8: {
        field: "originLikes",
        type: "text",
        style: {
            x: 450,
            y: 105 + marginBottom,
            yAddByLine: ["content", "imageUrl"],
            textAlign: "right",
            fontColor: "#333",
            fontSize: 14,
            opacity: 0.7,
        },
    },
    9: {
        type: "image",
        style: {
            x: 390,
            y: 90 + marginBottom,
            yAddByLine: ["content", "imageUrl"],
            width: 18,
            height: 18,
            url: "/images/comments.svg",
            color: "green",
            opacity: 0.7,
        },
    },
    10: {
        field: "originComments",
        type: "text",
        style: {
            x: 380,
            y: 105 + marginBottom,
            yAddByLine: ["content", "imageUrl"],
            textAlign: "right",
            fontColor: "#333",
            fontSize: 14,
            opacity: 0.7,
        },
    },
    11: {
        type: "line",
        style: {
            x: 63,
            y: 85 + marginBottom,
            width: 420,
            yAddByLine: ["content", "imageUrl"],
            color: "#aaa",
        },
    },
    12: {
        type: "line",
        style: {
            x: 63,
            y: 115 + marginBottom,
            width: 420,
            yAddByLine: ["content", "imageUrl"],
            color: "#aaa",
        },
    },
    13: {
        type: "image",
        style: {
            x: -70,
            y: 32,
            width: 200,
            height: 61,
            opacity: 0.8,
            rotate: -0.23,
        },
    },
};
