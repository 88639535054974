export const persona = (state = {}, action) => {
    switch (action.type) {
        case "ADD_PERSONA":
            return action.value;

        case "PATCH_PERSONA":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const personas = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_PERSONAS":
            return action.value;

        case "ADD_PERSONA":
        case "PATCH_PERSONA":
            return {
                ...state,
                [action.personaId]: persona(state[action.personaId], action),
            };

        case "DELETE_PERSONA":
            newState = { ...state };
            delete newState[action.personaId];
            return newState;

        default:
            return state;
    }
};
