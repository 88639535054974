import offline from "../constants/offline";

const response = data => {
    return {
        json: () => {
            return data;
        },
    };
};

export const fetchOffline = (url = "", options) => {
    return new Promise(resolve => {
        let res = response({ success: true });

        const endpoints = offline();

        let endpointPath, param;

        Object.keys(endpoints).forEach(key => {
            const regex = new RegExp(key);
            const match = url.match(regex);

            if (match && match[0] === url) {
                endpointPath = key;
                param = match[1];
            }
        });

        if (!endpointPath) {
            resolve(res);
        }

        let data;

        const endpoint = endpoints[endpointPath] || {};
        const getData = endpoint[options.method];

        if (getData) {
            data = getData(options, param);
            res = response(data);
        }

        resolve(res);
    });
};
