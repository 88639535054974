// @flow
import React, { Component } from "react";
import "./FacebookButton.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";

const noop = () => {};

export class FacebookButton extends Component {
    static defaultProps = {
        signIn: () => noop,
        signOut: () => noop,
    };

    get isLoggedIn() {
        const { user } = this.props;

        return user;
    }

    get text() {
        const { i18n } = this.context;

        return this.isLoggedIn ? i18n.logout : i18n.loginFacebook;
    }

    click = () => {
        if (this.isLoggedIn) {
            this.props.signOut();
        } else {
            this.props.signIn({ type: "facebook" });
        }
    };

    render() {
        const { small } = this.props;

        const className = classnames("FacebookButton-container", {
            small,
        });

        return (
            <button onClick={this.click} className={className}>
                <i className="fab fa-facebook" /> {this.text}
            </button>
        );
    }
}

FacebookButton.contextTypes = contextTypes;

export default FacebookButton;
