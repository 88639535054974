import { appState } from "./states/appState";
import { builder } from "./states/builder";
import { bons } from "./states/bons";
import { contacts } from "./states/contacts";
import { personas } from "./states/personas";
import { pendings } from "./states/pendings";
import { modal } from "./states/modal";
import { dumps } from "./states/dumps";
import { debates } from "./states/debates";
import { examples } from "./states/examples";
import { sessions } from "./states/sessions";
import { tags } from "./states/tags";
import { blogs } from "./states/blog";

export default {
    appState,
    bons,
    builder,
    contacts,
    personas,
    pendings,
    modal,
    debates,
    dumps,
    examples,
    sessions,
    tags,
    blogs,
};
