export default ` <h2>Roadmap</h2>

<h3>Definitions</h3>
<ul>
    <li>
        <strong>Tagger:</strong> A person who tags lofs
    </li>
    <li>
        <strong>Importer:</strong> A person who imports debates from social networks
    </li>
    <li>
        <strong>Sign:</strong> A link to a specific debate analysis posted on the debate itself
    </li>
    <li>
        <strong>Count:</strong> The total number of debates currently available on the site
    </li>
    <li>
        <strong>Visits:</strong> The total number of visits to the site
    </li>
    <li>
        <strong>Tags:</strong> The total number of tags on the site
    </li>
    <li>
        <strong>Flavour:</strong> The type of the media: text, image or video
    </li>
    <li>
        <strong>Views:</strong> The number of views on the site
    </li>
    <li>
        <strong>Visit duration:</strong> Average visit duration for visits with 10+ seconds
    </li>
    <li>
        <strong>Swipes:</strong> Number of viewed discussions in one session
    </li>
    <li>
        <strong>Baked:</strong> A discussion fully analyzed
    </li>
    <li>
        <strong>Half baked:</strong> A half analyzed discussion
    </li>
    <li>
        <strong>Pending:</strong> Waiting to be tagged
    </li>
</ul>
<h3>June 2019</h3>
<h4>Core KPIs</h4>
<ul>
    <li>
        <strong>Number of taggers:</strong> 10
    </li>
    <li>
        <strong>Number of signs:</strong> 200
    </li>
    <li>
        <strong>Daily visits:</strong> 300
    </li>
    <li>
        <strong>Visit duration:</strong> 60s
    </li>
</ul>
<h4>Plan</h4>
<ul>
    <li>Paid taggers</li>
    <li>Personal lofs training</li>
    <li>Play with different flavours</li>
</ul>`;
