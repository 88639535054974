// @flow
import React, { Component } from "react";
import "./Project.scss";
import contextTypes from "../contextTypes";
import Content from "./ProjectContent";
import Tag from "../tag/Tag";
import ProjectTable from "../project-table/ProjectTable";
import globals from "../../utils/globals";

export class Project extends Component {
    state = {};

    render() {
        const { id, title, description, status } = globals.config.pages.about;

        return (
            <div className="Project-container">
                <div className="header">
                    <h2 id={id}>{title}</h2>
                    <Tag status={status} />
                </div>
                <div className="body">
                    <Content content={description} />
                    <ProjectTable project={globals.config.pages.about} />
                </div>
            </div>
        );
    }
}

Project.contextTypes = contextTypes;

export default Project;
