import { lazy } from "react";

import { withSelector } from "../utils/withSelector";
import thunks from "../spark_modules/sparkThunks";
import UserBar from "../layout_modules/user-bar/UserBar";
import Toast from "../layout_modules/toast/Toast";
import SideMenu from "../layout_modules/side-menu/SideMenu";
import Wiki from "../layout_modules/wiki/Wiki";
import RootModal from "../layout_modules/root-modal/RootModal";

const XrayToggle = lazy(() => import( "../layout_modules/xray-toggle/XrayToggle")); // prettier-ignore
const IconBar = lazy(() => import( "../layout_modules/icon-bar/IconBar")); // prettier-ignore
const Stacks = lazy(() => import( "../layout_modules/stacks/Stacks")); // prettier-ignore
const Breadcrumbs = lazy(() => import( "../layout_modules/breadcrumbs/Breadcrumbs")); // prettier-ignore

export default {
    rootModal: withSelector("RootModal", thunks)(RootModal), // prettier-ignore
    iconBar: withSelector("IconBar", thunks)(IconBar), // prettier-ignore
    xrayToggle: withSelector("XrayToggle", thunks)(XrayToggle), // prettier-ignore
    userBar: withSelector("UserBar", thunks)(UserBar), // prettier-ignore
    wiki: withSelector("Wiki", thunks)(Wiki), // prettier-ignore
    stacks: withSelector("Stacks", thunks)(Stacks), // prettier-ignore
    breadcrumbs: withSelector("Breadcrumbs", thunks)(Breadcrumbs), // prettier-ignore
    stacksClean: withSelector("StacksClean", thunks)(Stacks), // prettier-ignore
    toast: withSelector("Toast", thunks)(Toast), // prettier-ignore
    sideMenu: withSelector("SideMenu", thunks)(SideMenu) // prettier-ignore
};
