// @flow
import React, { Component } from "react";
import "./BlogImage.scss";
import contextTypes from "../../contextTypes";

export class BlogImage extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    get data() {
        const { data } = this.element;
        return data || {};
    }

    render() {
        const { url, caption = "" } = this.data;

        return (
            <div className="BlogImage-container">
                <img src={url} alt="graph" />
                <div className="caption">{caption}</div>
            </div>
        );
    }
}

BlogImage.contextTypes = contextTypes;

export default BlogImage;
