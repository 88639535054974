// @flow
import React, { Component } from "react";
import "./BlogFooter.scss";
import contextTypes from "../contextTypes";

export class BlogFooter extends Component {
    state = {};

    render() {
        const { i18n } = this.context;

        return <div className="BlogFooter-container">{i18n.blogFooter}</div>;
    }
}

BlogFooter.contextTypes = contextTypes;

export default BlogFooter;
