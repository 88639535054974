import { createSelector } from "reselect";

export const appStateRaw = state => state.appState || {};

export const currentIds = createSelector(
    appStateRaw,
    appState => appState.currentIds || {},
);

export const viewIds = createSelector(
    appStateRaw,
    appState => appState.viewIds || {},
);

export const debateId = createSelector(
    currentIds,
    _currentIds => _currentIds.debId || 0,
);

export const commentId = createSelector(
    currentIds,
    _currentIds => _currentIds.commentId || 0,
);

export const tagId = createSelector(
    currentIds,
    _currentIds => _currentIds.tagId || 0,
);

export const blogPostId = createSelector(
    currentIds,
    _currentIds => _currentIds.blogPostId || 0,
);

export const elementId = createSelector(
    currentIds,
    _currentIds => _currentIds.elementId || 0,
);

export const filterTagId = createSelector(
    currentIds,
    _currentIds => _currentIds.filterTagId || 0,
);

export const exampleId = createSelector(
    currentIds,
    _currentIds => _currentIds.exampleId || 0,
);

export const sessionId = createSelector(
    currentIds,
    _currentIds => _currentIds.exampleId || 0,
);

export const sentenceId = createSelector(
    currentIds,
    _currentIds => _currentIds.sentenceId || 0,
);

export const contactId = createSelector(
    currentIds,
    _currentIds => _currentIds.contactId || 0,
);

export const personaId = createSelector(
    currentIds,
    _currentIds => _currentIds.personaId || 0,
);

export const pendingId = createSelector(
    currentIds,
    _currentIds => _currentIds.pendingId || 0,
);

export const dumpId = createSelector(
    currentIds,
    _currentIds => _currentIds.dumpId || 0,
);

export const bonId = createSelector(
    currentIds,
    _currentIds => _currentIds.bonId || 0,
);

export const bonIdHover = createSelector(
    currentIds,
    _currentIds => _currentIds.bonIdHover || 0,
);

export const overlay = createSelector(
    appStateRaw,
    appState => appState.overlay || 0,
);

export const isInputFocused = createSelector(
    appStateRaw,
    appState => appState.isInputFocused || false,
);

export const user = createSelector(
    appStateRaw,
    appState => appState.user,
);

export const toast = createSelector(
    appStateRaw,
    appState => appState.toast,
);

export const showSideMenu = createSelector(
    appStateRaw,
    appState => appState.showSideMenu || false,
);

export const offline = createSelector(
    appStateRaw,
    appState => appState.offline || false,
);

export const xray = createSelector(
    appStateRaw,
    appState => appState.xray,
);

export const xrayEdit = createSelector(
    appStateRaw,
    appState => appState.xray && appState.editMode,
);

export const editMode = createSelector(
    appStateRaw,
    appState => appState.editMode,
);

export const selectedSentencesRaw = createSelector(
    appStateRaw,
    appState => appState.selectedSentences,
);

export const isAdmin = createSelector(
    appStateRaw,
    appState => appState.isAdmin,
);

export const role = createSelector(
    appStateRaw,
    appState => appState.role,
);

export const barMode = createSelector(
    appStateRaw,
    appState => appState.barMode,
);

export const isMobile = createSelector(
    appStateRaw,
    appState => appState.mobile,
);

export const showBack = createSelector(
    appStateRaw,
    appState => appState.showBack,
);

export const isLoading = createSelector(
    appStateRaw,
    appState => appState.isLoading,
);

export const env = createSelector(
    appStateRaw,
    appState => appState.env,
);

export const site = createSelector(
    appStateRaw,
    appState => appState.site,
);

export const selectedSentences = createSelector(
    selectedSentencesRaw,
    selectedSentences => {
        return Object.keys(selectedSentences)
            .filter(key => selectedSentences[key])
            .map(i => parseInt(i, 10));
    },
);
