// @flow
import React, { Component } from "react";
import "./ProjectContent.scss";
import contextTypes from "../contextTypes";
import { Fragment } from "react";

export class Content extends Component {
    state = {};

    get value() {
        return (this.props.content || "")
            .trim()
            .split("\n")
            .map((text, index) => {
                return (
                    <Fragment key={index}>
                        {text}
                        <br />
                    </Fragment>
                );
            });
    }

    get style() {
        const { content = "" } = this.props;

        const isRTL = content.search(/[א-ת]/) >= 0;
        const direction = isRTL ? "rtl" : "ltr";

        return {
            direction,
        };
    }

    render() {
        return (
            <div className="Content-container" style={this.style}>
                {this.value}
            </div>
        );
    }
}

Content.contextTypes = contextTypes;

export default Content;
