// @flow
import React, { Component } from "react";
import "./SendSuggestion.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";
import globals from "../../utils/globals";

export class SendSuggestion extends Component {
    state = {
        value: "",
    };

    constructor() {
        super();

        this.input = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.focus();
        });
    }

    focus = () => {
        this.input.current.focus();
    };

    send = async () => {
        const { i18n } = this.context;

        const { value } = this.state;

        if (!value) {
            this.focus();
            return;
        }

        const suggestion = {
            url: value,
        };

        this.setState({ isSaving: true });

        const res = await globals.fetch("/suggestions", "POST", {
            suggestion,
        });

        const { errorCode } = res;

        if (errorCode) {
            this.context.showToast(i18n.errorSending, "error");
            this.setState({ isSaving: false });
            return false;
        }

        this.props.history.push("/thankyou/debate");
    };

    change = ev => {
        this.setState({ value: ev.target.value });
    };

    render() {
        const { i18n } = this.context;
        const { value, isSaving } = this.state;

        return (
            <div className="SendSuggestion-container">
                <div className="content">
                    <h1>{i18n.suggestionTitle}</h1>
                    <h2>{i18n.suggestionSubtitle}</h2>
                    <input
                        value={value}
                        onChange={this.change}
                        ref={this.input}
                        type="text"
                        placeholder="https://www.facebook.com/facebook/posts/10157626447381429"
                    />
                    <button disabled={isSaving} onClick={this.send}>
                        {i18n.suggestionSend}
                    </button>
                    <div className="take-part">
                        {i18n.suggestionTakePart}
                        <Link to="/contact">{i18n.suggestionContact}</Link>
                    </div>
                </div>
            </div>
        );
    }
}

SendSuggestion.contextTypes = contextTypes;

export default SendSuggestion;
