import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import unix from "./unix";
import future from "./future";
import timestamp from "./timestamp";
import duration from "./duration";
import celebrate from "./celebrate";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(relativeTime);

dayjs.extend(advancedFormat);
dayjs.extend(timestamp);
dayjs.extend(unix);
dayjs.extend(future);
dayjs.extend(duration);
dayjs.extend(celebrate);
