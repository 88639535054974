import appState from "./appState";
import bons from "./bons";
import builder from "./builder";
import contacts from "./contacts";
import blog from "./blog";
import debates from "./debates";
import examples from "./examples";
import sessions from "./sessions";
import personas from "./personas";
import pendings from "./pendings";
import dumps from "./dumps";
import tags from "./tags";

export default {
    appState,
    bons,
    builder,
    blog,
    contacts,
    debates,
    examples,
    sessions,
    personas,
    pendings,
    dumps,
    tags,
};
