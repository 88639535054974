import actions from "../sparkActions";
import globals from "../../utils/globals";

export default dispatch => {
    return {
        getBlogPosts: () => {
            return dispatch(actions.getBlogPosts());
        },
        getBlogPost: blogPostId => {
            return dispatch(actions.getBlogPost(blogPostId));
        },
        addBlog: blog => {
            return dispatch(actions.addBlog(blog.id, blog));
        },
        deleteBlog: blogPostId => {
            return dispatch(actions.patchBlog(blogPostId, { isDeleted: true }));
        },
        editBlogElement: (blogPostId, elementId) => {
            dispatch(actions.patchCurrentId({ blogPostId, elementId }));

            dispatch(
                actions.setModal({
                    id: "editBlogElement",
                    props: {},
                }),
            );
        },
        getBlogMapping: blogIndex => {
            return globals.fetch(`/blog/map/${blogIndex}`);
        },
        patchBlog: (blogPostId, value) => {
            return dispatch(actions.patchBlog(blogPostId, value));
        },
        patchBlogProgress: (blogPostId, value) => {
            return dispatch(actions.patchBlogProgress(blogPostId, value));
        },
        patchBlogCard: (blogPostId, value) => {
            return dispatch(actions.patchBlogCard(blogPostId, value));
        },
        patchElement: (blogPostId, elementId, value) => {
            return dispatch(actions.patchElement(blogPostId, elementId, value));
        },
        deleteElement: (blogPostId, elementId) => {
            return dispatch(actions.deleteElement(blogPostId, elementId));
        },
        addElement: (blogPostId, element) => {
            return dispatch(actions.addElement(blogPostId, 0, element));
        },
    };
};
