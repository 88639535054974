// @flow
import React, { Component } from "react";
import "./CommentStats.scss";
import contextTypes from "../contextTypes";
import { overlays } from "../../constants/menus";
import classnames from "classnames";

export class CommentStats extends Component {
    state = {};

    renderItem = item => {
        const { i18n } = this.context;
        const { stats } = this.props;
        const { id, icon, titleKey } = item;

        const className = classnames("item", { selected: false });
        const badge = stats[id];

        if (!badge) return null;

        return (
            <div title={i18n[titleKey]} className={className} key={id}>
                <div className={`icon ${icon}`} />
                <div className="badge">{stats[id]}</div>
            </div>
        );
    };

    render() {
        return (
            <div className="CommentStats-container">
                {Object.values(overlays)
                    .filter(overlay => overlay.showInComment)
                    .map(overlay => this.renderItem(overlay))}
            </div>
        );
    }
}

CommentStats.contextTypes = contextTypes;

export default CommentStats;
