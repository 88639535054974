// @flow
import React, { Component } from "react";
import "./BlogVideo.scss";
import contextTypes from "../../contextTypes";
import ContentEditable from "../../content-editable/ContentEditable";
import Icon from "../../icons/Icon";
import VideoPlayer from "../../video-player/VideoPlayer";

export class BlogVideo extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    get data() {
        const { data } = this.element;
        return data || {};
    }

    render() {
        const { url, caption = "" } = this.data;

        return (
            <div className="BlogVideo-container">
                <div className="delete">
                    <Icon
                        name="delete"
                        size={30}
                        color="#aaa"
                        onClick={this.onDelete}
                    />
                </div>
                <VideoPlayer url={url} height={350} />

                <div className="caption">{caption}</div>
            </div>
        );
    }
}

BlogVideo.contextTypes = contextTypes;

export default BlogVideo;
