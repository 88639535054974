// @flow
import React, { Component } from "react";
import "./BlogPost.scss";
import contextTypes from "../contextTypes";
import * as breadcrumbs from "../../constants/breadcrumbs";
import BlogElement from "../elements/BlogElement.read";
import components from "../../config/client.components";
import "../blog-post-editor/BlogTypography.scss";
import { isMobile } from "../../utils/screen";

export class BlogPost extends Component {
    state = {};

    get blogPostIndex() {
        const { match } = this.props,
            { params } = match || {},
            { blogPostIndex } = params || {};

        return blogPostIndex;
    }

    get post() {
        const { blogPost } = this.state;
        return blogPost || {};
    }

    get elements() {
        const { elements } = this.post;

        return Object.values(elements || {}).sort((a, b) => {
            if (a.order === b.order) return 0;

            return a.order > b.order ? 1 : -1;
        });
    }

    get element() {
        const { selectedElementId } = this.state;
        const { elements } = this.post;

        return elements[selectedElementId] || {};
    }

    async componentDidMount() {
        const blogPostIndex = this.blogPostIndex;

        if (isNaN(blogPostIndex)) return null;

        const res = await this.props.getBlogMapping(blogPostIndex);
        const { blogPostId } = res || {};

        if (blogPostId) {
            this.props.changeBlogPostId(blogPostId);
            await this.props.getBlogPost(blogPostId);
        }
    }

    componentWillReceiveProps(props) {
        const { blogPost } = props;

        if (blogPost !== this.state.blogPost) {
            this.setState({ blogPost });
        }
    }

    renderElement = element => {
        return (
            <BlogElement
                element={element}
                fetchDebate={this.props.fetchDebate}
                key={element.id}
            />
        );
    };

    renderElements() {
        return this.elements.map(el => this.renderElement(el));
    }

    render() {
        const Breadcrumbs = components.breadcrumbs;

        return (
            <div className="BlogPost-container">
                <div className="content">
                    <Breadcrumbs breadcrumbs={breadcrumbs.blogPost} />

                    <div className="content blog">{this.renderElements()}</div>
                </div>
            </div>
        );
    }
}

BlogPost.contextTypes = contextTypes;

export default BlogPost;
