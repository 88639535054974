export const currentIds = (state = {}, action) => {
    switch (action.type) {
        case "SET_APPSTATE_CURRENTIDS":
            return action.value;

        case "PATCH_APPSTATE_CURRENTID":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const viewIds = (state = {}, action) => {
    switch (action.type) {
        case "SET_APPSTATE_VIEWID":
            return action.value;

        case "PATCH_APPSTATE_VIEWID":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const selectedSentences = (state = {}, action) => {
    switch (action.type) {
        case "SET_APPSTATE_SELECTED_SENTENCES":
            return { ...action.value };

        case "PATCH_APPSTATE_SELECTED_SENTENCES":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const tree = (state = {}, action) => {
    switch (action.type) {
        case "SET_APPSTATE_TREE":
            return action.value;

        case "PATCH_APPSTATE_TREE":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const appState = (state = {}, action) => {
    switch (action.type) {
        case "SET_APPSTATE":
            return action.value;

        case "PATCH_APPSTATE":
            return {
                ...state,
                ...action.value,
            };

        case "SET_APPSTATE_CURRENTIDS":
        case "PATCH_APPSTATE_CURRENTID":
            return {
                ...state,
                currentIds: currentIds(state.currentIds, action),
            };

        case "SET_APPSTATE_VIEWIDS":
        case "PATCH_APPSTATE_VIEWID":
            return {
                ...state,
                viewIds: viewIds(state.viewIds, action),
            };

        case "SET_APPSTATE_SELECTED_SENTENCES":
        case "PATCH_APPSTATE_SELECTED_SENTENCES":
            return {
                ...state,
                selectedSentences: selectedSentences(state.currentIds, action),
            };

        case "SET_APPSTATE_TREE":
        case "PATCH_APPSTATE_TREE":
            return {
                ...state,
                currentIds: tree(state.currentIds, action),
            };

        default:
            return state;
    }
};
