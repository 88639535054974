export const setBuilder = (value) => ({type: "SET_BUILDER", value}); // prettier-ignore
export const patchBuilder = (value) => ({type: "PATCH_BUILDER", value}); // prettier-ignore
export const setBuilderDebate = (value) => ({type: "SET_BUILDER_DEBATE", value}); // prettier-ignore
export const patchBuilderDebate = (value) => ({type: "PATCH_BUILDER_DEBATE", value}); // prettier-ignore
export const setBuilderComments = (value) => ({type: "SET_BUILDER_DEBATE_COMMENTS", value}); // prettier-ignore
export const addBuilderComment = (value) => ({type: "ADD_BUILDER_DEBATE_COMMENT", value}); // prettier-ignore
export const setBuilderComment = (commentId, value) => ({type: "SET_BUILDER_DEBATE_COMMENT", commentId, value}); // prettier-ignore
export const patchBuilderComment = (commentId, value) => ({type: "PATCH_BUILDER_DEBATE_COMMENT", commentId, value}); // prettier-ignore
export const deleteBuilderComment = (commentId) => ({type: "DELETE_BUILDER_DEBATE_COMMENT", commentId}); // prettier-ignore
export const nudgeBuilderCommentParticipant = (commentId) => ({type: "NUDGE_BUILDER_COMMENT_PARTICIPANT", commentId}); // prettier-ignore
export const setBuilderParticipants = (value) => ({type: "SET_BUILDER_DEBATE_PARTICIPANTS", value}); // prettier-ignore
export const setBuilderParticipant = (participantId, value) => ({type: "SET_BUILDER_DEBATE_PARTICIPANT", participantId, value}); // prettier-ignore
export const patchBuilderParticipant = (participantId, value) => ({type: "PATCH_BUILDER_DEBATE_PARTICIPANT", participantId, value}); // prettier-ignore
export const deleteBuilderParticipant = (participantId) => ({type: "DELETE_BUILDER_DEBATE_PARTICIPANT", participantId}); // prettier-ignore
export const setBuilderRoot = (value) => ({type: "SET_BUILDER_DEBATE_ROOT", value}); // prettier-ignore
export const patchBuilderRoot = (value) => ({type: "PATCH_BUILDER_DEBATE_ROOT", value}); // prettier-ignore
export const setBuilderInfo = (value) => ({type: "SET_BUILDER_DEBATE_INFO", value}); // prettier-ignore
export const patchBuilderInfo = (value) => ({type: "PATCH_BUILDER_DEBATE_INFO", value}); // prettier-ignore
export const setBuilderStats = (value) => ({type: "SET_BUILDER_DEBATE_STATS", value}); // prettier-ignore
export const patchBuilderStats = (value) => ({type: "PATCH_BUILDER_DEBATE_STATS", value}); // prettier-ignore
export const setBuilderProgress = (value) => ({type: "SET_BUILDER_DEBATE_PROGRESS", value}); // prettier-ignore
export const patchBuilderProgress = (value) => ({type: "PATCH_BUILDER_DEBATE_PROGRESS", value}); // prettier-ignore
