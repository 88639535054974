// @flow
import React, { Component } from "react";
import "./Roadmap.scss";
import contextTypes from "../contextTypes";
import globals from "../../utils/globals";

export class Roadmap extends Component {
    state = {};

    render() {
        return (
            <div className="Roadmap-container">
                <div
                    dangerouslySetInnerHTML={{
                        __html: globals.config.pages.roadmap,
                    }}
                />
            </div>
        );
    }
}

Roadmap.contextTypes = contextTypes;

export default Roadmap;
