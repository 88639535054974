const getDividers = (content, regex) => {
    let output = [],
        match;

    while ((match = regex.exec(content))) {
        output.push(match[0]);
    }

    return output;
};

export const fromContent = (content, regex = /[.?!,]+/gi) => {
    const dividers = getDividers(content, regex);

    return content
        .split(regex)
        .map((s, index) => {
            return [s, dividers[index] || "", s === " "];
        })
        .filter(([s]) => {
            return s.length > 0;
        });
};
