import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";
import * as options from "../../constants/options";
import { filterForView } from "../../utils/filter";

export const blogs = raw.blogsRaw;
export const blog = raw.blog;
export const elements = raw.elements;
export const element = raw.element;

export const blogPostTable = createSelector(
    blogs,
    appState.viewIds,
    (_blogs, _viewIds) => {
        const { adminBlogPostId } = _viewIds;
        const filter = filterForView(
            options.viewsAdminBlogPosts,
            adminBlogPostId,
        );

        return Object.values(_blogs)
            .filter(filter)
            .reverse();
    },
);

export const blogPostsList = createSelector(
    blogs,
    _blogs => {
        return Object.values(_blogs || {})
            .filter(blog => {
                const { progress } = blog || {},
                    { isDeleted } = progress || {};

                return !isDeleted;
            })
            .map(blog => {
                const { id, index, card } = blog || {};

                return {
                    id,
                    index,
                    ...card,
                };
            })
            .sort((a, b) => {
                if (a.order === b.order) return 0;

                return parseFloat(a.order) > parseFloat(b.order) ? -1 : 1;
            });
    },
);

export const blogPostsFeatured = createSelector(
    blogPostsList,
    _blogs => {
        return _blogs.filter(blog => blog.showInFeaturedVideos);
    },
);
