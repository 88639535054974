// @flow
import React, { Component } from "react";
import "./Key.scss";
import contextTypes from "../contextTypes";
import Icon from "../icons/Icon";
import classnames from "classnames";

export class Key extends Component {
    state = {};

    get config() {
        const { config } = this.props;
        return config;
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keydown);
    }

    componentWillMount() {
        document.removeEventListener("keydown", this.keydown);
        clearTimeout(this.timeout);
    }

    keydown = ev => {
        const { hotkeyCode } = this.config;

        if (ev.code === hotkeyCode) {
            this.simulateClick();
        }
    };

    renderIcon() {
        const { icon } = this.props;
        const { big } = this.config;

        const size = big ? 50 : 30;

        return <Icon name={icon} size={size} />;
    }

    simulateClick = () => {
        this.setState({ mouseDown: true });
        this.onClick();

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.setState({ mouseDown: false });
        }, 150);
    };

    onClick = () => {
        const { id } = this.config;
        this.props.onClick(id);
    };

    onDoubleClick = () => {
        const { id } = this.config;
        this.props.onClick(id, 2);
    };

    render() {
        const { flavour } = this.props;

        const { id, big, hotkey } = this.config;
        const { mouseDown } = this.state;

        const className = classnames("Key-container", id, flavour, {
            big,
            mouseDown,
        });

        return (
            <button
                className={className}
                onClick={this.onClick}
                onDoubleClick={this.onDoubleClick}>
                {this.renderIcon()}
                <div className="title">{hotkey}</div>
            </button>
        );
    }
}

Key.contextTypes = contextTypes;

export default Key;
