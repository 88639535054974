const noop = () => {};

export const filterAndSortByIndex = (items, search = "", prefix = "") => {
    return Object.values(items)
        .filter(item => item.id)
        .filter(item => {
            const { title = "", titleEn = "" } = item;

            const ok =
                title.indexOf(search) >= 0 || titleEn.indexOf(search) >= 0;

            return item && ok;
        })
        .map(tag => {
            const { index = "" } = tag;
            const indexNumeric = parseInt(index.replace(prefix, ""), 10);
            return { ...tag, indexNumeric };
        })
        .sort((a, b) => {
            let { indexNumeric: indexA } = a,
                { indexNumeric: indexB } = b;

            return indexA - indexB;
        });
};

export const filterForView = (options, viewId) => item => {
    const option = options[viewId] || {},
        { filter } = option || {};

    return (filter || noop)(item);
};
