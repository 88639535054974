// @flow
import React, { Component } from "react";
import "./Toast.scss";
import contextTypes from "../contextTypes";
import ToastMessage from "./ToastMessage";

export class Toast extends Component {
    state = {
        lastToast: null,
        toasts: [],
    };

    componentDidMount() {
        this.timeouts = [];
    }

    addToast = toast => {
        const { toasts } = this.state;
        toast.show = true;
        toasts.push(toast);
        this.setState({ toasts, lastToast: toast });

        this.addTimeout(() => this.removeToast(toast), 3500);
    };

    removeToast = toast => {
        const { toasts } = this.state;

        toast.show = false;

        this.setState({ toasts });
    };

    addTimeout = (callback, delay) => {
        const timeout = setTimeout(callback, delay);
        this.timeouts.push(timeout);
    };

    componentWillReceiveProps(props) {
        const { toast } = props;

        if (this.state.lastToast !== toast) {
            this.addToast(toast);
        }
    }

    componentWillUnmount() {
        this.timeouts.forEach(timeout => clearTimeout(timeout));
    }

    render() {
        const { toasts } = this.state;

        return (
            <div className="Toast-container">
                {toasts.map((toast, index) => (
                    <ToastMessage
                        key={index}
                        type={toast.type}
                        show={toast.show}
                        text={toast.text}
                        close={() => this.removeToast(toast)}
                    />
                ))}
            </div>
        );
    }
}

Toast.contextTypes = contextTypes;

export default Toast;
