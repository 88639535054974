import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";
import * as comments from "./comments";
import * as options from "../../constants/options";
import * as filters from "../../constants/filters";
import { filterForView } from "../../utils/filter";

export const currentDebate = createSelector(
    raw.debate,
    comments.root,
    comments.comments,
    comments.videoHighlights,
    comments.videoStack,
    (debate, root, comments, videoHighlights, videoStack) => {
        return {
            ...debate,
            root,
            comments,
            videoHighlights,
            videoStack,
        };
    },
);

export const currentStats = createSelector(
    currentDebate,
    raw.bons,
    (debate, bons) => {
        let { comments, participants } = debate;

        return {
            commentsCount: Object.values(comments).length,
            participantsCount: Object.values(participants).length,
            bons: Object.values(bons).length,
        };
    },
);

export const debatesTextual = createSelector(
    raw.debatesRaw,
    _debates => {
        return Object.values(_debates).filter(filters.textual);
    },
);

export const debatesCC = createSelector(
    raw.debatesRaw,
    _debates => {
        return Object.values(_debates).filter(filters.cc);
    },
);

export const debatesTable = createSelector(
    debatesTextual,
    appState.viewIds,
    (_debates, _viewIds) => {
        const { adminDebatesId } = _viewIds;
        const filter = filterForView(options.viewsAdminDebates, adminDebatesId);

        return Object.values(_debates)
            .filter(filter)
            .reverse();
    },
);

export const browse = createSelector(
    raw.debatesRaw,
    _debates => {
        return Object.values(_debates)
            .filter(filters.published)
            .reverse();
    },
);

export const isVideo = raw.debateIsVideo;
