export const contact = (state = {}, action) => {
    switch (action.type) {
        case "ADD_CONTACT":
            return action.value;

        case "PATCH_CONTACT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const contacts = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_CONTACTS":
            return action.value;

        case "ADD_CONTACT":
        case "PATCH_CONTACT":
            return {
                ...state,
                [action.contactId]: contact(state[action.contactId], action),
            };

        case "DELETE_CONTACT":
            newState = { ...state };
            delete newState[action.contactId];
            return newState;

        default:
            return state;
    }
};
