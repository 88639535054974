// @flow
import React, { Component } from "react";
import "./Tag.scss";
import contextTypes from "../contextTypes";

export class Tag extends Component {
    state = {};

    get style() {
        const { status } = this.props;

        switch (status) {
            case "running":
                return { backgroundColor: "green" };
            case "soon":
                return { backgroundColor: "#eee", color: "#333" };
            case "future":
                return { backgroundColor: "#333" };
            default:
                return {};
        }
    }

    render() {
        const { i18n } = this.context;
        const { status } = this.props;

        return (
            <div className="Tag-container" style={this.style}>
                {i18n[status]}
            </div>
        );
    }
}

Tag.contextTypes = contextTypes;

export default Tag;
