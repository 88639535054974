// @flow
import React, { Component } from "react";
import "./BlogStripeWhite.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";

export class BlogStripeWhite extends Component {
    state = {};

    get item() {
        const { item } = this.props;
        return item;
    }

    get to() {
        const { index, redirectDebateIndex } = this.item;

        if (redirectDebateIndex) {
            return `/${redirectDebateIndex}`;
        } else {
            return `/blog/${index}`;
        }
    }

    render() {
        const { i18n } = this.context;

        const { title, subtitle, authorName } = this.item;

        return (
            <div className="BlogStripeWhite-container">
                <div className="row">
                    <div className="col col-1">
                        <div className="subtitle">{subtitle}</div>
                        <div className="author">
                            <span className="by">{i18n.by}</span>
                            <span className="authorName">{authorName}</span>
                        </div>
                    </div>
                    <div className="col col-2">
                        <Link to={this.to}>
                            <div className="title">{title}</div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

BlogStripeWhite.contextTypes = contextTypes;

export default BlogStripeWhite;
