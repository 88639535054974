// @flow
import React, { Component } from "react";
import "./ScreenFeed.scss";
import contextTypes from "../contextTypes";
import { ScreenPost } from "../screen-post/ScreenPost";
import { Link } from "react-router-dom";
import scroll from "../../utils/scroll";
import CardSuggestLink from "../card-suggest-link/CardSuggestLink";
import CardBooklet from "../card-booklet/CardBooklet";

export class ScreenFeed extends Component {
    state = {};

    async componentDidMount() {
        scroll.listen("ScreenFeed");
        scroll.refresh("ScreenFeed", false);
    }

    componentWillUnmount() {
        scroll.unlisten("ScreenFeed");
    }

    get debates() {
        const { debates } = this.props;
        const arr = Object.values(debates || {});
        arr.unshift({ type: "suggestion" });
        arr.unshift({ type: "booklet" });
        return arr.filter(debate => {
            const { info } = debate || {},
                { media } = info || {};

            return media !== "video";
        });
    }

    renderDebate = debate => {
        const { i18n } = this.context;

        const { root, type } = debate || {},
            { lofs } = root || {};

        if (type === "suggestion") {
            return <CardSuggestLink />;
        }

        if (type === "booklet") {
            return <CardBooklet />;
        }

        const lofsText = lofs === 1 ? i18n.lofsOne : `${lofs} ${i18n.lofsMany}`;

        return (
            <div className="debate">
                <div className="actions">
                    <div className="lofs">{lofsText}</div>
                    <Link to={`/${debate.index}`} className="button">
                        {i18n.seeAnalysis}
                    </Link>
                </div>
                <ScreenPost debate={debate} compact={true} />
            </div>
        );
    };

    renderDebates() {
        return this.debates.map(debate => this.renderDebate(debate));
    }

    render() {
        return (
            <div className="ScreenFeed-container">
                <div className="content">{this.renderDebates()}</div>
            </div>
        );
    }
}

ScreenFeed.contextTypes = contextTypes;

export default ScreenFeed;
