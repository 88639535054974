export const getDebates = () => ({ type: "GET_DEBATES" }); // prettier-ignore
export const getDebate = (debateId) => ({ type: "GET_DEBATE", debateId }); // prettier-ignore
export const setDebates = (value) => ({ type: "SET_DEBATES", value }); // prettier-ignore
export const addDebate = (debateId, value) => ({ type: "ADD_DEBATE", debateId, value }); // prettier-ignore
export const setDebate = (debateId, value) => ({ type: "SET_DEBATE", debateId, value, silent: true }); // prettier-ignore
export const patchDebate = (debateId, value) => ({ type: "PATCH_DEBATE", debateId, value }); // prettier-ignore
export const deleteDebate = (debateId) => ({ type: "DELETE_DEBATE", debateId }); // prettier-ignore
export const setComments = (commentId, value) => ({ type: "SET_DEBATE_COMMENTS", commentId, value }); // prettier-ignore
export const addComment = (debateId, commentId, value) => ({ type: "ADD_DEBATE_COMMENT", debateId, commentId, value }); // prettier-ignore
export const setComment = (debateId, commentId, value) => ({ type: "SET_DEBATE_COMMENT", debateId, commentId, value, silent: true }); // prettier-ignore
export const patchComment = (debateId, commentId, value) => ({ type: "PATCH_DEBATE_COMMENT", debateId, commentId, value }); // prettier-ignore
export const deleteComment = (debateId, commentId) => ({ type: "DELETE_DEBATE_COMMENT", debateId, commentId }); // prettier-ignore
export const setParticipants = (participantId, value) => ({ type: "SET_DEBATE_PARTICIPANTS", participantId, value }); // prettier-ignore
export const addParticipant = (debateId, participantId, value) => ({ type: "ADD_DEBATE_PARTICIPANT", debateId, participantId, value }); // prettier-ignore
export const setParticipant = (debateId, participantId, value) => ({ type: "SET_DEBATE_PARTICIPANT", debateId, participantId, value, silent: true }); // prettier-ignore
export const patchParticipant = (debateId, participantId, value) => ({ type: "PATCH_DEBATE_PARTICIPANT", debateId, participantId, value }); // prettier-ignore
export const deleteParticipant = (debateId, participantId) => ({ type: "DELETE_DEBATE_PARTICIPANT", debateId, participantId }); // prettier-ignore

export const setDebateStats = (debateId, value) => ({ type: "SET_DEBATE_STATS", debateId, value, silent: true }); // prettier-ignore
export const patchDebateStats = (debateId, value) => ({ type: "PATCH_DEBATE_STATS", debateId, value }); // prettier-ignore

export const setDebateInfo = (debateId, value) => ({ type: "SET_DEBATE_INFO", debateId, value, silent: true }); // prettier-ignore
export const patchDebateInfo = (debateId, value) => ({ type: "PATCH_DEBATE_INFO", debateId, value }); // prettier-ignore

export const setDebateProgress = (debateId, value) => ({ type: "SET_DEBATE_PROGRESS", debateId, value, silent: true }); // prettier-ignore
export const patchDebateProgress = (debateId, value) => ({ type: "PATCH_DEBATE_PROGRESS", debateId, value }); // prettier-ignore

export const setDebateRoot = (debateId, value) => ({ type: "SET_DEBATE_ROOT", debateId, value, silent: true }); // prettier-ignore
export const patchDebateRoot = (debateId, value) => ({ type: "PATCH_DEBATE_ROOT", debateId, value }); // prettier-ignore
