// @flow
import React, { Component } from "react";
import "./BlogBigBox.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export class BlogBigBox extends Component {
    state = {};

    get item() {
        const { item } = this.props;
        return item || {};
    }

    get styleImage() {
        const { imageUrl } = this.item;

        return {
            backgroundImage: `url(${imageUrl})`,
        };
    }

    get to() {
        const { index, redirectDebateIndex } = this.item;

        if (redirectDebateIndex) {
            return `/${redirectDebateIndex}`;
        } else {
            return `/blog/${index}`;
        }
    }

    render() {
        const { i18n } = this.context;
        const { title, subtitle, authorName, dateArticle } = this.item;

        const dateText = dayjs(dateArticle).fromNow();

        return (
            <div className="BlogBigBox-container">
                <div className="header">
                    <Link to={this.to} className="no-link">
                        <div className="title">{title}</div>
                    </Link>
                    <div className="subtitle">{subtitle}</div>

                    <div className="details">
                        <div className="author">
                            <span className="by">{i18n.by}</span>
                            <span className="authorName">{authorName}</span>
                        </div>
                        <span className="pipe">|</span>
                        <div className="date">{dateText}</div>
                    </div>
                </div>
                <Link to={this.to} className="no-link">
                    <div className="image" style={this.styleImage} />
                </Link>
            </div>
        );
    }
}

BlogBigBox.contextTypes = contextTypes;

export default BlogBigBox;
