export const setAppState = (value) => ({type: "SET_APPSTATE", value}); // prettier-ignore
export const patchAppState = (value) => ({type: "PATCH_APPSTATE", value}); // prettier-ignore
export const setCurrentId = (value) => ({type: "SET_APPSTATE_CURRENTIDS", value}); // prettier-ignore
export const patchCurrentId = (value) => ({type: "PATCH_APPSTATE_CURRENTID", value}); // prettier-ignore
export const setViewId = (value) => ({type: "SET_APPSTATE_VIEWIDS", value}); // prettier-ignore
export const patchViewId = (value) => ({type: "PATCH_APPSTATE_VIEWID", value}); // prettier-ignore
export const setTree = (value) => ({type: "SET_APPSTATE_TREE", value}); // prettier-ignore
export const patchTree = (value) => ({type: "PATCH_APPSTATE_TREE", value}); // prettier-ignore
export const setSelectedSentences = (value) => ({type: "SET_APPSTATE_SELECTED_SENTENCES", value}); // prettier-ignore
export const patchSelectedSentences = (value) => ({type: "PATCH_APPSTATE_SELECTED_SENTENCES", value}); // prettier-ignore
