import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import sparkSave from "./sparkSave";
import ReduxThunk from "redux-thunk";
import states from "./sparkStates";
import createSagaMiddleware from "redux-saga";
import cycleRoot from "./sagas/cycleRoot";
import offline from "./sagas/offline";
import globals from "../utils/globals";

const sagaMiddleware = createSagaMiddleware();

globals.sagaMiddleware = sagaMiddleware;

const debdbApp = combineReducers(states);

export const initStore = (initialState = {}) => {
    const store = createStore(
        debdbApp,
        initialState,
        compose(
            applyMiddleware(sagaMiddleware, ReduxThunk, sparkSave),
            window && window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f,
        ),
    );

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./sparkStates", () => {
                store.replaceReducer(states);
            });
        }
    }

    sagaMiddleware.run(cycleRoot);
    sagaMiddleware.run(offline);

    return store;
};

export default initStore;
