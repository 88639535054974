import about from "./about";
import volunteers from "./volunteers";
import roadmap from "./roadmap";
import course from "./course";

export default {
    about,
    volunteers,
    roadmap,
    course,
};
