// @flow
import React, { Component } from "react";
import "./Egg.scss";
import contextTypes from "../contextTypes";
import globals from "../../utils/globals";

export class Egg extends Component {
    state = {
        egg: "-",
    };

    async componentDidMount() {
        const res = await globals.fetch("/egg");
        const { egg } = res || {};

        this.setState({ egg });
    }

    render() {
        const { egg } = this.state,
            { us = "", il = "" } = egg || {};

        return (
            <div className="Egg-container">
                <div className="country">
                    <img src="https://www.countryflags.io/il/flat/64.png" />
                    <div className="count">{il.toLocaleString()}</div>
                </div>
                <div className="country">
                    <img src="https://www.countryflags.io/us/flat/64.png" />
                    <div className="count">{us.toLocaleString()}</div>
                </div>
            </div>
        );
    }
}

Egg.contextTypes = contextTypes;

export default Egg;
