// @flow
import React from "react";
import "./ScreenHomeAbout.scss";
import contextTypes from "../contextTypes";
import Project from "../project/Project";
import WebBar from "../web-bar/WebBar";
import Roadmap from "../roadmap/Roadmap";
import useToTop from "../../hooks/useToTop";

function ScreenHomeAbout(props) {
    useToTop();

    return (
        <div className="ScreenHomeAbout-container info-page">
            <div className="content">
                <WebBar dispatch={props.dispatch} />
                <article className="content">
                    <Project />
                    <br />
                    <Roadmap />
                </article>
            </div>
        </div>
    );
}

ScreenHomeAbout.contextTypes = contextTypes;

export default ScreenHomeAbout;
