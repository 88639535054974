import { lazy } from "react";
import { withSelector } from "../utils/withSelector";
import thunks from "../spark_modules/sparkThunks";

import ScreenRoot  from "../layout_modules/screen-root/ScreenRoot"; // prettier-ignore
import ScreenPanel from "../layout_modules/screen-panel/ScreenPanel"; // prettier-ignore
import ScreenBrowse from "../layout_modules/screen-browse/ScreenBrowse"; // prettier-ignore
import ScreenFeed from "../layout_modules/screen-feed/ScreenFeed"; // prettier-ignore
import CanvasPreview from "../layout_modules/canvas-preview/CanvasPreview"; // prettier-ignore
import ScreenLogout  from  "../layout_modules/screen-logout/ScreenLogout"; // prettier-ignore
import SendSuggestion from "../layout_modules/send-suggestion/SendSuggestion"; // prettier-ignore
import ThankYou from "../layout_modules/thank-you/ThankYou"; // prettier-ignore
import About from "../layout_modules/screen-home/ScreenHomeAbout";
import Volunteer from "../layout_modules/screen-home/ScreenHomeVolunteer";
import Roadmap from "../layout_modules/roadmap/Roadmap";
import Egg from "../layout_modules/egg/Egg";
import Blog from "../layout_modules/blog/Blog";
import BlogPost from "../layout_modules/blog-post/BlogPost";

const ScreenCourse = lazy(() => import("../layout_modules/screen-course/ScreenCourse")); // prettier-ignore
const ScreenContact = lazy(() => import("../layout_modules/screen-home/ScreenHomeContact")); // prettier-ignore
const ScreenCards = lazy(() => import("../layout_modules/screen-cards/ScreenCards")); // prettier-ignore
const ScreenWiki = lazy(() => import("../layout_modules/screen-wiki/ScreenWiki")); // prettier-ignore
const ScreenWikiHome = lazy(() => import("../layout_modules/screen-wiki-home/ScreenWikiHome")); // prettier-ignore

const ScreenShop = lazy(() => import("../layout_modules/screen-shop/ScreenShop")); // prettier-ignore
const ScreenProduct = lazy(() => import("../layout_modules/screen-product/ScreenProduct")); // prettier-ignore
const ScreenCreditCard = lazy(() => import("../layout_modules/screen-credit-card/ScreenCreditCard")); // prettier-ignore
const ScreenPurchaseDone = lazy(() => import("../layout_modules/screen-purchase-done/ScreenPurchaseDone")); // prettier-ignore

const ScreenBooklet = lazy(() => import("../layout_modules/screen-booklet/ScreenBooklet")); // prettier-ignore

const Admin = lazy(() => import("../components/Admin")); // prettier-ignore

let screens = {
    root: ["ScreenRoot", ScreenRoot],
    course: ["I", ScreenCourse],
    panel: ["ScreenPanel", ScreenPanel],
    cards: ["ScreenCards", ScreenCards],
    wikiHome: ["ScreenWikiHome", ScreenWikiHome],
    wiki: ["Wiki", ScreenWiki],
    contact: ["I", ScreenContact],
    browse: ["ScreenBrowse", ScreenBrowse],
    feed: ["ScreenFeed", ScreenFeed],
    canvasPreview: ["ScreenCanvasPreview", CanvasPreview],
    admin: ["I", Admin],
    suggestion: ["I", SendSuggestion],
    thankyou: ["I", ThankYou],
    about: ["I", About],
    volunteer: ["I", Volunteer],
    roadmap: ["I", Roadmap],
    egg: ["I", Egg],
    blog: ["Blog", Blog],
    blogPost: ["BlogPost", BlogPost],
    logout: ["I", ScreenLogout],
    shop: ["I", ScreenShop],
    product: ["I", ScreenProduct],
    purchase: ["I", ScreenCreditCard],
    purchaseDone: ["I", ScreenPurchaseDone],
    booklet: ["I", ScreenBooklet],
};

const digest = () => {
    return Object.keys(screens).reduce((output, key) => {
        const config = screens[key],
            selector = config[0],
            component = config[1];

        output[key] = withSelector(selector, thunks)(component);
        return output;
    }, {});
};

export default {
    get screens() {
        return digest();
    },
    addScreen: (key, selector, component) => {
        screens[key] = [selector, component];
    },
};
