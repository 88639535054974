import { useEffect } from "react";
import scroll from "../utils/scroll";

const useToTop = () => {
    useEffect(() => {
        scroll.to(0);
    }, []);
};

export default useToTop;
