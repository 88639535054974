import pages from "./pages";
import defaults from "./defaults";
import i18n from "./i18n";

export default {
    id: "us",
    fontFamily: "inherit",
    locale: "en",
    ga: "UA-143633751-3",
    server: {
        DOMAIN: "https://gentle-peak-86956.herokuapp.com",
        FIREBASE:
            "https://console.firebase.google.com/u/0/project/giro-2/database/giro-2/data/",
        LOG: false,
        SIGNIN_METHOD: "email",
        FIREBASE_CONFIG: {
            apiKey: "AIzaSyBwJDxqghPc8zA8DzcyMPgXr4THyzag9ug",
            authDomain: "giro-2.firebaseapp.com",
            databaseURL: "https://giro-2.firebaseio.com",
            consoleURL:
                "https://console.firebase.google.com/u/0/project/giro-2/database/giro-2/data",
            projectId: "giro-2",
            storageBucket: "giro-2.appspot.com",
            messagingSenderId: "827671298889",
            HOSTING: "",
        },
    },
    staging: {
        DOMAIN: "http://localhost:3001",
        FIREBASE:
            "https://console.firebase.google.com/u/0/project/debdb-staging/database/debdb-staging/data/",
        LOG: false,
        SIGNIN_METHOD: "email",
        FIREBASE_CONFIG: {
            apiKey: "AIzaSyCxwehfGm-4n1EBGW7TzZJvwMaysjjzYAs",
            authDomain: "debdb-staging.firebaseapp.com",
            databaseURL: "https://debdb-staging.firebaseio.com",
            projectId: "debdb-staging",
            storageBucket: "debdb-staging.appspot.com",
            messagingSenderId: "956902697413",
            appId: "1:956902697413:web:7e9e533bfef38c5c",
        },
    },
    pages,
    defaults,
    i18n,
    icons: {
        arrowLeft: "keyboard_arrow_left",
        arrowRight: "keyboard_arrow_right",
    },
    animations: {
        fadeInLeft: "fadeInLeft",
        fadeInRight: "fadeInRight",
        fadeOutLeft: "fadeOutLeft",
        fadeOutRight: "fadeOutRight",
        slideInRight: "slideInRight",
        slideInLeft: "slideInLeft",
        slideOutRight: "slideOutRight",
        slideOutLeft: "slideOutLeft",
    },
    booklet: {
        root: "/images/us/booklet/",
        pages: 10,
    },
};
