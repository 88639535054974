// @flow
import React, { Component } from "react";
import "./BlogLofDefinition.scss";
import contextTypes from "../../contextTypes";

export class BlogLofDefinition extends Component {
    state = {};

    render() {
        return (
            <div className="BlogLofDefinition-container">BlogLofDefinition</div>
        );
    }
}

BlogLofDefinition.contextTypes = contextTypes;

export default BlogLofDefinition;
