export const getImage = url => {
    if (!url) {
        return Promise.resolve({});
    }

    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.onerror = e => {
            reject(e);
        };

        image.src = url;
    });
};

export const getImageSize = url => {
    if (!url) {
        return Promise.resolve({});
    }

    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = () => {
            let ratio;

            if (image.height) {
                ratio = image.width / image.height;
            }

            resolve({
                width: image.width,
                height: image.height,
                ratio,
            });
        };
        image.onerror = e => {
            reject(e);
        };

        image.src = url;
    });
};

export const imageFromUrl = url => {
    return new Promise(async resolve => {
        let data;

        try {
            data = await fetch(url).then(res => res.blob());
        } catch (e) {
            return;
        }

        let metadata = {
            type: "image/jpeg",
        };
        const file = new File([data], "test.jpg", metadata);
        resolve(file);
    });
};
