// @flow
import React, { Component } from "react";
import "./SideMenu.scss";
import contextTypes from "../contextTypes";
import { withRouter } from "react-router-dom";
import { sideMenu } from "../../constants/menus";
import Icon from "../icons/Icon";
import p from "../../../package.json";
import classnames from "classnames";

export class SideMenu extends Component {
    state = {
        isVisible: false,
    };

    get bodyClasses() {
        return document.querySelector("body").classList;
    }

    set isVisible(value) {
        this.setState({ isVisible: value });

        if (value) {
            this.bodyClasses.add("no-scroll");
        } else {
            this.bodyClasses.remove("no-scroll");
        }
    }

    componentWillReceiveProps(props) {
        const { isVisible } = props;

        if (isVisible !== this.state.isVisible) {
            this.isVisible = isVisible;
        }
    }

    onLink = href => {
        this.props.history.push(href);
        this.props.onClose();
    };

    renderMenuItem = item => {
        const { i18n } = this.context;
        const { titleKey, href } = item;

        const title = i18n[titleKey];

        return (
            <li key={titleKey}>
                <button onClick={() => this.onLink(href)}>{title}</button>
            </li>
        );
    };

    render() {
        const { i18n, animations } = this.context;
        const { isVisible } = this.props;

        if (!isVisible) return null;

        const className = classnames("menu animated", animations.slideInLeft);

        return (
            <div className="SideMenu-container">
                <nav className={className}>
                    <div className="actions">
                        <Icon
                            name="close"
                            onClick={this.props.onClose}
                            color={"#aaa"}
                            size={30}
                            className="icon"
                        />
                    </div>

                    <ul>
                        {Object.values(sideMenu).map(item =>
                            this.renderMenuItem(item),
                        )}
                    </ul>
                    <div className="version">
                        {i18n.version} {p.version}
                    </div>
                </nav>
                <div className="overlay" onClick={this.props.onClose} />
            </div>
        );
    }
}

SideMenu.contextTypes = contextTypes;

export default withRouter(SideMenu);
