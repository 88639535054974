// @flow
import React, { Component } from "react";
import "./BlogRoot.scss";
import contextTypes from "../../contextTypes";
import Loader from "../../loader/Loader";
import { ScreenPost } from "../../screen-post/ScreenPost";

export class BlogRoot extends Component {
    state = {
        debate: {},
        isLoading: true,
    };

    get element() {
        const { element } = this.props;
        return element || {};
    }

    get data() {
        const { data } = this.element;
        return data || {};
    }

    get debate() {
        const { debate } = this.state;
        return debate || {};
    }

    loadDebate = async debateId => {
        const res = await this.props.fetchDebate(debateId);
        const { debate } = res || {};
        this.setState({ debate, isLoading: false });
    };

    componentDidMount() {
        const { debateId } = this.data;
        this.loadDebate(debateId);
    }

    renderLoader() {
        return (
            <div className="loader-panel">
                <Loader size={40} />
            </div>
        );
    }

    renderInner = () => {
        const { isLoading } = this.state;

        if (isLoading) {
            return this.renderLoader();
        }

        return <ScreenPost debate={this.debate} compact={true} />;
    };

    render() {
        return <div className="BlogRoot-container">{this.renderInner()}</div>;
    }
}

BlogRoot.contextTypes = contextTypes;

export default BlogRoot;
