export const addDebate = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsDebates",
        href: "/admin/debates",
    },
    {
        titleKey: "breadcrumbsAddDebate",
    },
];

export const studioCC = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsSubtitles",
    },
];

export const addCC = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsSubtitles",
        href: "/admin/cc",
    },
    {
        titleKey: "breadcrumbsAddSubtitles",
    },
];

export const addExample = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsExamples",
        href: "/admin/examples",
    },
    {
        titleKey: "breadcrumbsAddExample",
    },
];

export const addPersona = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsPersonas",
        href: "/admin/personas",
    },
    {
        titleKey: "breadcrumbsAddPersona",
    },
];

export const blogEditor = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsBlog",
        href: "/admin/blog",
    },
    {
        titleKey: "breadcrumbsBlogEditor",
    },
];

export const caeser = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsPending",
        href: "/admin/pending",
    },
    {
        titleKey: "breadcrumbsOneByOne",
    },
];

export const debateManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsDebates",
    },
];

export const blogManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsBlogPosts",
    },
];

export const examplesManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsExamples",
    },
];

export const manager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
];

export const pendingManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsPending",
    },
];

export const dumpManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsDumps",
    },
];

export const personasManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsPersonas",
    },
];

export const tagManager = [
    {
        titleKey: "breadcrumbsManager",
        href: "/admin",
    },
    {
        titleKey: "breadcrumbsTags",
    },
];

export const wikiHome = [
    {
        titleKey: "breadcrumbsWikis",
    },
];

export const blog = [
    {
        titleKey: "breadcrumbsBlog",
    },
];

export const blogPost = [
    {
        titleKey: "breadcrumbsBlog",
        href: "/blog",
    },
    {
        titleKey: "breadcrumbsBlogPost",
    },
];
