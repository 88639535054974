import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "GET_PENDINGS":
            data = await globals.fetch(
                pathFromParams("/pendings", action),
                "GET",
                value,
            ); // prettier-ignore;
            store.dispatch(actions.setPendings(data.pendings || {}));
            break;

        case "ADD_PENDING":
            data = await globals.fetch(
                pathFromParams("/pendings", action),
                "POST",
                {
                    pending: value,
                },
            ); // prettier-ignore;
            action.value = data.pending || {};
            action.pendingId = action.value.id;
            break;

        case "PATCH_PENDING":
            data = await globals.fetch(
                pathFromParams("/pendings/:pendingId", action),
                "PUT",
                { pending: value },
            ); // prettier-ignore;
            break;

        case "DELETE_PENDING":
            data = await globals.fetch(
                pathFromParams("/pendings/:pendingId", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        case "DELETE_PENDINGS":
            data = await globals.fetch(
                pathFromParams("/pendings", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        default:
    }
    return data;
};
