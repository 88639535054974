import { connect } from "react-redux";
import selectors from "../spark_modules/sparkSelectors";

export const withSelector = (selectorKey, thunks) => cmp => {
    const sel = selectors.selectors;
    const mapStateToProps = (state, ownProps) => {
        if (!selectorKey) return {};

        const selector = sel[selectorKey];

        if (!selector) {
            throw new Error(`SELECTOR ${selectorKey} not found`);
        }

        const data = selector(state) || {};

        return { ...data };
    };

    return connect(
        mapStateToProps,
        thunks,
    )(cmp);
};
