// @flow
import React, { Component } from "react";
import "./Icon.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";
import { Link } from "react-router-dom";

const Div = props => <div {...props}>{props.children}</div>;

const A = props => (
    <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
    </a>
);

export class Icon extends Component {
    static defaultProps = {
        name: "",
    };

    state = {};

    get style() {
        const { size } = this.props;

        return {
            fontSize: `${size}px`,
        };
    }

    get styleContainer() {
        const { color, margin } = this.props;

        return {
            color,
            margin: `0 ${margin}px`,
        };
    }

    renderAwesome = () => {
        const { name } = this.props;

        return <i style={this.style} className={name} />;
    };

    renderMaterial = () => {
        const { name } = this.props;

        return (
            <i style={this.style} className="material-icons">
                {name}
            </i>
        );
    };

    onContextMenu = ev => {
        const { disabled, onContext } = this.props;

        if (!onContext || disabled) return;

        ev.preventDefault();
        window.open(onContext, "_blank");
    };

    onClick = ev => {
        const { disabled } = this.props;

        if (!this.props.onClick || disabled) return;

        this.props.onClick(ev);
    };

    render() {
        const { name, to, href, className, disabled, circleColor } = this.props;

        const isMaterial = name.indexOf("fa") === -1;
        const inner = isMaterial ? this.renderMaterial() : this.renderAwesome();

        const classNameContainer = classnames(
            "Icon-container",
            className,
            circleColor,
            {
                material: isMaterial,
                disabled,
                circle: circleColor,
                link:
                    this.props.onClick ||
                    this.props.to ||
                    this.props.href ||
                    this.props.on,
            },
        );

        let Cmp = this.props.to ? Link : Div;

        if (href) {
            Cmp = A;
        }

        return (
            <Cmp
                style={this.styleContainer}
                to={to}
                href={href}
                className={classNameContainer}
                onContextMenu={this.onContextMenu}
                onClick={this.onClick}>
                {inner}
            </Cmp>
        );
    }
}

Icon.contextTypes = contextTypes;

export default Icon;
