// @flow
import React, { Component } from "react";
import "./BigButton.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";
import { Link } from "react-router-dom";

export class BigButton extends Component {
    state = {};

    renderInner() {
        const { label, on } = this.props;

        const className = classnames("BigButton-container", {
            on,
        });

        return (
            <div className={className} onClick={this.props.onClick}>
                {label}
            </div>
        );
    }

    render() {
        const { to } = this.props;

        if (to) {
            return (
                <Link className="no-link" to={to}>
                    {this.renderInner()}
                </Link>
            );
        }

        return this.renderInner();
    }
}

BigButton.contextTypes = contextTypes;

export default BigButton;
