// @flow
import React, { Component } from "react";
import "./ContentEditable.scss";
import contextTypes from "../contextTypes";
import ReactContentEditable from "react-contenteditable";
import classnames from "classnames";

const noop = () => {};

export class ContentEditable extends Component {
    static defaultProps = {
        onDelete: noop,
    };

    state = {
        html: "",
    };

    constructor(props) {
        super();
        this.contentEditable = React.createRef();
    }

    loadContent = content => {
        const html = content.replace(/[\n\r]/g, "<br />");
        this.setState({ html, content });
    };

    componentDidMount() {
        const { content } = this.props;

        this.loadContent(content);
    }

    componentWillReceiveProps(props) {
        const { content } = props;

        if (content !== this.state.content) {
            this.loadContent(content);
        }
    }

    handleChange = evt => {
        const value = evt.target.value;

        this.setState({ html: value });
    };

    onKeyDown = evt => {
        const { name } = this.props;
        const { html } = this.state;

        if (html === "" && evt.which === 8) {
            this.props.onDelete(name);
            evt.preventDefault();
        }
    };

    onBlur = () => {
        const { removeHtml = true, name } = this.props;
        const { html } = this.state;

        const text = !removeHtml
            ? html
            : html
                  .replace(/<br ?\/?>/g, "\n")
                  .replace(/<div>/g, "\n")
                  .replace(/<\/div>/g, "")
                  .replace(/<span[^>]+>/g, "\n")
                  .replace(/<\/span>/g, "")
                  .replace(/&nbsp;/g, " ")
                  .replace(/(<([^>]+)>)/g, "")
                  .replace(/^\s+/g, "");

        if (this.props.onBlur) {
            this.props.onBlur(text, name);
        }
    };

    render() {
        const className = classnames(
            "ContentEditable-container",
            this.props.className,
        );

        return (
            <div className={className}>
                <ReactContentEditable
                    innerRef={this.contentEditable}
                    html={this.state.html} // innerHTML of the editable div
                    disabled={false} // use true to disable editing
                    onKeyDown={this.onKeyDown}
                    onChange={this.handleChange} // handle innerHTML change
                    onBlur={this.onBlur}
                    tagName="article" // Use a custom HTML tag (uses a div by default)
                />
            </div>
        );
    }
}

ContentEditable.contextTypes = contextTypes;

export default ContentEditable;
