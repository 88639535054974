// @flow
import React, { Component } from "react";
import "./CommentRoot.scss";
import contextTypes from "../contextTypes";
import PostHeader from "../post-header/PostHeader";
import IconWithLabel from "../icons/IconWithLabel";
import classnames from "classnames";
import Sentences from "../sentences/Sentences";
import VideoPlayer from "../video-player/VideoPlayer";
import ContentEditable from "../content-editable/ContentEditable";
import Icon from "../icons/Icon";

const noop = () => {};

export class CommentRoot extends Component {
    static defaultProps = {
        onClick: noop,
        onAvatarClick: noop,
        onLikesClick: noop,
        onCommentsClick: noop,
        onDateClick: noop,
        onBlur: noop,
        changeSentence: noop,
        onSentenceClick: noop,
    };

    state = {
        showVideo: false,
    };

    renderImage = () => {
        const { comment, media, compact } = this.props,
            { imageUrl } = comment;

        if (media === "video" || !imageUrl || compact) {
            return null;
        }

        return (
            <figure className="image animated fadeIn">
                <img src={imageUrl} alt="main" />
            </figure>
        );
    };

    renderVideo = () => {
        const { media, comment, toggleVideo, compact } = this.props,
            { showVideo } = this.state,
            { videoUrl } = comment;

        if (compact) return null;

        if (media === "video") {
            if (toggleVideo && !showVideo) {
                return (
                    <div className="video">
                        <Icon
                            name="movie"
                            size={40}
                            onClick={() => this.setState({ showVideo: true })}
                        />
                    </div>
                );
            }

            return (
                <figure className="video">
                    <VideoPlayer
                        height={300}
                        url={videoUrl}
                        autoplay={true}
                        width={500}
                    />
                </figure>
            );
        }
    };

    renderContent() {
        const {
                comment,
                editMode,
                selected,
                editable,
                selectedSentences = [],
            } = this.props,
            { content = "", highlights = {} } = comment;

        if (editable) {
            return (
                <ContentEditable content={content} onBlur={this.props.onBlur} />
            );
        }

        return (
            <Sentences
                content={content}
                editMode={editMode && selected}
                highlights={highlights}
                selectedSentences={selectedSentences}
                onSentenceClick={this.props.onSentenceClick}
                changeSentence={this.props.changeSentence}
            />
        );
    }

    onClick = ev => {
        const { comment } = this.props;

        this.props.onClick(ev.target, comment);
    };

    render() {
        const { i18n } = this.context;
        const { comment, selected } = this.props;
        const { originLikes, originComments } = comment || {};

        const classNamePopoi = classnames("popoi fas fa-caret-left", {
            show: true,
        });

        return (
            <div className="CommentRoot-container">
                <PostHeader
                    comment={comment}
                    onAvatarClick={this.props.onAvatarClick}
                    onDateClick={this.props.onDateClick}
                />
                <div className="post-body" onClick={this.onClick}>
                    {this.renderContent()}
                    {this.renderImage()}
                    {this.renderVideo()}
                    {selected ? <i className={classNamePopoi} /> : null}
                </div>
                <div className="stats">
                    <IconWithLabel
                        onClick={this.props.onLikesClick}
                        name="thumbs-up"
                        label={originLikes}
                    />
                    <IconWithLabel
                        name="comments"
                        onClick={this.props.onCommentsClick}
                        label={`${originComments} ${i18n.replies}`}
                    />
                </div>
            </div>
        );
    }
}

CommentRoot.contextTypes = contextTypes;

export default CommentRoot;
