import site from "./sites/us/us";
import "dayjs/locale/en";
import components from "./client.components";
import globals from "../utils/globals";
import * as storage from "../utils/storage";
import dayjs from "dayjs";

const dev = process.env.REACT_APP_STAGE === "dev";
const env = dev ? "dev" : "prod";

globals.defaults = site.defaults;
globals.ga = site.ga;
globals.booklet = site.booklet;
globals.offlineData = storage.getJson("offline_data");

dayjs.locale(site.locale);

export default {
    env,
    get i18n() {
        return site.i18n;
    },
    get components() {
        return components;
    },
    get pages() {
        return site.pages;
    },
    get server() {
        const server = dev ? site.staging : site.server;
        return server;
    },
    get locale() {
        return site.locale;
    },
    get icons() {
        return site.icons;
    },
    get animations() {
        return site.animations;
    },
    get fontFamily() {
        return site.fontFamily;
    },
    get site() {
        return site.id;
    },
};
