export const fromTags = (tags, id) => {
    let runs = 0,
        tag,
        output = [];

    if (!tags || !id) {
        return output;
    }

    do {
        tag = tags[id];

        const { parentId, title } = tag || {};

        output.unshift({
            title,
            href: `/wiki/${tag.index}`,
        });

        id = parentId;
    } while (runs++ < 10 && id !== "");

    return output;
};
