import globals from "../utils/globals";
import { guid8 } from "../utils/guid";
import * as storage from "../utils/storage";

export default () => {
    const data = globals.offlineData,
        { bons, tags, debates, personas, mappings } = data || {},
        { pending, live, dumps } = debates || {};

    return {
        "/site": {
            GET: () => ({ env: "staging", site: "il", validToken: true }),
        },
        "/admin/stats": {
            GET: () => ({
                stats: {
                    id: 0,
                    debates: { total: 0, parsed: 0, published: 0, new: 0 },
                    pendings: { total: 0, video: 0, text: 0 },
                    cc: { total: 0, youtube: 0, facebook: 0 },
                    personas: { total: 0 },
                    tags: { total: 0, lofs: 0, nvcs: 0 },
                    examples: { total: 0, lofs: 0, nvcs: 0 },
                    badges: { debates: 0, pendings: 0, cc: 0 },
                },
                validToken: true,
            }),
        },
        "/admin/focus": {
            GET: () => ({
                focus: { menuCardId: "_examples", message: "הוספת חדשות" },
                validToken: true,
            }),
        },
        "/me": {
            GET: () => ({
                role: "admin",
                isAdmin: true,
                env: "production",
                site: "il",
                validToken: true,
            }),
        },
        "/token": {
            GET: () => ({
                tokenValid: true,
                validToken: true,
            }),
        },
        "/personas": {
            GET: () => {
                return {
                    personas: personas || {},
                };
            },
        },
        "/tags/lofs": {
            GET: () => {
                return {
                    tags: tags || {},
                };
            },
        },
        "/tags/nvcs": {
            GET: () => {
                return {};
            },
        },
        "/examples/lofs": {
            GET: () => {
                return {};
            },
        },
        "/examples/nvcs": {
            GET: () => {
                return {};
            },
        },
        "/debates": {
            GET: () => {
                return {
                    debates: live,
                };
            },
        },
        "/pendings": {
            GET: () => {
                return {
                    pending,
                };
            },
        },
        "/debates/map/(\\d+)": {
            GET: (options, param) => {
                return {
                    debateId: mappings[param],
                };
            },
        },
        "/debates/([^/]+)": {
            GET: (options, param) => {
                return {
                    debate: live[param],
                };
            },
        },
        "/debates/([^/]+)/bons": {
            GET: (options, param) => {
                return {
                    bons: bons[param],
                };
            },
            POST: (options, param) => {
                const id = guid8();
                const body = JSON.parse(options.body);
                const { bon } = body || {};

                bon.id = id;

                bons[param] = bons[param] || {};
                bons[param][id] = bon;

                storage.saveJson("offline_data", globals.offlineData);

                return {
                    bon,
                };
            },
        },
        "/debates/([^/]+)/progress": {
            PUT: (options, param) => {
                const body = JSON.parse(options.body);
                const { progress } = body || {};

                const _progress = live[param].progress;

                live[param].progress = {
                    ..._progress,
                    ...progress,
                };

                storage.saveJson("offline_data", globals.offlineData);

                return {
                    success: true,
                };
            },
        },
        "/debates/([^/]+)/root": {
            PUT: (options, param) => {
                const body = JSON.parse(options.body);
                const { root } = body || {};

                const _root = live[param].root;

                live[param].root = {
                    ..._root,
                    ...root,
                };

                storage.saveJson("offline_data", globals.offlineData);

                return {
                    success: true,
                };
            },
        },
    };
};
