// @flow
import React, { Component } from "react";
import "./CanvasPreview.scss";
import contextTypes from "../contextTypes";
import Canvas from "../../utils/canvas";
import canvasConfig from "./canvasConfig";
import dayjs from "dayjs";
import { getImageSize } from "../../utils/image";
import globals from "../../utils/globals";

const delay = duration => {
    return new Promise(resolve => {
        setTimeout(resolve, duration);
    });
};

export class CanvasPreview extends Component {
    state = {
        width: 1200,
        height: 1200,
    };

    constructor() {
        super();
        this.painted = false;
    }

    get debate() {
        const { debate } = this.state;
        return debate || {};
    }

    get root() {
        const { root } = this.debate;
        return root;
    }

    get debateId() {
        const { match } = this.props,
            { params } = match || {},
            { debIndex } = params;

        return debIndex;
    }

    parseData = element => {
        const { field } = element;

        let data = this.root[field];

        switch (element.type) {
            case "date":
                data = dayjs(data).fromNow();
                break;
        }

        return data;
    };

    drawElement = (canvas, element) => {
        const data = this.parseData(element);

        canvas.draw(element, data);
    };

    toLines = (content, length) => {
        let lines = [""],
            idx = 0;

        content.split(/\s/g).forEach(word => {
            if ((lines[idx] + " " + word).length > length) {
                idx++;
                lines[idx] = word;
            } else {
                lines[idx] += " " + word;
            }
        });

        return lines.slice(0, 9);
    };

    draw = async () => {
        if (this.painted) return null;
        this.painted = true;

        const { width, height } = this.state;
        const { imageUrl, content = "", lofs } = this.root;
        let imageHeight = 0;

        if (imageUrl) {
            const size = await getImageSize(imageUrl);
            const { ratio } = size;
            imageHeight = 430 / ratio;
            canvasConfig["6"].style.height = imageHeight;
        }

        canvasConfig["13"].style.url = `/images/${globals.site}/${lofs}.png`;

        const lines = this.toLines(content, 65);
        const contentHeight = lines.length * canvasConfig["5"].style.lineHeight;

        const totalHeight = 150 + contentHeight + imageHeight;
        canvasConfig["1"].style.height = totalHeight;

        this.setState({ height: totalHeight + 200 });

        this.c.clear(width, height);

        let arr = Object.values(canvasConfig);

        this.c.filter = "blur(7px)";
        this.c.dx = -30;
        this.c.dy = -70;
        this.c.scale = 1350 / 550;
        this.c.extraHeight = { imageUrl: (imageHeight * 1200) / 550 };
        arr.forEach(el => this.drawElement(this.c, el));
        //1350 / 550, -30, -70

        await delay(50);
        this.c.filter = "none";
        this.c.dx = 139;
        this.c.dy = 0;
        this.c.scale = 800 / 550;
        this.c.extraHeight = { imageUrl: (imageHeight * 800) / 550 };
        arr.forEach(el => this.drawElement(this.c, el));
    };

    componentDidMount() {
        const { fontFamily } = this.context;
        this.props.changeDebateId(this.debateId);
        this.props.fetchDebate(this.debateId);

        this.c = new Canvas("#canvas", fontFamily);
    }

    componentWillReceiveProps(props) {
        const { debate } = props;

        if (debate && debate.id && this.state.debate !== debate) {
            this.setState({ debate });
            setTimeout(this.draw, 1000);
        }
    }

    render() {
        const { i18n } = this.context;
        const { width, height } = this.state;

        return (
            <div className="CanvasPreview-container">
                <div className="wrapper">
                    <canvas
                        dir="rtl"
                        id="canvas"
                        width={width}
                        height={height}
                    />
                </div>
            </div>
        );
    }
}

CanvasPreview.contextTypes = contextTypes;

export default CanvasPreview;
