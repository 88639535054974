// @flow
import React, { Component } from "react";
import "./UserBar.scss";
import contextTypes from "../contextTypes";
import EditButton from "../buttons/EditButton";
import classnames from "classnames";
import EmailButton from "../buttons/EmailButton";
import FacebookButton from "../buttons/FacebookButton";
import { userMenu } from "../../constants/menus";
import { Link } from "react-router-dom";
import globals from "../../utils/globals";
import Icon from "../icons/Icon";
import Done from "../table/fields/done/Done";
import { getImageSize } from "../../utils/image";
import BigButton from "../buttons/BigButton";

const isInElement = (target, el) => {
    let runs = 0;

    while (target && runs++ < 20 && target !== el) {
        target = target.parentNode;
    }

    return target === el;
};

export class UserBar extends Component {
    state = {
        showMenu: false,
    };

    constructor() {
        super();

        this.menu = React.createRef();
    }

    get debates() {
        const { debates } = this.props;

        return debates;
    }

    get index() {
        const { debate } = this.props,
            { index } = debate;

        return index;
    }

    get first() {
        return this.debates.map(item => item.index).indexOf(this.index) === 0;
    }

    get last() {
        return (
            this.debates.map(item => item.index).indexOf(this.index) ===
            this.debates.length - 1
        );
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.mousedown);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.mousedown);
    }

    mousedown = ev => {
        const isIn = isInElement(ev.target, this.menu.current);
        if (!isIn) {
            this.setState({ showMenu: false });
        }
    };

    toggleMenu = () => {
        const { showMenu } = this.state;
        this.setState({ showMenu: !showMenu });
    };

    signout = () => {
        this.props.dispatch({ type: "SIGN_OUT" });
    };

    renderOffline() {
        const { i18n } = this.context;
        const { offline } = this.props;

        if (!offline) return null;

        return <div className="offline">{i18n.offline}</div>;
    }

    renderSite() {
        const { site = "", env, isAdmin } = this.props;
        let envAndVersion = "";

        let text = site.toUpperCase();

        if (isAdmin) {
            envAndVersion = `${env} ${process.env.REACT_APP_VERSION}`;
        }

        const className = classnames("site", {
            staging: env === "staging",
        });

        return (
            <div className={className}>
                {text}
                <span>{envAndVersion}</span>
            </div>
        );
    }

    renderMenu() {
        const { i18n, isMobile } = this.context;
        const { isAdmin } = this.props;

        const { showMenu } = this.state;

        if (!showMenu) return null;

        return (
            <div className="menu" ref={this.menu}>
                <ul>
                    {Object.values(userMenu)
                        .filter(i => (i.isAdmin && isAdmin) || !i.isAdmin)
                        .map(i => {
                            const { titleKey, id, href, hideMobile } = i || {};

                            const className = classnames({
                                hide: isMobile && hideMobile,
                            });

                            return (
                                <li key={id} className={className}>
                                    <Link to={href}>{i18n[titleKey]}</Link>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    }

    renderUserImage() {
        const { user } = this.props,
            { photoURL = "" } = user || {};

        if (!photoURL) {
            return <i className="fas fa-user-alt" />;
        }

        return (
            <div
                className="photo"
                style={{ backgroundImage: `url(${photoURL})` }}
            />
        );
    }

    signIn = ({ type }) => {
        switch (type) {
            case "facebook":
                return this.props.signIn();
            case "email":
                globals.history.push("/admin/login");
                break;
            default:
        }
    };

    renderUser() {
        const { isMobile } = this.context;
        const { user } = this.props,
            { showMenu } = this.state,
            { displayName = "" } = user || {};

        if (isMobile) return null;

        const name = (displayName || "").split(" ").shift();

        const classNameInner = classnames("inner", { selected: showMenu });

        const Cmp =
            globals.config.server.SIGNIN_METHOD === "email"
                ? EmailButton
                : FacebookButton;

        if (!user || !user.uid) {
            return (
                <div className="user-container">
                    <Cmp
                        user={user}
                        small={true}
                        signIn={this.signIn}
                        signOut={this.props.signOut}
                    />
                </div>
            );
        }

        return (
            <header>
                <div className="user-container" onClick={this.toggleMenu}>
                    <div className={classNameInner}>
                        {this.renderUserImage()}
                        <div className="name">{name}</div>
                        <i className="fas fa-chevron-down" />
                    </div>

                    {this.renderMenu()}
                </div>
            </header>
        );
    }

    get debate() {
        const { debate } = this.props;
        return debate || {};
    }

    get doneLofs() {
        const { progress } = this.debate,
            { doneLofs } = progress || {};

        return doneLofs;
    }

    get cardImageUrl() {
        const { root } = this.debate,
            { cardImageUrl } = root || {};

        return cardImageUrl;
    }

    toggleDoneLofs = () => {
        const { id } = this.debate;
        const doneLofs = !this.doneLofs;
        this.props.patchDebateProgress(id, { doneLofs });
    };

    changeCardImage = async ev => {
        const { id } = this.debate;
        const target = ev.target;
        const cardImageUrl = target.value;

        const imageSize = await getImageSize(cardImageUrl);
        const cardImageRatio = imageSize.ratio;

        this.props.patchDebateRoot(id, {
            cardImageUrl,
            cardImageRatio,
        });
    };

    renderBack() {
        const { i18n } = this.context;
        const { barMode, isAdmin } = this.props;

        if (barMode !== "debate") return null;

        const to = isAdmin ? "/admin/debates" : "/";

        return (
            <div className="back">
                <BigButton label={i18n.back} to={to} />
            </div>
        );
    }

    renderEditActions() {
        const { editMode } = this.props;

        if (!editMode) return null;

        return (
            <div className="edit-actions">
                <div className="done">
                    <Done
                        value={this.doneLofs}
                        size={22}
                        onClick={this.toggleDoneLofs}
                    />
                </div>
                &nbsp;&nbsp;&nbsp;
                <Icon
                    name="arrow_back"
                    size={28}
                    onClick={this.previous}
                    disabled={this.last}
                />
                <div className="index">{this.index || "-"}</div>
                <Icon
                    name="arrow_forward"
                    size={28}
                    onClick={this.next}
                    disabled={this.first}
                />
            </div>
        );
    }

    nudge = delta => {
        const { debate } = this.props,
            { id } = debate;

        let index = this.debates.map(debate => debate.id).indexOf(id);
        index += delta;
        index = Math.max(index, 0);
        index = Math.min(index, this.debates.length - 1);

        const newDebate = this.debates[index];

        globals.history.push(`/${newDebate.index}`);
    };

    next = () => {
        // order of array is reversed so -1
        this.nudge(-1);
    };

    previous = () => {
        // order of array is reversed so +1
        this.nudge(+1);
    };

    renderEdit() {
        const { editMode, barMode, isAdmin } = this.props;

        if (barMode !== "debate" || !isAdmin) return null;

        return (
            <EditButton
                editMode={editMode}
                onClick={editMode => this.props.changeEditMode(editMode)}
            />
        );
    }

    renderLogo() {
        const { icons } = this.context;
        const { showBack } = this.props;

        return (
            <Link to="/" className="logo-container">
                <div className="logo">DebDB</div>
                {showBack ? (
                    <Icon name={icons.arrowRight} color="#eee" />
                ) : null}
            </Link>
        );
    }

    render() {
        return (
            <div className="UserBar-container">
                <Icon
                    name="menu"
                    className="hamburger"
                    onClick={this.props.onMenu}
                />
                {this.renderLogo()}
                {this.renderSite()}
                {this.renderOffline()}

                <div className="actions">
                    {this.renderEdit()}
                    {this.renderBack()}
                    {this.renderEditActions()}
                </div>

                <div className="spacer" />

                {this.renderUser()}
            </div>
        );
    }
}

UserBar.contextTypes = contextTypes;

export default UserBar;
