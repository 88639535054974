import actions from "../sparkActions";

export default dispatch => {
    return {
        changeStats: stats => {
            return dispatch(actions.patchBuilderStats(stats));
        },
        changeInfo: info => {
            return dispatch(actions.patchBuilderInfo(info));
        },
        changeRoot: root => {
            return dispatch(actions.patchBuilderRoot(root));
        },
        changeProgress: progress => {
            return dispatch(actions.patchBuilderProgress(progress));
        },
        changeParticipant: (participantId, participant) => {
            return dispatch(
                actions.patchBuilderParticipant(participantId, participant),
            );
        },
        deleteParticipant: participantId => {
            return dispatch(actions.deleteBuilderParticipant(participantId));
        },
        addComment: comment => {
            return dispatch(actions.addBuilderComment(comment));
        },
        nudgeParticipant: commentId => {
            return dispatch(actions.nudgeBuilderCommentParticipant(commentId));
        },
        changeComment: (commentId, comment) => {
            if (commentId === "i0") {
                return dispatch(actions.patchBuilderRoot(comment));
            } else {
                return dispatch(
                    actions.patchBuilderComment(commentId, comment),
                );
            }
        },
        deleteComment: commentId => {
            return dispatch(actions.deleteBuilderComment(commentId));
        },
        setBuilderDebate: debate => {
            return dispatch(actions.setBuilderDebate(debate));
        },
        setBuilderComments: comments => {
            return dispatch(actions.setBuilderComments(comments));
        },
    };
};
