// @flow
import React, { Component } from "react";
import "./CardSuggestLink.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";

export class CardSuggestLink extends Component {
    state = {};

    render() {
        const { i18n } = this.context;

        return (
            <Link to="/suggestion" className="no-link">
                <div className="CardSuggestLink-container card">
                    <div className="inner">
                        <div className="question">
                            {i18n.suggestionQuestion}
                        </div>
                        <button className="button">{i18n.suggestionCTA}</button>
                    </div>
                </div>
            </Link>
        );
    }
}

CardSuggestLink.contextTypes = contextTypes;

export default CardSuggestLink;
