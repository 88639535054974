import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";

// prevent circular reference

export const examplesForTag = createSelector(
    raw.examples,
    appState.tagId,
    (_examples, _tagId) => {
        return Object.values(_examples).filter(
            example => example.tagId === _tagId,
        );
    },
);

export const examplesFiltered = createSelector(
    raw.examples,
    appState.filterTagId,
    raw.tags,
    (_examples, _tagId, _tags) => {
        return Object.values(_examples)
            .filter(example => {
                const { tagId } = example; // isDeleted
                const okId = tagId === _tagId || !_tagId;

                return okId; // && !isDeleted;
            })
            .map(example => {
                const { tagId } = example;
                const tag = _tags[tagId],
                    { title } = tag || {};

                return { ...example, tagTitle: title };
            });
    },
);

export const examplesCountByTag = createSelector(
    raw.examples,
    _examples => {
        return Object.values(_examples).reduce((output, example) => {
            output[example.tagId] = (output[example.tagId] || 0) + 1;
            return output;
        }, {});
    },
);

export const example = createSelector(
    raw.examples,
    appState.exampleId,
    (_examples, _exampleId) => {
        return _examples[_exampleId] || {};
    },
);
