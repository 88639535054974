import React from "react";
import ReactDOM from "react-dom";
import "./utils/dayjs";
import "./config/dependencies";
import "./config/config";
import globals from "./utils/globals";
import App from "./components/App";
import * as serviceWorker from "./utils/serviceWorker";
import { Provider } from "react-redux";
import { initStore } from "./spark_modules/store";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import initialStates from "./spark_modules/initialStates";
import { navigateHomeIfNotAllowed } from "./utils/admin";
import "./styles/All.scss";
import ReactGA from "react-ga";

ReactGA.initialize(globals.ga);

const history = createBrowserHistory();
globals.history = history;

history.listen(params => {
    const { pathname } = params || {};
    navigateHomeIfNotAllowed(pathname);
    ReactGA.pageview(pathname);
});

const store = initStore(initialStates);

const rootEl = document.getElementById("root");

const render = Component =>
    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <Component />
            </Router>
        </Provider>,
        rootEl,
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

render(App);

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        render(<NextApp />, rootEl);
    });
}
