import * as navigation from "./navigation";
import globals from "./globals";
import adminRoutes from "../config/admin.routes";

export const checkIfAllowed = pathname => {
    const route = adminRoutes[pathname];

    if (!route) return true;

    return globals.isAdmin;
};

const isDev = process.env.NODE_ENV === "development";

export const navigateHomeIfNotAllowed = pathname => {
    const isAllowed = checkIfAllowed(pathname || {});

    if (!isAllowed && !isDev) {
        navigation.toHome();
    }
};
