import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "GET_TAGS":
            data = await globals.fetch(
                pathFromParams("/tags/:layerId", action),
                "GET",
            ); // prettier-ignore;
            store.dispatch(actions.setTags(action.layerId, data.tags));
            break;

        case "ADD_TAG":
            data = await globals.fetch(
                pathFromParams("/tags/:layerId", action),
                "POST",
                { tag: value },
            ); // prettier-ignore;
            action.value = data.tag || {};
            action.tagId = action.value.id;
            break;

        case "PATCH_TAG":
            data = await globals.fetch(
                pathFromParams("/tags/:layerId/:tagId", action),
                "PUT",
                { tag: value },
            ); // prettier-ignore;
            break;

        case "DELETE_TAG":
            data = await globals.fetch(
                pathFromParams("/tags/:layerId/:tagId", action),
                "DELETE",
            ); // prettier-ignore;
            break;
        default:
    }
    return data;
};
