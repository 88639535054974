// @flow
import React, { Component } from "react";
import "./RootModal.scss";
import contextTypes from "../contextTypes";
import Modal from "../modal/Modal";
import globals from "../../utils/globals";

export class RootModal extends Component {
    state = {};

    render() {
        const { i18n } = this.context;
        const { modal } = this.props,
            { id, props } = modal || {};

        if (!id) return null;

        const SpecificModal = globals.components[id + "Modal"];

        if (!SpecificModal) return null;

        const title = i18n[id + "Modal"];

        return (
            <Modal onClose={this.props.closeModal} title={title}>
                <SpecificModal {...this.props} {...props} />
            </Modal>
        );
    }
}

RootModal.contextTypes = contextTypes;

export default RootModal;
