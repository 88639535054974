export const getBlogPosts = () => ({type: "GET_BLOG_POSTS"}); // prettier-ignore
export const getBlogPost = (blogPostId) => ({type: "GET_BLOG_POST", blogPostId}); // prettier-ignore
export const setBlogs = (value) => ({type: "SET_BLOGS", value}); // prettier-ignore
export const addBlog = (blogPostId, value) => ({type: "SET_BLOG", blogPostId, value}); // prettier-ignore
export const setBlogPost = (blogPostId, value) => ({type: "SET_BLOG", blogPostId, value, silent: true}); // prettier-ignore
export const patchBlog = (blogPostId, value) => ({type: "PATCH_BLOG", blogPostId, value}); // prettier-ignore
export const deleteBlog = (blogPostId, value) => ({type: "DELETE_BLOG"}); // prettier-ignore
export const setBlogProgress = (blogPostId, value) => ({type: "SET_BLOG_PROGRESS", blogPostId, value}); // prettier-ignore
export const patchBlogProgress = (blogPostId, value) => ({type: "PATCH_BLOG_PROGRESS", blogPostId, value}); // prettier-ignore
export const setBlogCard = (blogPostId, value) => ({type: "SET_BLOG_CARD", blogPostId, value}); // prettier-ignore
export const patchBlogCard = (blogPostId, value) => ({type: "PATCH_BLOG_CARD", blogPostId, value}); // prettier-ignore
export const setElements = (elementId, value) => ({type: "SET_BLOG_ELEMENTS", elementId, value}); // prettier-ignore
export const addElement = (blogPostId, elementId, value) => ({type: "SET_BLOG_ELEMENT", blogPostId, elementId, value}); // prettier-ignore
export const patchElement = (blogPostId, elementId, value) => ({type: "PATCH_BLOG_ELEMENT", blogPostId, elementId, value}); // prettier-ignore
export const deleteElement = (blogPostId, elementId) => ({type: "DELETE_BLOG_ELEMENT", blogPostId, elementId}); // prettier-ignore
export const setData = (blogPostId, elementId, value) => ({type: "SET_BLOG_ELEMENT_DATA", blogPostId, elementId, value}); // prettier-ignore
export const patchData = (blogPostId, elementId, value) => ({type: "PATCH_BLOG_ELEMENT_DATA", blogPostId, elementId, value}); // prettier-ignore
