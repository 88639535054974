import throttle from "lodash/throttle";

const THROTTLE_DURATION = 100;

const scrollPositions = {};
const listeners = {};

const html = document.querySelector("html");

const addClass = (el, className) => {
    el.classList.add(className);
};

const removeClass = (el, className) => {
    el.classList.remove(className);
};

const toggleClass = (el, className, on) => {
    if (on) {
        addClass(html, className);
    } else {
        removeClass(html, className);
    }
};

const getTop = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
};

const to = (y, smooth) => {
    toggleClass(html, "smooth", smooth);
    window.scrollTo(0, y);
};

const toEl = (el, smooth, delta = 0) => {
    if (!el) {
        return;
    }

    toggleClass(html, "smooth", smooth);

    const rect = el.getBoundingClientRect();

    const scrollTop = getTop();
    const top = scrollTop + rect.y + delta;
    window.scrollTo(0, top);
};

const save = key => {
    const y = getTop();
    scrollPositions[key] = y;
};

const refresh = (key, smooth) => {
    const y = scrollPositions[key];

    if (y || y === 0) {
        to(y, smooth);
    }
};

const listen = key => {
    listeners[key] = throttle(() => save(key), THROTTLE_DURATION);
    document.addEventListener("scroll", listeners[key]);
};

const unlisten = key => {
    document.removeEventListener("scroll", listeners[key]);
};

export default {
    get top() {
        return getTop();
    },
    toEl,
    to,
    listen,
    unlisten,
    refresh,
};
