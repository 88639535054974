// @flow
import React, { Component } from "react";
import "./BlogBeforeAfter.scss";
import contextTypes from "../../contextTypes";

export class BlogBeforeAfter extends Component {
    state = {};

    render() {
        return <div className="BlogBeforeAfter-container">BlogBeforeAfter</div>;
    }
}

BlogBeforeAfter.contextTypes = contextTypes;

export default BlogBeforeAfter;
