import { createSelector } from "reselect";
import * as raw from "./raw";

export const persona = raw.persona;

export const personas = createSelector(
    raw.personasRaw,
    _personas => {
        return (
            Object.values(_personas)
                // .filter(persona => {
                //     const { isDeleted } = persona;
                //     return true; // !isDeleted;
                // })
                .reduce((output, persona) => {
                    output[persona.id] = persona;
                    return output;
                }, {})
        );
    },
);
