// @flow
import React, { Component } from "react";
import "./ProjectTable.scss";
import contextTypes from "../contextTypes";
import tableFields from "./tableFields";
import dayjs from "dayjs";

export class ProjectTable extends Component {
    state = {};

    renderUrl = url => {
        const { i18n } = this.context;
        if (!url) return "-";

        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                {i18n.linkToPage}
            </a>
        );
    };

    renderRow = key => {
        const { i18n } = this.context;
        const { project } = this.props;

        const type = tableFields[key];
        const title = i18n[key];
        let value = project[key];

        switch (type) {
            case "date":
                value = dayjs(value).format("L");
                break;
            case "status":
                value = i18n[value];
                break;
            case "url":
                value = this.renderUrl(value);
                break;
            case "hours":
                value += " " + i18n.hoursTotal;
                break;
            default:
        }

        return (
            <tr key={key}>
                <td>{title}</td>
                <td>{value}</td>
            </tr>
        );
    };

    render() {
        const { i18n } = this.context;

        return (
            <div className="ProjectTable-container">
                <table cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th colSpan={2}>{i18n.infoTable}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tableFields).map(key =>
                            this.renderRow(key),
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

ProjectTable.contextTypes = contextTypes;

export default ProjectTable;
