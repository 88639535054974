// @flow
import React, { Component } from "react";
import "./Comment.scss";
import contextTypes from "../contextTypes";
import Avatar from "../avatar/Avatar";
import classnames from "classnames";
import CommentStats from "../comment-stats/CommentStats";
import dayjs from "dayjs";
import Sentences from "../sentences/Sentences";
import ContentEditable from "../content-editable/ContentEditable";
import ContentBlank from "../content-blank/ContentBlank";

const noop = () => {};

export class Comment extends Component {
    static defaultProps = {
        onClick: noop,
        onAvatarClick: noop,
        onLikesClick: noop,
        onDateClick: noop,
        onBlur: noop,
        onDelete: noop,
        changeSentence: noop,
    };

    state = {};

    constructor() {
        super();

        this.ref = React.createRef();
    }

    onClick = () => {
        const { comment } = this.props;

        this.props.onClick(this.ref.current, comment);
    };

    onAvatarClick = () => {
        const { comment } = this.props;

        this.props.onAvatarClick(this.ref.current, comment);
    };

    renderLikes = () => {
        const { comment } = this.props,
            { originLikes } = comment;

        if (!originLikes) return null;

        return [
            <span key={0} className="dot">
                &nbsp;·&nbsp;
            </span>,
            <span key={1} onClick={this.props.onLikesClick}>
                {originLikes} <i className="far fa-thumbs-up" />
            </span>,
        ];
    };

    renderContent() {
        const {
                comment,
                editMode,
                selected,
                selectedSentences = [],
                blank,
                editable,
            } = this.props,
            { content = "", highlights = {} } = comment;

        if (blank) {
            return <ContentBlank />;
        }

        if (editable) {
            return (
                <ContentEditable
                    content={content}
                    onBlur={this.props.onBlur}
                    onDelete={this.props.onDelete}
                />
            );
        }

        return (
            <Sentences
                content={content}
                editMode={editMode && selected}
                highlights={highlights}
                selectedSentences={selectedSentences}
                onSentenceClick={this.props.onSentenceClick}
                changeSentence={this.props.changeSentence}
            />
        );
    }

    render() {
        const { comment, selected } = this.props,
            { timeComment, stats = {}, participant } = comment,
            { title: name, imageUrl } = participant || {};

        const className = classnames("Comment-container", {
            selected,
        });

        const classNamePopoi = classnames("popoi fas fa-caret-left", {
            show: selected,
        });

        const date = dayjs(timeComment);

        const dateText = date.isValid() ? date.fromNow() : "";

        return (
            <div className={className} ref={this.ref}>
                <div className="avatar" onClick={this.onAvatarClick}>
                    <Avatar url={imageUrl} />
                </div>
                <div className="bubble">
                    <div className="inner-bubble" onClick={this.onClick}>
                        <div className="name">{name}</div>
                        <div className="content">{this.renderContent()}</div>
                    </div>
                    <div className="actions">
                        <div className="likes">
                            <span
                                className="time"
                                onClick={this.props.onDateClick}>
                                {dateText}
                            </span>
                            {this.renderLikes()}
                        </div>
                        <CommentStats stats={stats} />
                    </div>
                </div>

                <i className={classNamePopoi} />
            </div>
        );
    }
}

Comment.contextTypes = contextTypes;

export default Comment;
