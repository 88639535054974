// @flow
import React, { Component } from "react";
import "./Avatar.scss";
import contextTypes from "../contextTypes";

export class Avatar extends Component {
    static defaultProps = {
        url: "",
    };

    state = {};

    get style() {
        const { url } = this.props;

        return {
            backgroundImage: `url(${url})`,
        };
    }
    render() {
        return <div className="Avatar-container" style={this.style} />;
    }
}

Avatar.contextTypes = contextTypes;

export default Avatar;
