import { saveJson } from "../../utils/storage";

const STORAGE_KEY = "builder_debate";

export default async (action, store) => {
    let data;

    switch (action.type) {
        case "SET_BUILDER_DEBATE":
        case "PATCH_BUILDER":
        case "PATCH_BUILDER_DEBATE":
        case "PATCH_BUILDER_DEBATE_COMMENT":
        case "DELETE_BUILDER_DEBATE_COMMENT":
        case "PATCH_BUILDER_DEBATE_PARTICIPANT":
        case "DELETE_BUILDER_DEBATE_PARTICIPANT":
        case "PATCH_BUILDER_DEBATE_ROOT":
        case "PATCH_BUILDER_DEBATE_INFO":
        case "PATCH_BUILDER_DEBATE_STATS":
        case "PATCH_BUILDER_DEBATE_PROGRESS":
        case "ADD_BUILDER_DEBATE_COMMENT":
        case "SET_BUILDER_DEBATE_COMMENTS":
        case "NUDGE_BUILDER_COMMENT_PARTICIPANT":
            const state = store.getState(),
                { builder } = state || {},
                { debate } = builder || {};

            saveJson(STORAGE_KEY, debate);
            break;
        default:
    }
    return data;
};
