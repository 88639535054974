import { fork, take, select, call, put } from "redux-saga/effects";
import actions from "../sparkActions";
import * as appState from "../selectors/appState";
import * as storage from "../../utils/storage";
import { setOffline } from "../../utils/fetch";
import globals from "../../utils/globals";
import { merge, download } from "../../utils/offline";

const getData = () => globals.fetch("/admin/offline");

const storage_key = "offline_data";

function* offlineStart() {
    const res = yield getData();
    const data = merge(globals.offlineData, res);
    storage.saveJson(storage_key, data);
    globals.offlineData = data;
    setOffline(true);
}

function* offlineEnd() {
    download(globals.offlineData);
    setOffline(false);
}

function* offline() {
    try {
        while (true) {
            const { value } = yield take("OFFLINE");

            if (value) {
                yield call(offlineStart);
            } else {
                yield call(offlineEnd);
            }
        }
    } finally {
    }
}

function* login() {
    const offline = yield select(appState.offline);
    if (offline) {
        yield put(
            actions.patchAppState({
                isAdmin: true,
                role: "admin",
                env: "production",
                site: "il",
                user: {
                    uid: 1,
                    displayName: "offline",
                },
            }),
        );
    }
}

function* all() {
    yield fork(offline);
    yield fork(login);
}

export default all;
