import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "SET_DEBATES":
            store.dispatch(actions.patchAppState({ isLoading: false }));
            break;

        case "GET_DEBATES":
            store.dispatch(actions.patchAppState({ isLoading: true }));
            data = await globals.fetch(
                pathFromParams("/debates", action),
                "GET",
            ); // prettier-ignore;
            await store.dispatch(actions.setDebates(data.debates));
            break;

        case "GET_DEBATE":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId", action),
                "GET",
            ); // prettier-ignore;
            store.dispatch(actions.setDebate(action.debateId, data.debate));

            if (data && data.debate && data.debate.id) {
                store.dispatch(
                    actions.patchCurrentId({ debId: data.debate.id }),
                );
            }
            break;

        case "ADD_DEBATE":
            data = await globals.fetch(
                pathFromParams("/debates", action),
                "POST",
                {
                    debate: value,
                },
            ); // prettier-ignore;
            action.value = data.debate || {};
            action.debateId = action.value.id;
            break;

        case "PATCH_DEBATE":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId", action),
                "PUT",
                { debate: value },
            ); // prettier-ignore;
            break;

        case "DELETE_DEBATE":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        case "PATCH_DEBATE_STATS":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId/stats", action),
                "PUT",
                {
                    stats: value,
                },
            ); // prettier-ignore;
            break;

        case "PATCH_DEBATE_INFO":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId/info", action),
                "PUT",
                {
                    info: value,
                },
            ); // prettier-ignore;
            break;

        case "PATCH_DEBATE_ROOT":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId/root", action),
                "PUT",
                {
                    root: value,
                },
            ); // prettier-ignore;
            break;

        case "PATCH_DEBATE_PROGRESS":
            data = await globals.fetch(
                pathFromParams("/debates/:debateId/progress", action),
                "PUT",
                {
                    progress: value,
                },
            ); // prettier-ignore;
            break;

        case "ADD_DEBATE_COMMENT":
            data = await globals.fetch(
                pathFromParams("/debates/:debId/comments", action),
                "POST",
                { comment: value },
            ); // prettier-ignore;
            action.value = data.comment || {};
            action.commentId = action.value.id;
            break;

        case "PATCH_DEBATE_COMMENT":
            data = await globals.fetch(
                pathFromParams(
                    "/debates/:debateId/comments/:commentId",
                    action,
                ),
                "PUT",
                { comment: value },
            ); // prettier-ignore;
            break;

        case "DELETE_DEBATE_COMMENT":
            data = await globals.fetch(
                pathFromParams(
                    "/debates/:debateId/comments/:commentId",
                    action,
                ),
                "DELETE",
            ); // prettier-ignore;
            break;

        case "ADD_DEBATE_PARTICIPANT":
            data = await globals.fetch(
                pathFromParams(
                    "/debates/:debateId/participants/random",
                    action,
                ),
                "POST",
                { participant: value },
            ); // prettier-ignore;
            action.value = data.participant || {};
            action.participantId = action.value.id;
            break;

        case "PATCH_DEBATE_PARTICIPANT":
            data = await globals.fetch(
                pathFromParams(
                    "/debates/:debateId/participants/:participantId",
                    action,
                ),
                "PUT",
                { participant: value },
            ); // prettier-ignore;
            break;

        case "DELETE_DEBATE_PARTICIPANT":
            data = await globals.fetch(
                pathFromParams(
                    "/debates/:debateId/participants/:participantId",
                    action,
                ),
                "DELETE",
            ); // prettier-ignore;
            break;

        default:
    }
    return data;
};
