import React from "react";
import "./Isomer.scss";
import throttle from "lodash/throttle";

const canItFit = ({ canvasWidth, canvasHeight, x, y, w, h, toTheLeft }) => {
    const x2 = toTheLeft ? x + w : x - w;
    const y2 = toTheLeft ? y + h : x - h;

    const okX = (x2 >= 0 && x2 <= canvasWidth) || !canvasWidth;

    const okY = (y2 >= 0 && y2 <= canvasHeight) || !canvasHeight;

    return okX && okY;
};

const intersectRect = (r1, r2) => {
    return !(
        r2.left >= r1.right ||
        r2.right <= r1.left ||
        r2.top >= r1.bottom ||
        r2.bottom <= r1.top
    );
};

const positionsThatFit = (cw, box, pointers, positions) => {
    return pointers.filter(pos => {
        return (
            canItFit({
                canvasWidth: cw,
                x: pos.x,
                y: pos.y,
                toTheLeft: pos.toTheLeft,
                w: box.width,
                h: box.height,
            }) &&
            willItNotCollide({
                positions,
                x: pos.x,
                y: pos.y,
                toTheLeft: pos.toTheLeft,
                w: box.width,
                h: box.height,
            })
        );
    });
};

const findAPoint = (cw, box, pointers, positions) => {
    const validPointers = positionsThatFit(cw, box, pointers, positions);

    let bestPoint = validPointers.reduce(
        (output, pos) => {
            if (pos.y < output.y) {
                output = pos;
            }
            return output;
        },
        { x: 0, y: Number.MAX_SAFE_INTEGER },
    );

    if (bestPoint.y === Number.MAX_SAFE_INTEGER) {
        bestPoint = null;
    }

    const idx = pointers.indexOf(bestPoint);

    return { idx, pos: bestPoint };
};

const willItNotCollide = ({ x, y, w, h, toTheLeft, positions }) => {
    let left, right;

    if (toTheLeft) {
        left = x;
        right = x + w;
    } else {
        left = x - w;
        right = x;
    }

    const r2 = {
        top: y,
        left,
        right,
        bottom: y + h,
    };

    return Object.keys(positions).reduce((output, key) => {
        const r1 = positions[key];
        return output && !intersectRect(r1, r2);
    }, true);
};

const noop = () => {};

export class Isomer extends React.Component {
    static defaultProps = {
        maxItems: 100,
        itemSelector: "div",
        renderEmpty: noop,
        didRefresh: noop,
    };

    constructor() {
        super();

        this.state = {
            margin: 10,
            width: 800, // width of canvas
            pointers: [{ x: 0, y: 0, toTheLeft: true }],
            positions: {},
        };

        this.onResize = throttle(this.onResize, 300);

        this.container = React.createRef();
    }

    get children() {
        return this.props.children;
    }

    get count() {
        return this.children.length;
    }

    componentDidMount() {
        this.onResize();

        this.timeout = setTimeout(this.onResize, 3500);

        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        clearTimeout(this.timeout);
        clearTimeout(this.timeout2);
    }

    onResize = () => {
        const el = this.container.current;

        const canvasBox = el.getBoundingClientRect();

        this.setState({ width: canvasBox.width, count: this.count }, () => {
            this.refreshUI();
        });
    };

    componentWillReceiveProps(props) {
        const { children } = props;

        if (children && children.length !== this.state.count) {
            this.setState({ count: children.count }, () => {
                this.onResize();
            });
        }
    }

    getDOMId = i => {
        const child = this.children[i];

        return `ref${child.key}`;
    };

    redrawUI = positions => {
        const { itemSelector } = this.props;
        const { width: cw, pointers } = this.state;

        for (let i = 0; i < this.count; i++) {
            const id = this.getDOMId(i);
            const el = document.querySelector(`#${id} ${itemSelector}`);

            if (!el) continue;

            const _box = el.getBoundingClientRect();

            if (!_box || _box.width === 0) {
                clearTimeout(this.timeout2);
                this.timeout2 = setTimeout(() => this.redrawUI(positions), 100);
                return;
            }

            let box = {
                top: _box.top - 5,
                left: _box.left - 5,
                width: _box.width + 10,
                height: _box.height + 10,
            };

            const { pos } = findAPoint(cw, box, pointers, positions);

            if (!pos) continue;

            let left, right, width;

            if (pos.toTheLeft) {
                left = pos.x;
                right = pos.x + box.width;
                width = box.width;
            } else {
                left = pos.x - box.width;
                right = pos.x;
                width = box.width;
            }

            // position the element
            positions[id] = {
                left,
                width,
                right,
                top: pos.y,
                bottom: pos.y + box.height,
            };

            // remove used pointer
            // pointers.splice(idx);

            // add new pointers;
            pointers.push({
                x: pos.x + box.width,
                y: pos.y,
                toTheLeft: true,
            });

            pointers.push({
                x: pos.x,
                y: pos.y + box.height,
                toTheLeft: true,
            });

            pointers.push({
                x: pos.x + box.width,
                y: pos.y + box.height,
                toTheLeft: false,
            });
        }

        this.setState({ pointers, positions });

        this.props.didRefresh();
    };

    refreshUI = () => {
        this.setState(
            {
                pointers: [{ x: 0, y: 0, toTheLeft: true }],
            },
            () => {
                this.redrawUI({});
            },
        );
    };

    style = key => {
        const { positions } = this.state;

        const opacity = !positions[key] ? 0 : 1;

        const { left, width, top, bottom } = positions[key] || {};

        const height = bottom - top;

        let style = {
            top: 0,
            left: 0,
            width,
            height: isNaN(height) ? null : height,
            transform: `translateX(${left}px) translateY(${top}px)`,
            right: "auto",
            opacity,
        };

        return style;
    };

    renderChild = (child, index) => {
        const { maxItems } = this.props;

        const id = `ref${child.key}`;

        if (index >= maxItems) return null;

        return (
            <div
                id={id}
                className={"item"}
                key={child.key}
                style={this.style(id)}>
                {child}
            </div>
        );
    };

    renderPointers = () => {
        const { pointers } = this.state;

        return pointers.map(pointer => {
            const style = {
                left: pointer.x,
                top: pointer.y,
            };

            return <div style={style} className="pointer" />;
        });
    };

    renderChildren() {
        const { children } = this.props;

        if (!children || children.length === 0) {
            return this.props.renderEmpty();
        }

        return children.map((child, index) => this.renderChild(child, index));
    }

    render() {
        return (
            <div className="Isomer-container" ref={this.container}>
                {this.renderChildren()}
                {/* {this.renderPointers()} */}
            </div>
        );
    }
}

export default Isomer;
