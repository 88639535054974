export default `<h2>Volunteers</h2>
<p>
Online discussion interests you? Were you always occupied with
finding the best way to phrase and rephrase a thought?  Do you 
want to take a small or big part in shaping social discussion
in Facebook and in general? Great, perhaps you'd like to 
volunteer with us. We are currently in need for:
</p>
<h3>To go</h3>
<ul>
    <li>
        <strong>Taggers:</strong>
        People who tag DebDB's profile on interesting debates they come across with
    </li>
    <li>
        <strong>Explainer: </strong>
        People who add examples for logical fallacies to our fallacies wiki
        
    </li>
    <li>
        <strong>Word givers:</strong>
        People who spread the word of DebDB existence on the street and online
    </li>
</ul>
<h3>Medium</h3>
<ul>
    <li>
        <strong>Importers:</strong>
        People who install a DebDB chrome extension and import debates actively
    </li>
    <li>
        <strong>Analyzers:</strong>
        People who tag logical fallacies on imported debates
    </li>
    <li>
        <strong>NVCs:</strong>
        People with NVC training or experiences who will tag unmet needs
    </li>
</ul>
<h3>Large</h3>
<ul>
    <li>
        <strong>Idea people:</strong>
        People who will actively take part in our brian storming staff meetings 
    </li>
    <li>
        <strong>Developers:</strong>People who will take part in the development effort
    </li>
</ul>
<br />
<p>If one of these interets you feel free to:</p>`;
