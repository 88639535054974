// @flow
import React, { Component } from "react";
import "./Wiki.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";
import classnames from "classnames";

export class Wiki extends Component {
    state = {};

    renderContent(content = "") {
        return content
            .split("\n")
            .map((text, index) => <p key={index}>{text}</p>);
    }

    renderExample = (example, index) => {
        const { i18n } = this.context;
        const { id, description } = example || {};

        return (
            <div className="example" key={id}>
                <h3>
                    {i18n.wikiExample} #{index + 1}
                </h3>
                {this.renderContent(description)}
            </div>
        );
    };

    renderExamples() {
        const { i18n } = this.context;
        const { examples } = this.props;

        if (!examples || examples.length === 0) return null;

        return (
            <div className="examples">
                <h2>{i18n.wikiExamples}</h2>
                {examples.map((example, index) =>
                    this.renderExample(example, index),
                )}
            </div>
        );
    }

    renderChildren() {
        const { i18n } = this.context;
        const { wikiChildren } = this.props;

        if (!wikiChildren || wikiChildren.length === 0) return null;

        return (
            <div className="children">
                <h2>{i18n.wikiChildren}</h2>
                <ol>
                    {wikiChildren.map(tag => (
                        <li key={tag.index}>
                            <Link to={`/wiki/${tag.index}`}>{tag.title}</Link>
                        </li>
                    ))}
                </ol>
            </div>
        );
    }

    render() {
        const { tag, gold } = this.props,
            { title, description } = tag || {};

        const className = classnames("Wiki-container", {
            gold,
        });

        return (
            <div className={className}>
                <h1>{title}</h1>
                {this.renderContent(description)}
                {this.renderExamples()}
                {this.renderChildren()}
            </div>
        );
    }
}

Wiki.contextTypes = contextTypes;

export default Wiki;
