// @flow
import React, { Component } from "react";
import "./Search.scss";
import contextTypes from "../contextTypes";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

export class Search extends Component {
    state = {
        value: "",
        suggestions: [],
        items: [],
        tag: null,
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });
    };

    componentDidMount() {
        const { autocomplete, tag } = this.props;
        this.setState({ items: Object.values(autocomplete), tag });
    }

    componentWillReceiveProps(props) {
        const { autocomplete, tag } = props;
        this.setState({ items: Object.values(autocomplete) });

        if (this.state.tag !== props.tag) {
            this.setState({ tag });

            if (!tag) {
                this.setState({ value: "" });
                document.getElementById("auto-search").focus();
            }
        }
    }

    // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
    escapeRegexCharacters = str => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    getSuggestions = value => {
        const { items } = this.state;
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === "") {
            return [];
        }

        const regex = new RegExp(escapedValue, "i");

        return items
            .filter(item => regex.test(this.getSuggestionValue(item)))
            .slice(0, 6);
    };

    getSuggestionValue = suggestion => {
        return `${suggestion.title}`;
    };

    renderSuggestion = (suggestion, { query }) => {
        const suggestionText = `${suggestion.title}`;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <span
                className={"suggestion-content " + suggestion.twitter}
                style={{}}>
                <span className="name">
                    {parts.map((part, index) => {
                        const className = part.highlight ? "highlight" : null;

                        return (
                            <span className={className} key={index}>
                                {part.text}
                            </span>
                        );
                    })}
                </span>
            </span>
        );
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onBlur = ev => {
        const { value, items } = this.state;

        const found = items.filter(item => item.title === value);

        if (found && found.length > 0) {
            this.props.onSelect(found[0]);
        } else {
            this.props.onSelect();
        }

        this.props.onBlur(ev);
    };

    onKeyDown = ev => {
        const which = ev.which;

        if (which === 13) {
            setTimeout(this.onBlur, 0);
        }
    };

    render() {
        const { disabled, placeholder } = this.props;
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder,
            value,
            onKeyDown: this.onKeyDown,
            onChange: this.onChange,
            onFocus: this.props.onFocus,
            onBlur: this.onBlur,
            disabled,
            id: "auto-search",
        };

        // Finally, render it!
        return (
            <div className="Search-container">
                <Autosuggest
                    id="search"
                    disabled={disabled}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    highlightFirstSuggestion={true}
                />
            </div>
        );
    }
}

Search.contextTypes = contextTypes;

export default Search;
