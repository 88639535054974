import { createSelector } from "reselect";
import * as appState from "./selectors/appState";
import * as bons from "./selectors/bons";
import * as builder from "./selectors/builder";
import * as comments from "./selectors/comments";
// import * as contacts from "./selectors/contacts";
import * as debates from "./selectors/debates";
import * as examples from "./selectors/examples";
import * as modal from "./selectors/modal";
import * as sessions from "./selectors/sessions";
import * as personas from "./selectors/personas";
import * as pendings from "./selectors/pendings";
import * as dumps from "./selectors/dumps";
import * as tags from "./selectors/tags";
import * as blogs from "./selectors/blog";

const Stacks = createSelector(
    appState.currentIds,
    appState.editMode,
    comments.stack,
    (_currentIds, editMode, stack) => ({
        ..._currentIds,
        editMode,
        stack,
    }),
);

const StacksClean = createSelector(
    appState.currentIds,
    _currentIds => ({
        ..._currentIds,
    }),
);

const IconBar = createSelector(
    appState.overlay,
    appState.editMode,
    comments.stats,
    (overlay, _editMode, stats) => ({
        editMode: _editMode,
        overlay,
        stats,
    }),
);

const I = v => v;

const SideMenu = createSelector(
    appState.showSideMenu,
    _sideMenu => ({
        isVisible: _sideMenu,
    }),
);

const Toast = createSelector(
    appState.toast,
    _toast => ({
        toast: _toast,
    }),
);

const ScreenRoot = createSelector(
    debates.currentDebate,
    appState.overlay,
    appState.currentIds,
    appState.isInputFocused,
    appState.selectedSentencesRaw,
    debates.isVideo,
    appState.user,
    appState.editMode,
    debates.browse,
    appState.debateId,
    tags.tagsBasic,
    comments.stats,
    comments.comment,
    (
        debate,
        overlay,
        currentIds,
        isInputFocused,
        _selectedSentences,
        _isVideo,
        user,
        editMode,
        _debates,
        debateId,
        _tags,
        stats,
        comment,
    ) => ({
        debate,
        overlay,
        ...currentIds,
        isInputFocused,
        selectedSentences: _selectedSentences,
        isVideo: _isVideo,
        user,
        editMode,
        debates: _debates,
        debateId,
        tags: _tags,
        stats,
        comment,
    }),
);

const ScreenCanvasPreview = createSelector(
    debates.currentDebate,
    debate => ({
        debate,
    }),
);

const ScreenTagManager = createSelector(
    tags.tagsWithStats,
    tags.tag,
    appState.overlay,
    (_tags, _tag, _overlay) => ({
        tags: _tags,
        tag: _tag,
        overlay: _overlay,
    }),
);

const ScreenDebateManager = createSelector(
    debates.debatesTable,
    appState.viewIds,
    (_debates, _viewIds) => ({
        debates: _debates,
        viewIds: _viewIds,
    }),
);

const ScreenBlogManager = createSelector(
    blogs.blogPostTable,
    appState.viewIds,
    (_blogs, _viewIds) => ({
        blogs: _blogs,
        viewIds: _viewIds,
    }),
);

const ScreenCCManager = createSelector(
    debates.debatesCC,
    appState.viewIds,
    (_debates, _viewIds) => ({
        debates: _debates,
        viewIds: _viewIds,
    }),
);

const CCStudio = createSelector(
    debates.currentDebate,
    appState.currentIds,
    (_debate, _currentIds) => ({
        debate: _debate,
        ..._currentIds,
    }),
);

const ScreenHome = createSelector(
    debates.browse,
    _debates => ({
        debates: _debates,
    }),
);

const ScreenLogin = createSelector(
    appState.user,
    user => ({ user }),
);

const Breadcrumbs = createSelector(
    appState.isAdmin,
    isAdmin => ({ isAdmin }),
);

const AddDebate = createSelector(
    builder.debate,
    personas.personas,
    (_debate, _personas) => ({
        debate: _debate,
        personas: _personas,
    }),
);

const DebateDetails = createSelector(
    personas.personas,
    _personas => ({
        personas: _personas,
    }),
);

const XrayToggle = createSelector(
    appState.xray,
    appState.xrayEdit,
    (xray, xrayEdit) => ({
        xray,
        xrayEdit,
    }),
);

const UserBar = createSelector(
    appState.editMode,
    appState.user,
    appState.isAdmin,
    appState.role,
    appState.barMode,
    appState.showBack,
    appState.env,
    appState.site,
    appState.offline,
    debates.currentDebate,
    debates.debatesTable,
    (
        editMode,
        user,
        isAdmin,
        role,
        barMode,
        showBack,
        env,
        site,
        offline,
        _debate,
        _debates,
    ) => ({
        editMode,
        user,
        isAdmin,
        role,
        barMode,
        showBack,
        env,
        offline,
        site,
        debate: _debate,
        debates: _debates,
    }),
);

const ScreenCards = createSelector(
    tags.tagsBasic,
    _tags => ({
        tags: _tags,
    }),
);

const ScreenPersonasManager = createSelector(
    personas.personas,
    _personas => ({
        personas: _personas,
    }),
);

const ScreenPendingManager = createSelector(
    pendings.pendingsFiltered,
    appState.viewIds,
    (_pendings, _viewIds) => ({
        pendings: _pendings,
        viewIds: _viewIds,
    }),
);

const ScreenDumpManager = createSelector(
    dumps.dumpsFiltered,
    _dumps => ({
        dumps: _dumps,
    }),
);

const ScreenExamplesManager = createSelector(
    examples.examplesFiltered,
    tags.tags,
    tags.tag,
    appState.filterTagId,
    appState.overlay,
    (_examples, _tags, _tag, _filterTagId, _overlay) => ({
        examples: _examples,
        tags: _tags,
        tag: _tag,
        filterTagId: _filterTagId,
        overlay: _overlay,
    }),
);

const ScreenBrowse = createSelector(
    debates.browse,
    appState.isLoading,
    (_debates, isLoading) => ({
        debates: _debates,
        isLoading,
    }),
);

const ScreenFeed = createSelector(
    debates.browse,
    appState.isLoading,
    (_debates, isLoading) => ({
        debates: _debates,
        isLoading,
    }),
);

const ScreenManagement = createSelector(
    personas.personas,
    appState.user,
    appState.offline,
    (_personas, _user, offline) => ({
        personas: _personas,
        user: _user,
        offline,
    }),
);

const Wiki = createSelector(
    tags.tagWiki,
    tags.tagWikiBreadcrumbs,
    tags.tagWikiChildren,
    tags.tags,
    examples.examplesForTag,
    (_tag, _breadcrumbs, _wikiChildren, _tags, _examples) => ({
        tag: _tag,
        breadcrumbs: _breadcrumbs,
        wikiChildren: _wikiChildren,
        tags: _tags,
        examples: _examples,
    }),
);

const ScreenWikiHome = createSelector(
    tags.tags,
    appState.isAdmin,
    (_tags, isAdmin) => ({
        tags: _tags,
        isAdmin,
    }),
);

const ScreenCaeser = createSelector(
    pendings.pendings,
    pendings.pendingsSingle,
    appState.pendingId,
    (_pendings, _pendingsSingle, _pendingId) => {
        return {
            pendings: _pendingsSingle || _pendings,
            pendingId: _pendingId,
        };
    },
);

const RootModal = createSelector(
    modal.modal,
    _modal => ({
        modal: _modal,
    }),
);

const BlogPostEditor = createSelector(
    blogs.blog,
    appState.blogPostId,
    (_blogPost, blogPostId) => ({
        blogPost: _blogPost,
        blogPostId,
    }),
);

const Blog = createSelector(
    blogs.blogPostsList,
    blogs.blogPostsFeatured,
    (_blogPosts, featured) => ({
        blogPosts: _blogPosts,
        featured,
    }),
);

const BlogPost = createSelector(
    blogs.blog,
    _blogPost => ({
        blogPost: _blogPost,
    }),
);

const ScreenSessions = createSelector(
    sessions.sessions,
    _sessions => ({
        sessions: _sessions,
    }),
);

let selectors = {
    I,
    AddDebate,
    DebateDetails,
    Breadcrumbs,
    XrayToggle,
    IconBar,
    ScreenRoot,
    ScreenHome,
    ScreenLogin,
    ScreenManagement,
    ScreenTagManager,
    ScreenDebateManager,
    ScreenPendingManager,
    ScreenDumpManager,
    ScreenBlogManager,
    ScreenCCManager,
    ScreenPersonasManager,
    ScreenCaeser,
    ScreenExamplesManager,
    ScreenCards,
    ScreenBrowse,
    ScreenFeed,
    CCStudio,
    Wiki,
    ScreenWikiHome,
    RootModal,
    UserBar,
    Stacks,
    StacksClean,
    SideMenu,
    Toast,
    ScreenCanvasPreview,
    BlogPostEditor,
    Blog,
    BlogPost,
    ScreenSessions,
    ...appState,
    ...debates,
    ...comments,
    ...tags,
    ...modal,
    ...bons,
    ...blogs,
};

export default {
    get selectors() {
        return selectors;
    },
    addSelector: (key, value) => {
        selectors[key] = value;
    },
};
