import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "GET_SESSIONS":
            data = await globals.fetch(
                pathFromParams("/sessions", action),
                "GET",
            ); // prettier-ignore;
            await store.dispatch(actions.setSessions(data.sessions));
            break;

        case "ADD_SESSION":
            data = await globals.fetch(
                pathFromParams("/sessions", action),
                "POST",
                {
                    session: value,
                },
            ); // prettier-ignore;
            action.value = data.session || {};
            action.sessionId = action.value.id;
            break;

        case "PATCH_SESSION":
            data = await globals.fetch(
                pathFromParams("/sessions/:sessionId", action),
                "PUT",
                { session: value },
            ); // prettier-ignore;
            break;

        case "DELETE_SESSION":
            data = await globals.fetch(
                pathFromParams("/sessions/:sessionId", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        default:
    }
    return data;
};
