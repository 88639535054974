// @flow
import React, { Component } from "react";
import "./VideoSpeed.scss";
import contextTypes from "../contextTypes";

export class VideoSpeed extends Component {
    state = {};

    get playbackRate() {
        const { playbackRate = 0 } = this.props;

        return "x" + playbackRate;
    }

    render() {
        return <div className="VideoSpeed-container">{this.playbackRate}</div>;
    }
}

VideoSpeed.contextTypes = contextTypes;

export default VideoSpeed;
