export const bon = (state = {}, action) => {
    switch (action.type) {
        case "ADD_BON":
        case "SET_BON":
            return action.value;

        case "PATCH_BON":
            return {
                ...state,
                ...action.value,
            };

        case "VOTE_BON":
            return state;

        default:
            return state;
    }
};

export const debate = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_BONS":
            return action.value;

        case "ADD_BON":
        case "PATCH_BON":
        case "SET_BON":
        case "VOTE_BON":
            return {
                ...state,
                [action.bonId]: bon(state[action.bonId], action),
            };
        case "DELETE_BON":
            newState = { ...state };
            delete newState[action.bonId];
            return newState;

        default:
            return state;
    }
};

export const bons = (state = {}, action) => {
    switch (action.type) {
        case "SET_BONS":
        case "ADD_BON":
        case "PATCH_BON":
        case "DELETE_BON":
        case "VOTE_BON":
            return {
                ...state,
                [action.debId]: debate(state[action.debId], action),
            };

        default:
            return state;
    }
};
