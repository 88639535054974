// @flow
import React, { Component } from "react";
import "./BlogExamples.scss";
import contextTypes from "../../contextTypes";

export class BlogExamples extends Component {
    state = {};

    render() {
        return <div className="BlogExamples-container">BlogExamples</div>;
    }
}

BlogExamples.contextTypes = contextTypes;

export default BlogExamples;
