// @flow
import React, { Component } from "react";
import "./BlogFeatured.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";
import Icon from "../icons/Icon";

export class BlogFeatured extends Component {
    state = {};

    get items() {
        const { items } = this.props;
        return (items || []).slice(0, 3);
    }

    getTo = item => {
        const { index, redirectDebateIndex } = item;

        if (redirectDebateIndex) {
            return `/${redirectDebateIndex}`;
        } else {
            return `/blog/${index}`;
        }
    };

    renderItem = (item, index) => {
        const { title, imageUrl } = item;

        const style = {
            backgroundImage: `url(${imageUrl})`,
        };

        return (
            <Link to={this.getTo(item)}>
                <div className="item" key={index}>
                    <div className="image" style={style}>
                        <div className="play">
                            <Icon
                                name="play_arrow"
                                color="palevioletred"
                                size={40}
                            />
                        </div>
                    </div>
                    <div className="title">{title}</div>
                </div>
            </Link>
        );
    };

    render() {
        const { i18n } = this.context;
        return (
            <div className="BlogFeatured-container">
                <h2>{i18n.featuredVideos}</h2>
                <div className="items">
                    {this.items.map((item, index) =>
                        this.renderItem(item, index),
                    )}
                </div>
            </div>
        );
    }
}

BlogFeatured.contextTypes = contextTypes;

export default BlogFeatured;
