export const info = (state = {}, action) => {
    switch (action.type) {
        case "SET_DEBATE_INFO":
            return action.value;

        case "PATCH_DEBATE_INFO":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const progress = (state = {}, action) => {
    switch (action.type) {
        case "SET_DEBATE_PROGRESS":
            return action.value;

        case "PATCH_DEBATE_PROGRESS":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const stats = (state = {}, action) => {
    switch (action.type) {
        case "SET_DEBATE_STATS":
            return action.value;

        case "PATCH_DEBATE_STATS":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const root = (state = {}, action) => {
    switch (action.type) {
        case "SET_DEBATE_ROOT":
            return action.value;

        case "PATCH_DEBATE_ROOT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const participant = (state = {}, action) => {
    switch (action.type) {
        case "ADD_DEBATE_PARTICIPANT":
        case "SET_DEBATE_PARTICIPANT":
            return action.value;

        case "PATCH_DEBATE_PARTICIPANT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const participants = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_DEBATE_PARTICIPANTS":
            return action.value;

        case "SET_DEBATE_PARTICIPANT":
        case "ADD_DEBATE_PARTICIPANT":
        case "PATCH_DEBATE_PARTICIPANT":
            return {
                ...state,
                [action.participantId]: participant(
                    state[action.participantId],
                    action,
                ),
            };

        case "DELETE_DEBATE_PARTICIPANT":
            newState = { ...state };
            delete newState[action.participantId];
            return newState;

        default:
            return state;
    }
};

export const comment = (state = {}, action) => {
    switch (action.type) {
        case "SET_DEBATE_COMMENT":
        case "ADD_DEBATE_COMMENT":
            return action.value;

        case "PATCH_DEBATE_COMMENT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const comments = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_DEBATE_COMMENTS":
            return action.value;

        case "SET_DEBATE_COMMENT":
        case "ADD_DEBATE_COMMENT":
        case "PATCH_DEBATE_COMMENT":
            return {
                ...state,
                [action.commentId]: comment(state[action.commentId], action),
            };

        case "DELETE_DEBATE_COMMENT":
            newState = { ...state };
            delete newState[action.commentId];
            return newState;

        default:
            return state;
    }
};

export const debate = (state = {}, action) => {
    switch (action.type) {
        case "ADD_DEBATE":
        case "SET_DEBATE":
            return action.value;

        case "PATCH_DEBATE":
            return {
                ...state,
                ...action.value,
            };

        case "SET_DEBATE_COMMENTS":
        case "ADD_DEBATE_COMMENT":
        case "SET_DEBATE_COMMENT":
        case "PATCH_DEBATE_COMMENT":
        case "DELETE_DEBATE_COMMENT":
            return {
                ...state,
                comments: comments(state.comments, action),
            };
        case "SET_DEBATE_PARTICIPANTS":
        case "ADD_DEBATE_PARTICIPANT":
        case "SET_DEBATE_PARTICIPANT":
        case "PATCH_DEBATE_PARTICIPANT":
        case "DELETE_DEBATE_PARTICIPANT":
            return {
                ...state,
                participants: participants(state.participants, action),
            };

        case "SET_DEBATE_STATS":
        case "PATCH_DEBATE_STATS":
            return {
                ...state,
                stats: stats(state.stats, action),
            };

        case "SET_DEBATE_INFO":
        case "PATCH_DEBATE_INFO":
            return {
                ...state,
                info: info(state.info, action),
            };

        case "SET_DEBATE_PROGRESS":
        case "PATCH_DEBATE_PROGRESS":
            return {
                ...state,
                progress: progress(state.progress, action),
            };

        case "SET_DEBATE_ROOT":
        case "PATCH_DEBATE_ROOT":
            return {
                ...state,
                root: root(state.root, action),
            };

        default:
            return state;
    }
};

export const debates = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_DEBATES":
            return action.value;

        case "SET_DEBATE_COMMENTS":
        case "ADD_DEBATE_COMMENT":
        case "SET_DEBATE_COMMENT":
        case "PATCH_DEBATE_COMMENT":
        case "DELETE_DEBATE_COMMENT":
        case "SET_DEBATE_PARTICIPANTS":
        case "ADD_DEBATE_PARTICIPANT":
        case "SET_DEBATE_PARTICIPANT":
        case "PATCH_DEBATE_PARTICIPANT":
        case "DELETE_DEBATE_PARTICIPANT":
        case "SET_DEBATE_STATS":
        case "PATCH_DEBATE_STATS":
        case "SET_DEBATE_INFO":
        case "PATCH_DEBATE_INFO":
        case "SET_DEBATE_PROGRESS":
        case "PATCH_DEBATE_PROGRESS":
        case "SET_DEBATE":
        case "SET_DEBATE_ROOT":
        case "PATCH_DEBATE_ROOT":
        case "ADD_DEBATE":
        case "PATCH_DEBATE":
            return {
                ...state,
                [action.debateId]: debate(state[action.debateId], action),
            };

        case "DELETE_DEBATE":
            newState = { ...state };
            delete newState[action.debateId];
            return newState;

        default:
            return state;
    }
};
