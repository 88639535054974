// @flow
import React, { Component } from "react";
import "./ScreenLoader.scss";
import contextTypes from "../contextTypes";
import Loader from "../loader/Loader";

export class ScreenLoader extends Component {
    state = {
        showLoader: false,
    };

    componentDidMount() {
        this.timeout = setTimeout(this.show, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    show = () => {
        this.setState({ showLoader: true });
    };

    render() {
        const { showLoader } = this.state;

        return (
            <div className="ScreenLoader-container">
                {showLoader ? <Loader /> : null}
            </div>
        );
    }
}

ScreenLoader.contextTypes = contextTypes;

export default ScreenLoader;
