// @flow
import React, { Component } from "react";
import "./BlogElement.scss";
import contextTypes from "../contextTypes";
import allElements from "./allElements.read";

export class BlogElement extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    get Cmp() {
        const { type } = this.element;

        return allElements[type] || allElements.blank;
    }

    render() {
        const Cmp = this.Cmp;

        return (
            <div className="BlogElement-container">
                <div className="component">
                    <Cmp {...this.props} />
                </div>
            </div>
        );
    }
}

BlogElement.contextTypes = contextTypes;

export default BlogElement;
