import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "GET_DUMPS":
            data = await globals.fetch(
                pathFromParams("/dumps", action),
                "GET",
                value,
            ); // prettier-ignore;

            store.dispatch(actions.setDumps(data.dumps || {}));
            break;

        case "ADD_DUMP":
            await globals.fetch(pathFromParams("/dumps", action), "POST", {
                dump: value,
            }); // prettier-ignore;
            action.value = data.dump || {};
            action.dumpId = action.value.id;
            break;

        case "PATCH_DUMP":
            data = await globals.fetch(
                pathFromParams("/dumps/:dumpId", action),
                "PUT",
                {
                    dump: value,
                },
            ); // prettier-ignore;
            break;

        case "DELETE_DUMP":
            data = await globals.fetch(
                pathFromParams("/dumps/:dumpId", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        case "DELETE_DUMPS":
            data = await globals.fetch(
                pathFromParams("/dumps", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        default:
    }
    return data;
};
