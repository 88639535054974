import { createSelector } from "reselect";

import * as appState from "./appState";

export const bonsRaw = state => state.bons || {};
export const contactsRaw = state => state.contacts || {};
export const tagsRaw = state => state.tags || {};
export const debatesRaw = state => state.debates || {};
export const examplesRaw = state => state.examples || {};
export const pendingsRaw = state => state.pendings || {};
export const dumpsRaw = state => state.dumps || {};
export const personasRaw = state => state.personas || {};
export const sessionsRaw = state => state.sessions || {};
export const blogsRaw = state => state.blogs || {};

export const builderRaw = state => state.builder || {};
export const builderDebateRaw = state => state.builder.debate || {};

export const modalRaw = state => state.modal || {};

export const contact = createSelector(
    contactsRaw,
    appState.contactId,
    (_contacts, contactId) => _contacts[contactId] || {},
);

export const debate = createSelector(
    debatesRaw,
    appState.debateId,
    (_debates, debId) => {
        return _debates[debId] || {};
    },
);

export const blog = createSelector(
    blogsRaw,
    appState.blogPostId,
    (_blogs, blogPostId) => {
        return _blogs[blogPostId] || {};
    },
);

export const debateIsVideo = createSelector(
    debate,
    _debate => {
        const { info } = _debate || {},
            { media, size } = info || {};

        return media === "video" && size === "full";
    },
);

export const comments = createSelector(
    debate,
    _debate => {
        return _debate.comments || {};
    },
);

export const root = createSelector(
    debate,
    _debate => {
        return _debate.root || {};
    },
);

export const elements = createSelector(
    blog,
    _blog => {
        return _blog.elements || {};
    },
);

export const element = createSelector(
    elements,
    appState.elementId,
    (_elements, elementId) => {
        return _elements[elementId];
    },
);

export const comment = createSelector(
    comments,
    appState.commentId,
    (_comments, commentId) => {
        return _comments[commentId];
    },
);

export const participants = createSelector(
    debate,
    _debate => {
        return _debate.participants;
    },
);

export const persona = createSelector(
    personasRaw,
    appState.personaId,
    (_personas, personaId) => _personas[personaId] || {},
);

export const session = createSelector(
    sessionsRaw,
    appState.sessionId,
    (_sessions, sessionId) => _sessions[sessionId] || {},
);

export const pending = createSelector(
    pendingsRaw,
    appState.pendingId,
    (_pendings, pendingId) => _pendings[pendingId] || {},
);

export const dump = createSelector(
    dumpsRaw,
    appState.dumpId,
    (_dumps, dumpId) => _dumps[dumpId] || {},
);

// per debate
export const bons = createSelector(
    bonsRaw,
    appState.debateId,
    (_bons, debId) => _bons[debId] || {},
);

export const bon = createSelector(
    bons,
    appState.bonId,
    (_bons, bonId) => {
        return _bons[bonId];
    },
);

// per overlay
export const tags = createSelector(
    tagsRaw,
    appState.overlay,
    (_tags, _overlay) => {
        return _tags[_overlay] || {};
    },
);

export const tag = createSelector(
    tags,
    appState.tagId,
    (_tags, _tagId) => {
        return _tags[_tagId] || {};
    },
);

export const examples = createSelector(
    examplesRaw,
    appState.overlay,
    (_examples, overlay) => {
        return _examples[overlay] || {};
    },
);

export const example = createSelector(
    examples,
    appState.exampleId,
    (_examples, _exampleId) => {
        return _examples[_exampleId] || {};
    },
);
