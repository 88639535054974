import actions from "../sparkActions";

export default dispatch => {
    return {
        getPersonas: () => {
            return dispatch(actions.getPersonas());
        },
        getPersona: personaId => {
            return dispatch(actions.getPersona(personaId));
        },
        addPersona: (personaId, persona) => {
            return dispatch(actions.addPersona(personaId, persona));
        },
        showAddPersona: () => {
            dispatch(actions.setModal({ id: "addPersona" }));
        },
        showEditPersona: personaId => {
            dispatch(actions.patchCurrentId({ personaId }));
            dispatch(actions.setModal({ id: "editPersona" }));
        },
        patchPersona: (personaId, persona) => {
            return dispatch(actions.patchPersona(personaId, persona));
        },
    };
};
