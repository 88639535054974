import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "GET_PERSONAS":
            data = await globals.fetch(
                pathFromParams("/personas", action),
                "GET",
            ); // prettier-ignore;
            store.dispatch(actions.setPersonas(data.personas));
            break;

        case "GET_PERSONA":
            data = await globals.fetch(
                pathFromParams("/personas/:personaId", action),
                "GET",
            ); // prettier-ignore;
            data = data.persona;
            break;

        case "ADD_PERSONA":
            data = await globals.fetch(
                pathFromParams("/personas", action),
                "POST",
                {
                    persona: value,
                },
            ); // prettier-ignore;
            action.value = data.persona || {};
            action.personaId = action.value.id;
            break;

        case "PATCH_PERSONA":
            data = await globals.fetch(
                pathFromParams("/personas/:personaId", action),
                "PUT",
                { persona: value },
            ); // prettier-ignore;
            break;

        case "DELETE_PERSONA":
            data = await globals.fetch(
                pathFromParams("/personas/:personaId", action),
                "DELETE",
            ); // prettier-ignore;
            break;

        default:
    }
    return data;
};
