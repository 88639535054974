// @flow
import React, { Component } from "react";
import "./VideoTime.scss";
import contextTypes from "../contextTypes";
import dayjs from "dayjs";

export class VideoTime extends Component {
    state = {};

    get time() {
        const { playedSeconds = 0 } = this.props;

        return dayjs.duration(playedSeconds);
    }

    render() {
        return <div className="VideoTime-container">{this.time}</div>;
    }
}

VideoTime.contextTypes = contextTypes;

export default VideoTime;
