// @flow
import React, { Component } from "react";
import "./Done.scss";
import contextTypes from "../../../contextTypes";
import classnames from "classnames";
import Icon from "../../../icons/Icon";

const noop = () => {};

export class Done extends Component {
    static defaultProps = {
        onClick: noop,
    };

    state = {};

    get style() {
        const { size = 22 } = this.props;

        return {
            width: size,
            height: size,
            borderRadius: size,
        };
    }

    onClick = ev => {
        ev.stopPropagation();
        this.props.onClick(ev);
    };

    render() {
        const { value, size = 22 } = this.props;

        const className = classnames("Done-container", {
            on: value,
        });
        return (
            <div
                className={className}
                style={this.style}
                onClick={this.onClick}>
                <Icon name="done" size={(size / 3) * 2} />
            </div>
        );
    }
}

Done.contextTypes = contextTypes;

export default Done;
