import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";
import * as bons from "./bons";
import { parseComment } from "./parsers/comments";

export const commentDependencies = createSelector(
    raw.participants,
    bons.bonsGroupByComment,
    appState.xray,
    appState.editMode,
    appState.bonIdHover,
    raw.debateIsVideo,
    (participants, _bons, xray, editMode, bonId, isVideo) => ({
        bons: _bons,
        participants: participants || {},
        xray,
        editMode,
        bonId,
        isVideo,
    }),
);

export const root = createSelector(
    raw.root,
    commentDependencies,
    (_root, dependencies) => {
        return parseComment(_root, dependencies);
    },
);

export const comments = createSelector(
    raw.comments,
    commentDependencies,
    (_comments, dependencies) => {
        if (!_comments) return [];

        return Object.values(_comments || {})
            .map(comment => parseComment(comment, dependencies))
            .sort((a, b) => {
                if (a.order === b.order) return 0;

                return a.order > b.order ? 1 : -1;
            });
    },
);

export const comment = createSelector(
    raw.comment,
    bons.bonsForComment,
    (comment, bons) => {
        return { ...comment, ...bons };
    },
);

export const stack = createSelector(
    bons.bonsForComment,
    appState.overlay,
    appState.xrayEdit,
    (_bons, overlay, xrayEdit) => {
        let stack = _bons;
        return stack;
    },
);

export const stats = createSelector(
    comment,
    _comment => {
        return _comment.stats;
    },
);

export const videoHighlights = createSelector(
    raw.bons,
    appState.bonId,
    raw.debateIsVideo,
    (_bons, bonId, isVideo) => {
        if (!isVideo) {
            return {};
        }

        return Object.values(_bons).reduce((output, bon) => {
            const { selectedSentences = [], id } = bon;

            selectedSentences.forEach(sentenceId => {
                const color = "blue";
                output[sentenceId] = bonId === id ? "brown" : color;
            });

            return output;
        }, {});
    },
);

export const videoStack = createSelector(
    stack,
    raw.debateIsVideo,
    (_bons, isVideo) => {
        if (!isVideo) {
            return {};
        }

        return _bons;
    },
);
