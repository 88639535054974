// @flow
import React, { Component } from "react";
import "./ScreenBrowse.scss";
import contextTypes from "../contextTypes";
import LofCard from "../lof-card/LofCard";
import Isomer from "../isomer/Isomer";
import scroll from "../../utils/scroll";
import Loader from "../loader/Loader";
import { forceCheck } from "react-lazyload";
import throttle from "lodash/throttle";
import CardSuggestLink from "../card-suggest-link/CardSuggestLink";

const Div = props => <div className={props.className}>{props.children}</div>;

export class ScreenBrowse extends Component {
    constructor(props) {
        super();

        this.state = {
            width: window.innerWidth - 20,
            debates: props.debates,
        };
    }

    async componentDidMount() {
        this.props.changeDebateId(0);
        this.props.changeCommentId("i0");
        this.props.changeTagId(0);
        this.props.changeShowBack(false);
        scroll.listen("ScreenBrowse");
        scroll.refresh("ScreenBrowse", false);
        window.addEventListener("resize", this.onResize);
        this.onResize = throttle(this.onResize, 300);
    }

    componentWillReceiveProps(props) {
        const { debates } = props;

        if (debates !== this.state.debates) {
            this.setState({ debates });
        }
    }

    get debates() {
        const { debates } = this.state;
        const arr = Object.values(debates);
        arr.unshift({ type: "suggestion" });
        return arr;
    }

    componentWillUnmount() {
        scroll.unlisten("ScreenBrowse");
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        this.setState({ width: window.innerWidth - 20 });
    };

    onClick = index => {
        this.props.history.push(`/${index}`);
    };

    get style() {
        const { width } = this.state;

        return { width };
    }

    renderLoader() {
        return (
            <div className="loader">
                <Loader />
            </div>
        );
    }

    renderEmpty = () => {
        const { i18n } = this.context;

        return <div className="empty">{i18n.noItems}</div>;
    };

    didRefresh = () => {
        forceCheck();
    };

    //

    renderCard = debate => {
        const { type } = debate || {};

        if (type === "suggestion") {
            return <CardSuggestLink />;
        }

        return (
            <LofCard key={debate.id} debate={debate} onClick={this.onClick} />
        );
    };

    renderInner() {
        const { isMobile } = this.context;
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoader();
        }

        const mobile = isMobile;

        const Cmp = mobile ? Div : Isomer;

        return (
            <Cmp
                itemSelector=".card"
                className="container"
                didRefresh={this.didRefresh}
                renderEmpty={this.renderEmpty}>
                {this.debates.map(this.renderCard)}
            </Cmp>
        );
    }

    render() {
        return (
            <div className="ScreenBrowse-container" style={this.style}>
                {this.renderInner()}
            </div>
        );
    }
}

ScreenBrowse.contextTypes = contextTypes;

export default ScreenBrowse;
