// @flow
import React, { Component } from "react";
import "./BlogBox.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";
import { Link } from "react-router-dom";

export class BlogBox extends Component {
    state = {};

    get blogPost() {
        const { blogPost } = this.props;
        return blogPost || {};
    }

    get styleImage() {
        const { imageUrl } = this.blogPost;

        return {
            color: "green",
            backgroundSize: "cover",
            backgroundImage: `url(${imageUrl})`,
        };
    }

    get to() {
        const { index, redirectDebateIndex } = this.blogPost;

        if (redirectDebateIndex) {
            return `/${redirectDebateIndex}`;
        } else {
            return `/blog/${index}`;
        }
    }

    render() {
        const { i18n } = this.context;
        const { size, flip } = this.props;
        const { title, authorName = i18n.byDebDB } = this.blogPost;

        const className = classnames("BlogBox-container", `size-${size}`, {
            flip,
        });

        return (
            <div className={className}>
                <Link className="image-link" to={this.to}>
                    <div className="image" style={this.styleImage} />
                </Link>
                <div className="details">
                    <div className="inner">
                        <Link className="title" to={this.to}>
                            {title}
                        </Link>
                        <div className="author">
                            <span className="by">{i18n.by}</span>
                            <span className="authorName">{authorName}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlogBox.contextTypes = contextTypes;

export default BlogBox;
