// @flow
import React, { Component } from "react";
import "./BlogWikiLink.scss";
import contextTypes from "../../contextTypes";
import { Link } from "react-router-dom";

export class BlogWikiLink extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    get data() {
        const { data } = this.element || {};
        return data;
    }

    render() {
        const { content = "" } = this.element || {};
        const { tagIndex } = this.data;

        return (
            <div className="BlogWikiLink-container">
                <Link to={`/wiki/${tagIndex}`}>{content}</Link>
            </div>
        );
    }
}

BlogWikiLink.contextTypes = contextTypes;

export default BlogWikiLink;
