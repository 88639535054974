export const session = (state = {}, action) => {
    switch (action.type) {
        case "ADD_SESSION":
            return action.value;

        case "PATCH_SESSION":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const sessions = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_SESSIONS":
            return action.value;

        case "ADD_SESSION":
        case "PATCH_SESSION":
            return {
                ...state,
                [action.sessionId]: session(state[action.sessionId], action),
            };

        case "DELETE_SESSION":
            newState = { ...state };
            delete newState[action.sessionId];
            return newState;

        default:
            return state;
    }
};
