// @flow
import React, { Component } from "react";
import "./NewBon.scss";
import contextTypes from "../contextTypes";
import Search from "../search/Search";
import * as storage from "../../utils/storage";
import classnames from "classnames";
import ButtonWithLoader from "../buttons/ButtonWithLoader";

const placeholders = {
    lofs: "lofNew",
    nvcs: "nvcNew",
};

const placeholdersRephrase = {
    lofs: "rephrase",
    nvcs: "rephrase",
    rephrase: "rephrase",
};

const storageKey = "TRANSIENT_BON";

export class NewBon extends Component {
    state = {
        show: false,
        explanation: storage.getString(storageKey),
    };

    constructor() {
        super();

        this.textarea = React.createRef();
    }

    get disabled() {
        const { selectedSentences } = this.props;

        return !selectedSentences || selectedSentences.length === 0;
    }

    get placeholderText() {
        const { i18n } = this.context;
        const { overlay } = this.props;

        const p = this.isRephrasing ? placeholdersRephrase : placeholders;

        return i18n[p[overlay]];
    }

    get isRephrasing() {
        const { flavour } = this.props;
        return flavour === "rephrasing";
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    showExplanation = show => {
        if (show) {
            this.textarea.current.focus();
        }

        this.timeout = setTimeout(() => {
            this.setState({ show });
        }, 200);
    };

    onSelect = tag => {
        const { overlay } = this.props;

        const show = tag && overlay !== "hashTags";

        this.setState({ tag });
        this.showExplanation(show);

        if (overlay === "hashTags") {
            this.addBon(tag);
        }
    };

    get selectedSentenceArray() {
        let { selectedSentences } = this.props;

        return Object.keys(selectedSentences)
            .filter(key => selectedSentences[key])
            .map(i => parseInt(i, 10));
    }

    addBon = async tag => {
        let { debId, commentId } = this.props;
        const { explanation } = this.state,
            { id: tagId } = tag || {};

        const input = document.getElementById("auto-search");

        if (!tag && !this.isRephrasing) {
            input.focus();
            input.select();
            return;
        }

        if (!explanation) {
            this.textarea.current.focus();
            return;
        }

        this.setState({ isSaving: true });
        storage.setString(storageKey, "");

        await this.props.addBon(debId, {
            tagId: tagId || "0",
            commentId,
            selectedSentences: this.selectedSentenceArray,
            explanation,
        });

        this.setState({
            isSaving: false,
            tag: null,
            explanation: "",
            show: false,
        });
    };

    onFocus = ev => {
        this.props.changeFocus(true);
    };

    onBlur = ev => {
        this.props.changeFocus(false);
    };

    onChange = ev => {
        const value = ev.target.value;
        storage.setString(storageKey, value);
        this.setState({ explanation: value });
    };

    onKeyDown = ev => {
        const { tag } = this.state;

        if (ev.which === 13 && (ev.metaKey || ev.altKey)) {
            this.addBon(tag);
        }
    };

    renderSearch() {
        const { tags } = this.props;
        const { tag } = this.state;

        if (this.isRephrasing) return null;

        return (
            <Search
                tag={tag}
                disabled={this.disabled}
                autocomplete={tags}
                onSelect={this.onSelect}
                placeholder={this.placeholderText}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
            />
        );
    }

    renderExplanation() {
        const { i18n } = this.context;
        const { explanation, show, isSaving, tag } = this.state;

        const className = classnames("more", {
            show,
            rephrase: this.isRephrasing,
        });

        return (
            <div className={className}>
                <textarea
                    ref={this.textarea}
                    value={explanation}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    placeholder={this.placeholderText}
                    className="explanation"
                    rows={30}
                    cols={30}
                />
                <br />
                <ButtonWithLoader
                    isLoading={isSaving}
                    onClick={() => this.addBon(tag)}>
                    {i18n.add}
                </ButtonWithLoader>
            </div>
        );
    }

    render() {
        return (
            <div className="NewBon-container">
                <div className="row">{this.renderSearch()}</div>
                {this.renderExplanation()}
            </div>
        );
    }
}

NewBon.contextTypes = contextTypes;

export default NewBon;
