// @flow
import React, { Component } from "react";
import contextTypes from "../contextTypes";
import Avatar from "../avatar/Avatar";
import dayjs from "dayjs";
import "./PostHeader.scss";

const noop = () => {};

export class PostHeader extends Component {
    static defaultProps = {
        onAvatarClick: noop,
        onDateClick: noop,
    };

    state = {};

    render() {
        const { comment } = this.props,
            { dateDebate, authorImageUrl, authorName } = comment || {};

        const date = dayjs(dateDebate);

        const dateText = date.isValid() ? date.fromNow() : "-";

        return (
            <div className="PostHeader-container">
                <div
                    className="avatar animated fadeIn"
                    onClick={this.props.onAvatarClick}>
                    <Avatar url={authorImageUrl} />
                </div>
                <div className="details">
                    <div className="name">{authorName}</div>
                    <div className="date" onClick={this.props.onDateClick}>
                        {dateText}
                    </div>
                </div>
            </div>
        );
    }
}

PostHeader.contextTypes = contextTypes;

export default PostHeader;
