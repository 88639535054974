// @flow
import React, { Component } from "react";
import "./CardBooklet.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";

export class CardBooklet extends Component {
    state = {};

    render() {
        const { i18n } = this.context;

        return (
            <Link to="/booklet" className="no-link">
                <div className="CardBooklet-container card">
                    <div className="inner">
                        <div className="question">{i18n.booklet}</div>
                        <button className="button">{i18n.bookletCTA}</button>
                    </div>
                </div>
            </Link>
        );
    }
}

CardBooklet.contextTypes = contextTypes;

export default CardBooklet;
