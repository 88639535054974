const lofsXToContent = (lofsX = []) => {
    if (lofsX.length === 0) {
        return "-";
    }

    return lofsX.map(i => " - " + i.explanation).join("\n");
};

const nvcsXToContent = (nvcsX = {}) => {
    if (nvcsX.length === 0) {
        return "-";
    }

    nvcsX.sort((a, b) => {
        const totalA = a.upVote + a.stars;
        const totalB = b.upVote + b.stars;

        return totalA - totalB;
    });

    const item = nvcsX[0],
        { explanation } = item || {};

    return explanation;
};

export const commentToContent = (overlay, x) => {
    switch (overlay) {
        case "nvcs":
            return nvcsXToContent(x.nvcsX);
        case "lofs":
            return lofsXToContent(x.lofsX);
        default:
            return "";
    }
};

export const calcDebateLength = deb => {
    const { comments } = deb;

    return Object.values(comments).reduce((output, comment) => {
        const { content = "" } = comment;
        return output + content.length;
    }, 0);
};
