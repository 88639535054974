// @flow
import React, { Component } from "react";
import "./VideoControls.scss";
import contextTypes from "../contextTypes";
import Key from "../key/Key";
import * as options from "../../constants/options";

export class VideoControls extends Component {
    state = {};

    render() {
        const { videoState = {}, controls } = this.props;

        const keys =
            controls === "simple"
                ? options.keysCCStudio
                : options.keysCCStudioEdit;

        return (
            <div className="VideoControls-container">
                {Object.values(keys).map(config => {
                    const { id, icon, disabled } = config;

                    const _icon = icon(videoState || {});
                    const _disabled = disabled(videoState || {});

                    return (
                        <Key
                            key={id}
                            config={config}
                            flavour={controls}
                            icon={_icon}
                            disabled={_disabled}
                            onClick={this.props.onClick}
                        />
                    );
                })}
            </div>
        );
    }
}

VideoControls.contextTypes = contextTypes;

export default VideoControls;
