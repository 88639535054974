// @flow
import React, { Component } from "react";
import "./WebBarMenu.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";

export class WebBarMenu extends Component {
    state = {};

    render() {
        const { i18n } = this.context;

        return (
            <div className="WebBarMenu-container">
                <ul>
                    <li>
                        <Link to="/about">{i18n.webbarAbout}</Link>
                    </li>
                    <li className="strong">
                        <Link to="/volunteers">{i18n.webbarVolunteers}</Link>
                    </li>
                    <li>
                        <Link to="/wiki">{i18n.webbarWiki}</Link>
                    </li>
                    <li>
                        <Link to="/contact">{i18n.webbarContact}</Link>
                    </li>
                </ul>
            </div>
        );
    }
}

WebBarMenu.contextTypes = contextTypes;

export default WebBarMenu;
