import { commentToContent } from "./content";

export const parseComment = (
    comment,
    { bons, participants, xray, editMode, bonId, isVideo },
) => {
    let bonsForComment = Object.values(bons[comment.id] || {});

    const highlights = bonsForComment.reduce((output, bon) => {
        const { selectedSentences = [], id } = bon;

        selectedSentences.forEach(sentenceId => {
            const color = "blue";
            output[sentenceId] = bonId === id ? "brown" : color;
        });

        return output;
    }, {});

    const stats = bonsForComment.length;
    let content = comment.content;

    if (xray && !editMode) {
        content = commentToContent(bonsForComment);
    }

    const { participantId } = comment,
        participant = participants[participantId];

    return { ...comment, participant, stats, content, highlights };
};
