// @flow
import React, { Component } from "react";
import "./ScreenLogout.scss";
import contextTypes from "../contextTypes";

export class ScreenLogout extends Component {
    state = {};

    componentDidMount() {
        this.props.signOut();

        this.props.history.push("/");
    }

    render() {
        return <div className="ScreenLogout-container">...</div>;
    }
}

ScreenLogout.contextTypes = contextTypes;

export default ScreenLogout;
