// @flow
import React, { Component } from "react";
import "./DefinitionModal.scss";
import contextTypes from "../contextTypes";
import Modal from "../modal/Modal";
import components from "../../config/client.components";

export class DefinitionModal extends Component {
    state = {};

    render() {
        const { i18n } = this.context;
        const Wiki = components.wiki;

        return (
            <Modal title={i18n.definition} onClose={this.props.onClose}>
                <div className="DefinitionModal-container">
                    <Wiki gold={true} />
                </div>
            </Modal>
        );
    }
}

DefinitionModal.contextTypes = contextTypes;

export default DefinitionModal;
