import PropTypes from "prop-types";

export default {
    i18n: PropTypes.object,
    colors: PropTypes.object,
    icons: PropTypes.object,
    animations: PropTypes.object,
    showToast: PropTypes.func,
    isMobile: PropTypes.bool,
    fontFamily: PropTypes.string,
    locale: PropTypes.string,
    isRTL: PropTypes.bool,
};
