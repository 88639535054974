// @flow
import React, { Component } from "react";
import "./VideoPost.scss";
import contextTypes from "../contextTypes";
import VideoPlayer from "../video-player/VideoPlayer";
import Sentences from "../sentences-video/SentencesVideo";
import PostHeader from "../post-header/PostHeader";
import classnames from "classnames";

import VideoPostLayer from "../video-post-layer/VideoPostLayer";

const noop = () => {};

export class VideoPost extends Component {
    state = {
        currentIndex: -1,
        isPlaying: false,
        neverPlayed: true,
    };

    constructor() {
        super();

        this.videoPlayer = React.createRef();
    }

    get debate() {
        const { debate } = this.props;

        return debate || {};
    }

    get root() {
        const { root } = this.debate;
        return root || {};
    }

    get comments() {
        const { comments } = this.debate;
        return Object.values(comments || {});
    }

    get content() {
        return this.comments
            .map(comment => comment.content.trim() || "")
            .join("");
    }

    get isLTR() {
        const match = this.content.substr(0, 1).match(/[a-zA-Z]/);
        return match && match.length > 0;
    }

    get currentIndex() {
        const { playedSeconds } = this.state;

        if (!playedSeconds) {
            return -1;
        }

        let index = 0;

        for (let comment of this.comments) {
            const { timeComment } = comment;

            if (timeComment > playedSeconds + 0.3) {
                break;
            }

            index++;
        }

        return index - 1;
    }

    get highlights() {
        const { videoHighlights } = this.debate;
        return videoHighlights;
    }

    setFirstComment = () => {
        setTimeout(() => {
            this.props.changeCommentId("i0");
        });
    };

    componentDidMount() {
        this.setFirstComment();
    }

    onProgress = data => {
        this.setState(data);
    };

    renderHeader() {
        const { isMobile } = this.context;
        const { neverPlayed } = this.state;

        if (!neverPlayed && isMobile) return null;

        return (
            <div className="header">
                <PostHeader
                    comment={this.root}
                    onAvatarClick={noop}
                    onDateClick={noop}
                />
            </div>
        );
    }

    togglePlaying = () => {
        let { isPlaying } = this.state;
        isPlaying = !isPlaying;
        this.setState({ isPlaying });
    };

    renderRoot() {
        const { isMobile } = this.context;
        const { videoUrl } = this.root;
        const { isPlaying } = this.state;

        return (
            <div className="root">
                <VideoPlayer
                    ref={this.videoPlayer}
                    onPlay={() =>
                        this.setState({ isPlaying: true, neverPlayed: false })
                    }
                    onPause={() => this.setState({ isPlaying: false })}
                    playing={isPlaying}
                    url={videoUrl}
                    height={isMobile ? 210 : 270}
                    onProgress={this.onProgress}
                />
            </div>
        );
    }

    clickSentence = index => {
        this.jumpToSentence(index);
    };

    renderCC() {
        const { editMode } = this.props;

        const className = this.isLTR ? "ltr" : "rtl";

        return (
            <div className="cc">
                <Sentences
                    className={className}
                    comments={this.comments}
                    highlights={this.highlights}
                    currentIndex={this.currentIndex}
                    editMode={editMode}
                    selectedSentences={this.props.selectedSentences}
                    onSentenceClick={this.props.onSentenceClick}
                    changeSentence={this.props.changeSentence}
                    clickSentence={this.clickSentence}
                />
            </div>
        );
    }

    jumpToSentence = index => {
        const comment = this.comments[index];

        if (!comment || !comment.timeComment) return;

        const { timeComment } = comment;
        this.videoPlayer.current.seek(timeComment);
    };

    renderLayer() {
        const { isMobile } = this.context;
        const { neverPlayed, isPlaying } = this.state;

        const className = classnames("lofs", {
            "desktop-panel": !isMobile,
        });

        return (
            <div className={className}>
                <VideoPostLayer
                    debate={this.debate}
                    togglePlaying={this.togglePlaying}
                    isPlaying={isPlaying}
                    currentIndex={this.currentIndex}
                    neverPlayed={neverPlayed}
                    jumpToSentence={this.jumpToSentence}
                />
            </div>
        );
    }

    render() {
        const { minimal } = this.props;

        const className = classnames("VideoPost-container", {
            minimal,
        });

        return (
            <article className={className}>
                {this.renderHeader()}
                {this.renderRoot()}
                {this.renderCC()}
                {this.renderLayer()}
            </article>
        );
    }
}

VideoPost.contextTypes = contextTypes;

export default VideoPost;
