// @flow
import React, { Component } from "react";
import "./Blog.scss";
import contextTypes from "../contextTypes";
import BlogTopHeader from "../blog-top-header/BlogTopHeader";
import BlogBoxes from "../blog-boxes/BlogBoxes";
import BlogList from "../blog-list/BlogList";
import BlogFeatured from "../blog-featured/BlogFeatured";
import BlogFooter from "../blog-footer/BlogFooter";

export class Blog extends Component {
    state = {};

    get blogPosts() {
        const { blogPosts } = this.props;

        return blogPosts;
    }

    getPosts = i => {
        const ranges = [[0, 8], [9, 27]];
        const range = ranges[i];

        if (!Array.isArray(this.blogPosts)) return [];

        return this.blogPosts.slice(range[0], range[1]);
    };

    componentDidMount() {
        this.props.getBlogPosts();
    }

    render() {
        const { featured } = this.props;

        return (
            <div className="Blog-container">
                <BlogTopHeader />
                <div className="content">
                    <BlogBoxes items={this.getPosts(0)} />
                </div>

                <div className="lists">
                    <div className="row">
                        <div className="col col-1">
                            <BlogList items={this.getPosts(1)} />
                        </div>
                        <div className="col col-2 featured">
                            <BlogFeatured items={featured} />
                        </div>
                    </div>
                </div>

                <BlogFooter />
            </div>
        );
    }
}

Blog.contextTypes = contextTypes;

export default Blog;
