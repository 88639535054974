// @flow
import React, { Component } from "react";
import "./BlogList.scss";
import contextTypes from "../contextTypes";
import BlogListItem from "../blog-list-item/BlogListItem";
import BlogStripeWhite from "../blog-stripe-white/BlogStripeWhite";
import BlogBigBox from "../blog-big-box/BlogBigBox";

export class BlogList extends Component {
    state = {};

    renderItem = (item, index) => {
        const Cmp = (index + 1) % 5 === 0 ? BlogStripeWhite : BlogListItem;

        if (index === 8) {
            return <BlogBigBox item={item} />;
        }
        return <Cmp key={index} item={item} />;
    };

    render() {
        const { items } = this.props;

        return (
            <div className="BlogList-container">
                {items.map((item, index) => this.renderItem(item, index))}
            </div>
        );
    }
}

BlogList.contextTypes = contextTypes;

export default BlogList;
