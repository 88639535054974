export default {
    id: "debdb",
    title: "About the project",
    color: "#fff",
    description: `DebDB strives to fix the core issues of online discussions and supply tools for a next generation of practical on-topic debate. 

    DebDB’s community creates a catalog of debates and adds layers of Logical fallacies and NVC to each debate. The debates go through a process of obfuscation in which the names and avatars of the participants are replaced in order to ensure privacy and focus on the content itself. Public posts of politicians are left with the original names and avatars.
    
    Online discussions, whether private or public, are many times intense, leaving both sides emotionally triggered and feeling the other side made false arguments. Rarely do we critiaclly inspect our own fallacies or look for new information to enrich our own outlook. The outcome of this dynamics is a “poisonous” discussion ecosystem, in the sense that being exposed to it takes us further from the truth it inspire to unveil and leaves us with a bitter taste of polarization.
    
    The core reason is not rooted in the medium, or the participants, but in the debate skills of the parties. When people are not trained to identify logical fallacies, they tend to defend against them by presenting logical fallacies of their own. This feedback loop can easily turn a debate into Eristic - the equivalent of rhetorical trench warfare that is forgotten in the ditches of our feeds.
    
    The DebDB scans and analyzes debates with the help of a professional impartial team and by this enables the debate’s participants to study and better understand a debate’s dynamics. In that way, the process is similar to “replays” in sport events, where slowing down the speed exposes you to a new opportunity for inspection and understanding.
    `,
    startingDate: "2019-05-01",
    status: "running",
    duration: "2 months",
    webLink: "https://debdb.com",
    facebookLink: "",
    medium: "Website and facebook",
    nextGoal: "Establish a moderation community",
    numberOfParticipants: 2,
    hours: 400,
};
