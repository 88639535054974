import React from "react";

import BlogHeader from "./blog-header/BlogHeader.read";
import BlogAuthor from "./blog-author/BlogAuthor.read";
import BlogImage from "./blog-image/BlogImage.read";
import BlogParagraph from "./blog-paragraph/BlogParagraph.read";
import BlogLofDefinition from "./blog-lof-definition/BlogLofDefinition.read";
import BlogRoot from "./blog-root/BlogRoot.read";
import BlogBeforeAfter from "./blog-before-after/BlogBeforeAfter.read";
import BlogExamples from "./blog-examples/BlogExamples.read";
import BlogVideo from "./blog-video/BlogVideo.read";
import BlogH from "./blog-h/BlogH.read";
import BlogQuote from "./blog-quote/BlogQuote.read";
import BlogWikiLink from "./blog-wiki-link/BlogWikiLink.read";
import BlogDebateLink from "./blog-debate-link/BlogDebateLink.read";

export default {
    header: BlogHeader,
    author: BlogAuthor,
    image: BlogImage,
    paragraph: BlogParagraph,
    lofDefintion: BlogLofDefinition,
    root: BlogRoot,
    beforeAfter: BlogBeforeAfter,
    examples: BlogExamples,
    h: BlogH,
    quote: BlogQuote,
    wikiLink: BlogWikiLink,
    debateLink: BlogDebateLink,
    video: BlogVideo,
    blank: () => <div />,
};
