import config from "../config/config";
import { getString, setString, getBoolean } from "./storage";
import { fetchOffline } from "./fetch-offline";

let token = getString("token");
let offline = getBoolean("offline");

export const setToken = _token => {
    token = _token;
    setString("token", token);
};

export const setOffline = _offline => {
    offline = _offline;
};

export const fetchOnline = (path, options) => {
    const url = config.server.DOMAIN + path;

    return fetch(url, options);
};

export const _fetch = (path, method = "GET", body) => {
    let options = {
        method,
    };

    options.headers = {
        "Content-Type": "application/json",
        Authorization: token,
        site: config.site,
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    // console.log("url, options -> ", url, options);

    const func = offline ? fetchOffline : fetchOnline;

    return func(path, options).then(res => {
        return res.json();
    });
};

export const sendBeacon = (path, body = {}) => {
    // let url = `${config.server.DOMAIN}${path}/${token}`;
    // navigator.sendBeacon(url);
};

export default {
    setToken,
    setOffline,
    _fetch,
    sendBeacon,
};
