export default ` <h2> The "Art of Debating"</h2>
<p>
The ability to take part in a discussion is important today more than ever.
With the right tools a person can better express his outlook, reach more people
and better shape the reality around him/her.
</p>

<p>
If classes in the "Art of debating" interests you, leave your name and contact information and come for an 
    <a
        href="https://www.youtube.com/watch?v=ohDB5gbtaEQ"
        target="_blank"
        rel="noopener noreferrer">
        argument
    </a>.
    
</p>`;
