// @flow
import React, { Component } from "react";
import "./BlogHeader.scss";
import contextTypes from "../../contextTypes";

export class BlogHeader extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element || {};
    }

    get data() {
        const { data } = this.element;
        return data || {};
    }

    render() {
        const { title, subtitle } = this.data;

        return (
            <div className="BlogHeader-container">
                <h1>{title}</h1>
                <h2 className="subtitle">{subtitle}</h2>
            </div>
        );
    }
}

BlogHeader.contextTypes = contextTypes;

export default BlogHeader;
