import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";
import dayjs from "dayjs";

export const pendings = createSelector(
    raw.pendingsRaw,
    _pendings => {
        return Object.values(_pendings).reduce((output, pending) => {
            const { id, snoozeUntil, content = "" } = pending || {};

            const isSnoozed = snoozeUntil && dayjs(snoozeUntil).isFuture();

            output[id] = {
                ...pending,
                isSnoozed,
                snoozeUntil: isSnoozed ? snoozeUntil : "",
                length: content.length,
            };

            return output;
        }, {});
    },
);

export const pending = raw.pending;

export const pendingsSingle = createSelector(
    pending,
    appState.pendingId,
    (_pending, _pendingId) => {
        if (!_pending || !_pendingId) {
            return null;
        }
        return {
            [_pendingId]: _pending,
        };
    },
);

export const pendingsFiltered = createSelector(
    pendings,
    _pendings => {
        return Object.values(_pendings).filter(pending => {
            const { bucketId = 0 } = pending;

            return bucketId === 2;
        });
    },
);
