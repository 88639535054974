// @flow
import React, { Component } from "react";
import "./RemoteImage.scss";
import contextTypes from "../contextTypes";
import { getImageSize } from "../../utils/image";
import Loader from "../loader/Loader";
import LazyLoad from "react-lazyload";
import { isMobile } from "../../utils/screen";

const colors = [
    "#8f8983",
    "#3f3e42",
    "#a2a19f",
    "#b5a598",
    "#84827b",
    "#514d30",
    "#a68377",
    "#95877f",
    "#917a6c",
    "#c5b8b0",
];

export class RemoteImage extends Component {
    constructor() {
        super();

        this.image = React.createRef();
        // const index = Math.floor(Math.random() * colors.length);
        this.backgroundColor = "#eee";
    }

    state = {
        width: 300,
        isLoading: true,
        showLoader: false,
    };

    get src() {
        let { src, ratio = 2 } = this.props;
        const { width } = this.state;

        const height = width / ratio;

        if (!src) {
            return `https://imgplaceholder.com/${width}x${height}/eeeeee/aaaaaa/fa-image?font-size=80`;
        }

        return src;
    }

    async componentDidMount() {
        this.recalcWidth();

        this.mounted = true;
        await getImageSize(this.src);

        if (this.mounted) {
            this.setState({ isLoading: false });

            this.timeout3 = setTimeout(() => {
                this.setState({ whiteBackground: true });
            }, 200);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        clearTimeout(this.timeout);
        clearTimeout(this.timeout2);
        clearTimeout(this.timeout3);
    }

    recalcWidth() {
        if (!this.image.current) {
            return null;
        }

        this.timeout = setTimeout(() => {
            const box = this.image.current.getBoundingClientRect();
            this.setState({ width: box.width });
        }, 0);
    }

    get height() {
        const { ratio } = this.props;
        const { width } = this.state;

        if (!ratio) return {};

        const output = (width / ratio) * 0.83 - 3;

        return isNaN(output) ? null : output;
    }

    get style() {
        const { whiteBackground } = this.state;

        return {
            minHeight: this.height,
            backgroundColor:
                whiteBackground || isMobile() ? "#fff" : this.backgroundColor,
        };
    }

    renderLoader() {
        return (
            <div className="loader">
                <Loader />
            </div>
        );
    }

    renderImage() {
        const { alt } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return null;
        }

        return <img src={this.src} alt={alt} />;
    }

    render() {
        return (
            <LazyLoad height={this.height} offset={200}>
                <div
                    className="RemoteImage-container"
                    ref={this.image}
                    style={this.style}>
                    {this.renderImage()}
                </div>
            </LazyLoad>
        );
    }
}

RemoteImage.contextTypes = contextTypes;

export default RemoteImage;
