import { getString, getJson, getBoolean } from "../utils/storage";
import globals from "../utils/globals";

export default {
    appState: {
        isLoading: true,
        currentIds: {
            debId: 0,
            commentId: "i0",
            sentenceId: -1,
            tagId: 0,
        },
        viewIds: {
            adminDebatesId: getString("adminDebatesId") || "waitingForTagging",
            adminPendingId: getString("adminPendingId") || "pending",
            adminBlogPostId: getString("adminBlogPostId") || "all",
        },
        selectedSentences: {},
        tree: {},
        overlay: "lofs",
        user: null,
        toast: null,
        xray: false,
        xrayEdit: false,
        editMode: false,
        isAdmin: false,
        showBack: true,
        role: null,
        offline: getBoolean("offline"),
        barMode: "",
    },
    bons: {
        "7e2b6eb0": {
            lofs: {},
            nvcs: {},
            lofsX: {},
            nvcsX: {},
        },
    },
    blogs: {},
    contacts: {},
    debates: {},
    pendings: {},
    dumps: {},
    examples: {
        lofs: {},
        nvcs: {},
        lofsX: {},
        nvcsX: {},
    },
    sessions: {},
    tags: {
        lofs: {},
        nvcs: {},
        lofsX: {},
        nvcsX: {},
    },
    builder: {
        debate: getJson("builder_debate") || globals.defaultDebate,
    },
    modal: {},
};
