// @flow
import React, { Component } from "react";
import "./VideoTeleprompter.scss";
import contextTypes from "../contextTypes";

export class VideoTeleprompter extends Component {
    state = {
        dx: window.innerWidth,
        duration: 1500,
        width: window.innerWidth,
    };

    constructor() {
        super();

        this.sentenceRef = React.createRef();
    }

    get currentSentence() {
        const { currentSentence } = this.state;

        return currentSentence || {};
    }

    get styleScroll() {
        const { dx, duration, animated } = this.state;

        let transition;

        if (animated) {
            transition = `all ${duration}ms linear`;
        }

        return {
            transform: `translateX(${dx}px)`,
            transition,
        };
    }

    get styleInner() {
        const { width } = this.state;

        return {
            width,
        };
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    get sentenceWidth() {
        let width;
        const el = this.sentenceRef.current;

        try {
            const box = el.getBoundingClientRect();
            width = box.width;
        } catch (e) {}

        return width;
    }

    changeSentence = sentence => {
        const { width } = this.state;

        const { content = "" } = sentence || {};
        const length = content.length;
        const isScrolling = length > 50;

        clearTimeout(this.timeout);

        if (!isScrolling) {
            this.setState(
                {
                    currentSentence: sentence,
                    isScrolling,
                    dx: 0,
                    animated: false,
                },
                () => {
                    this.setState({
                        dx: Math.floor((width - this.sentenceWidth) / 2),
                    });
                },
            );
            return;
        }

        const duration = length * 50;

        this.setState(
            {
                currentSentence: sentence,
                isScrolling,
                dx: width,
                duration,
                animated: false,
            },
            () => {
                this.timeout = setTimeout(() => {
                    this.setState({
                        dx: width - this.sentenceWidth - 15,
                        animated: true,
                    });
                });
            },
        );
    };

    componentWillReceiveProps(props) {
        const { currentSentence } = props;

        if (currentSentence !== this.state.currentSentence) {
            this.changeSentence(currentSentence);
        }
    }

    render() {
        const { content = "" } = this.currentSentence;

        return (
            <div className="VideoTeleprompter-container">
                <div className="inner" style={this.styleInner}>
                    <div className="scrolling" style={this.styleScroll}>
                        <div className="content" ref={this.sentenceRef}>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

VideoTeleprompter.contextTypes = contextTypes;

export default VideoTeleprompter;
