// @flow
import React, { Component } from "react";
import "./ThankYou.scss";
import contextTypes from "../contextTypes";
import Icon from "../icons/Icon";
import { Link } from "react-router-dom";

export class ThankYou extends Component {
    state = {
        animation: "",
    };

    get which() {
        const { match } = this.props,
            { params } = match || {},
            { which = "" } = params || {};

        return which.substr(0, 1).toUpperCase() + which.substr(1);
    }

    render() {
        const { i18n } = this.context;
        const which = this.which;

        const title = i18n[`thankYou${which}Title`];
        const subtitle = i18n[`thankYou${which}Subtitle`];
        const icon = i18n[`thankYou${which}Icon`];

        return (
            <div className="ThankYou-container">
                <div className="content">
                    <div className="icon animated fadeIn">
                        <Icon name={icon} size={140} />
                    </div>
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                    <Link className="button back" to="/">
                        {i18n.thankYouBack}
                    </Link>
                    <div className="take-part">
                        {i18n.thankYouTakePart}
                        <Link to="/contact">{i18n.thankYouContact}</Link>
                    </div>
                </div>
            </div>
        );
    }
}

ThankYou.contextTypes = contextTypes;

export default ThankYou;
