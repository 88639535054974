// @flow
import React, { Component } from "react";
import "./IconWithLabel.scss";
import contextTypes from "../contextTypes";
import Icon from "./Icon";

const noop = () => {};

export class IconWithLabel extends Component {
    static defaultProps = {
        name: "thumbs-up",
        onClick: noop,
    };

    state = {};

    render() {
        const { name, label } = this.props;

        return (
            <div
                className="IconWithLabel-container"
                onClick={this.props.onClick}>
                <Icon name={"far fa-" + name} size={14} />
                <span>{label}</span>
            </div>
        );
    }
}

IconWithLabel.contextTypes = contextTypes;

export default IconWithLabel;
