export const debatesWaitingForTagging = deb => {
    const { isPublished, doneLofs, isDeleted } = deb.progress || {};
    return !isPublished && !doneLofs && !isDeleted;
};

export const debatesWaitingForPublish = deb => {
    const { isPublished, doneLofs, isDeleted } = deb.progress || {};
    return !isPublished && doneLofs && !isDeleted;
};

export const debatesPublished = deb => {
    const { isPublished, isDeleted } = deb.progress || {};
    return isPublished && !isDeleted;
};

export const debatesDeleted = deb => {
    const { isDeleted } = deb.progress;
    return isDeleted;
};

export const blogPostsWaitingForPublish = blogPost => {
    const { isPublished, isDeleted } = blogPost.progress || {};
    return !isPublished && !isDeleted;
};

export const blogPostsPublished = blogPost => {
    const { isPublished, isDeleted } = blogPost.progress || {};
    return isPublished && !isDeleted;
};

export const blogPostsDeleted = blogPost => {
    const { isDeleted } = blogPost.progress;
    return isDeleted;
};

export const all = () => true;

export const pendingsPending = pending => {
    const { isSnoozed, isPromoted, isDeleted } = pending.progress || {};
    return !isSnoozed && !isPromoted && !isDeleted;
};

export const pendingsText = pending => {
    const { media } = pending.info;
    const { isSnoozed, isPromoted, isDeleted } = pending.progress || {};
    return media !== "video" && !isSnoozed && !isPromoted && !isDeleted;
};

export const pendingsSnooze = pending => {
    const { isSnoozed, isDeleted } = pending.progress || {};
    return isSnoozed && !isDeleted;
};

export const pendingsDeleted = pending => {
    const { isDeleted } = pending.progress;
    return isDeleted;
};

export const cc = deb => {
    const { media, size } = deb.info || {};
    const { doneSubtitles, isDeleted } = deb.progress || {};
    return media === "video" && !doneSubtitles && !isDeleted && size === "full";
};

export const textual = deb => {
    const { media, size } = deb.info || {};
    const { doneSubtitles, isDeleted } = deb.progress || {};
    return media !== "video" || doneSubtitles || isDeleted || size !== "full";
};

export const published = deb => {
    const { isPublished } = deb.progress || {};
    return isPublished;
};
