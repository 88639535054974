let routes = {
    "/admin": "manage",
    "/admin/login": "login",
    "/admin/tags": "tagManager",
    "/admin/cc": "ccManager",
    "/admin/cc/:debId": "ccStudio",
    "/admin/debates": "debateManager",
    "/admin/personas": "personas",
    "/admin/pending": "pending",
    "/admin/pending/browse": "caeser",
    "/admin/pending/:pendingId": "caeser",
    "/admin/dump": "dump",
    "/admin/leaderboard": "leaderboard",
    "/admin/examples": "examples",
    "/admin/debates/add": "addDebate",
    "/admin/blog": "blogManager",
    "/admin/blog/:blogPostIndex": "editBlog",
    "/admin/shop": "shopManagment",
    "/admin/shop/add": "addProduct",
    "/admin/shop/:productId": "editProduct",
    "/admin/orders": "orderManagement",
    "/admin/sessions": "sessions",
};

export default {
    get routes() {
        return routes;
    },
    addRoute: (key, value) => {
        routes[key] = value;
    },
};
