// @flow
import React, { Component } from "react";
import "./BlogParagraph.scss";
import contextTypes from "../../contextTypes";

export class BlogParagraph extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    render() {
        const { content = "" } = this.element || {};

        return <p>{content}</p>;
    }
}

BlogParagraph.contextTypes = contextTypes;

export default BlogParagraph;
