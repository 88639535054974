export const dump = (state = {}, action) => {
    switch (action.type) {
        case "ADD_DUMP":
            return action.value;

        case "PATCH_DUMP":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const dumps = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_DUMPS":
            return action.value;

        case "ADD_DUMP":
        case "PATCH_DUMP":
            return {
                ...state,
                [action.dumpId]: dump(state[action.dumpId], action),
            };

        case "DELETE_DUMP":
            newState = { ...state };
            delete newState[action.dumpId];
            return newState;

        default:
            return state;
    }
};
