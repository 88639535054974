// @flow
import React, { Component } from "react";
import "./BlogStripe.scss";
import contextTypes from "../contextTypes";
import { Link } from "react-router-dom";

export class BlogStripe extends Component {
    state = {};

    get blogPost() {
        const { blogPost } = this.props;
        return blogPost || {};
    }

    get to() {
        const { index, redirectDebateIndex } = this.blogPost;

        if (redirectDebateIndex) {
            return `/${redirectDebateIndex}`;
        } else {
            return `/blog/${index}`;
        }
    }

    render() {
        const { title, subtitle, authorName } = this.blogPost;

        return (
            <div className="BlogStripe-container">
                <div className="row">
                    <div className="col">
                        <Link to={this.to} className="title no-link">
                            {subtitle}
                        </Link>
                        <div className="authorName">{authorName}</div>
                    </div>
                    <div className="col">
                        <Link to={this.to} className="no-link">
                            <div className="subtitle">{title}</div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

BlogStripe.contextTypes = contextTypes;

export default BlogStripe;
