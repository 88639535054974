// @flow
import React, { Component } from "react";
import "./PanelVideo.scss";
import contextTypes from "../contextTypes";
import dayjs from "dayjs";
import components from "../../config/client.components";

export class PanelVideo extends Component {
    state = {
        lastIndex: 0,
        bons: [],
        comment: {},
        text: "",
        timeComment: "",
    };

    constructor() {
        super();
        this.sound = new Audio("/sounds/beep.wav");
        this.sound.volume = 0.05;
    }

    get debate() {
        const { debate } = this.props;

        return debate || {};
    }

    get comments() {
        const { comments } = this.debate;
        return Object.values(comments);
    }

    get videoStack() {
        const { videoStack } = this.debate;
        return videoStack;
    }

    get info() {
        const { info } = this.debate;
        return info || {};
    }

    get flavour() {
        const { flavour } = this.info;
        return flavour;
    }

    changeStack = (bons, currentIndex) => {
        let text = "";

        this.sound.play();

        const comment = this.comments[currentIndex],
            { timeComment } = comment || {};

        for (let i = 0; i < 3; i++) {
            const c = this.comments[currentIndex + i],
                { content = "" } = c || {};

            text += content;
        }

        this.setState({
            bons,
            comment,
            text,
            timeComment,
            lastIndex: currentIndex,
        });
    };

    resetStack = () => {
        this.setState({
            lastIndex: 0,
            bons: [],
            comment: {},
            text: "",
            timeComment: "",
        });
    };

    componentWillReceiveProps(props) {
        const { currentIndex } = props;

        if (currentIndex < this.state.currentIndex) {
            this.resetStack();
        }

        if (currentIndex !== this.state.currentIndex) {
            const bons = this.videoStack.filter(bon => {
                const { selectedSentences } = bon,
                    firstSentenceIndex = selectedSentences[0];

                return firstSentenceIndex === currentIndex;
            });

            if (bons.length > 0) {
                this.changeStack(bons, currentIndex);
            }

            this.setState({ currentIndex });
        }
    }

    renderComment() {
        const { text, timeComment } = this.state;

        if (!text) return null;

        const time = dayjs.duration(timeComment);

        return (
            <div className="preview animated fadeIn">
                <div className="time">{time}</div>
                <div className="comment" style={this.style}>
                    &quot;{text}
                </div>
            </div>
        );
    }

    renderStack() {
        const { bons } = this.state;

        const Stacks = components.stacksClean;

        if (!bons || Object.values(bons).length === 0) return null;

        return (
            <div className="stack animated fadeIn">
                <Stacks stack={bons} flavour={this.flavour} />
            </div>
        );
    }

    render() {
        return (
            <div className="PanelVideo-container">
                <div className="inner">
                    {this.renderComment()}
                    {this.renderStack()}
                </div>
            </div>
        );
    }
}

PanelVideo.contextTypes = contextTypes;

export default PanelVideo;
