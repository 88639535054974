import { saveAs } from "file-saver";

const mergeNode = (current, fresh) => {
    return {
        ...current,
        ...fresh,
    };
};

export const merge = (current, fresh) => {
    let output = {
        bons: {},
        tags: {},
        personas: {},
        mappings: {},
        debates: {
            live: {},
            pending: {},
            dumps: {},
        },
    };

    const { bons, tags, debates, personas, mappings } = current || {},
        { live, pending, dumps } = debates || {};

    output.bons = mergeNode(bons, fresh.bons);
    output.tags = mergeNode(tags, fresh.tags);
    output.personas = mergeNode(personas, fresh.personas);
    output.mappings = mergeNode(mappings, fresh.mappings);
    output.debates.dumps = mergeNode(dumps, fresh.debates.dumps);
    output.debates.pending = mergeNode(pending, fresh.debates.pending);
    output.debates.live = mergeNode(live, fresh.debates.live);

    return output;
};

export const download = json => {
    var blob = new Blob([JSON.stringify(json, null, 4)], {
        type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "data.json");
};
