// @flow
import React, { Component } from "react";
import "./WebBar.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";
import WebBarMenu from "./WebBarMenu";
import { Link } from "react-router-dom";

export class WebBar extends Component {
    state = {};

    render() {
        const { style, mobile } = this.props;

        const className = classnames("WebBar-container", {
            mobile,
        });

        return (
            <div className={className} style={style}>
                <Link to="/">
                    <div className="logo">DebDB</div>
                </Link>
                <div className="flex1" />

                <div className="bar">
                    <WebBarMenu />
                </div>
            </div>
        );
    }
}

WebBar.contextTypes = contextTypes;

export default WebBar;
