export const example = (state = {}, action) => {
    switch (action.type) {
        case "ADD_EXAMPLE":
            return action.value;

        case "PATCH_EXAMPLE":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const layer = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_EXAMPLES":
            return action.value;

        case "ADD_EXAMPLE":
        case "PATCH_EXAMPLE":
            return {
                ...state,
                [action.exampleId]: example(state[action.exampleId], action),
            };

        case "DELETE_EXAMPLE":
            newState = { ...state };
            delete newState[action.exampleId];
            return newState;

        default:
            return state;
    }
};

export const examples = (state = {}, action) => {
    switch (action.type) {
        case "SET_EXAMPLES":
        case "ADD_EXAMPLE":
        case "PATCH_EXAMPLE":
        case "DELETE_EXAMPLE":
            return {
                ...state,
                [action.layerId]: layer(state[action.layerId], action),
            };

        default:
            return state;
    }
};
