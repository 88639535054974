export default {
    hashTags: "Tags",
    logicalFallacies: "Lofs",
    by: "By",
    byDebDB: "DebDB",
    NVC: "Nvcs",
    outerLink: "Link",
    explanationLink: "Definition",
    tagNew: "New tag",
    lofNew: "New LOF",
    nvcNew: "New NVC",
    lofXray:
        "This tag already exists, please try to refrain from adding duplicate tags.",
    nvcXray: "Rephrase in nvc",
    loginFacebook: "Login with Facebook",
    loginEmail: "Login with Email",
    logout: "Logout",
    startingDate: "Start date",
    status: "Status",
    back: "Back",
    repaint: "Repaint",
    suggestionQuestion: "Saw an interesting debate online?",
    suggestionCTA: "Submit a debate",
    duration: "Duration",
    languages: "Languages",
    placeholderSubtitle: "Subtitle",
    placeholderDate: "Date",
    blogFooter: "DebDB.com blog",
    webLink: "Website",
    menuBlog: "Blog",
    breadcrumbsBlog: "Blog",
    breadcrumbsBlogEditor: "Edit article",
    debateIndexPlease: "Debate index",
    elementWikiLink: "Wiki link",
    elementDebateLink: "Debate link",
    tagIndexPlease: "Tag index",
    elementQuote: "Quote",
    adminBlogsAdd: "Add a blog post",
    adminblogsSave: "Save post",
    optionViewBlogPostsPrePublish: "Waiting for publish",
    optionViewBlogPostsPublished: "Live",
    optionViewBlogPostsPreRemove: "Waiting for deletion",
    optionViewBlogPostsAll: "All",
    breadcrumbsBlogPosts: "Blog",
    facebookLink: "Facebook page",
    leaderboardLofs: "LOFS Leaderboard",
    tableCompleted: "Done",
    medium: "Medium",
    elementP: "Paragraph",
    elementH: "Title",
    elementImage: "Image",
    elementLofDefinition: "Lof definition",
    elementPost: "Post",
    elementBeforeAfter: "Before & after",
    elementExamples: "Example",
    elementVideo: "Video",
    minutesToRead: "Minutes to read",
    nextGoal: "Next goal",
    blogSaving: "Saving...",
    numberOfParticipants: "Participants",
    hoursTotal: "Hours",
    soon: "Soon",
    running: "Running",
    future: "Future",
    hours: "Hours",
    next: "Next",
    previous: "Previous",
    infoTable: "Info table",
    list: "List",
    preview: "Preview",
    debateDetails: "Debate details",
    participants: "Participants",
    commentDetails: "Reply details",
    arguments: "Arguments",
    fieldId: "Identifier",
    fieldImageUrl: "Image",
    fieldParticipantId: "Participant Id",
    fieldTitle: "Title",
    fieldCommentId: "Comment Id",
    fieldTagId: "Tag",
    fieldExplanationId: "Explanation",
    fieldContentId: "Content",
    fieldLikes: "Number of Likes",
    fieldOrder: "Order",
    magicSure: "Are you sure?",
    magicNew: "Add new",
    magicSave: "Save",
    magicTitle: "Title",
    emptyList: "Empty list",
    noItems: "Nothing to show",
    viewMode: "Preview",
    editMode: "Edit",
    edit: "Edit",
    delete: "Delete",
    manage: "Dashboard",
    manageTags: "Tag management",
    glossary: "Wiki",
    manageDebates: "Manage debate",
    menuDebates: "Debates",
    menuPendings: "Pending",
    menuCCStudio: "CC Studio",
    menuPersonas: "Personas",
    menuTags: "Tags",
    menuCards: "Cards",
    menuPeople: "Users",
    menuExamples: "Examples",
    menuDumps: "Data dumps",
    tableLink: "Link",
    tableTitle: "Title",
    tableType: "Type",
    tableSource: "Source",
    tableTextLength: "Text\nlength",
    tableMainArguments: "Major\npoints",
    tableTagLofs: "Lofs\ntag",
    tablePublished: "Published",
    tableReviewTime: "Review\ntime",
    tableMinutes: "Minutes",
    tableInbounds: "Inbounds",
    tableVisits: "Visits",
    tableAverageTime: "Avg.\nvisit",
    tableSeconds: "Seconds",
    tableTimePerCharacter: "Per\nchar",
    tableMillis: "Mil'",
    tableDebateDate: "Debate\ndate",
    tableImportDate: "Import\ndate",
    tablePublishDate: "Publish\ndate",
    tableImageUrl: "Image",
    tableFacebookUrl: "Facebook",
    tableTwitterUrl: "Twitter",
    tableInstagramUrl: "Instagram",
    tableTagTitle: "Tag",
    tableWikiUrl: "Wiki",
    tableCardImageUrl: "Image",
    tableAuthorImageUrl: "Image",
    tableCardTopic: "Topic",
    tableIndex: "Id",
    tableEnglish: "English",
    tableDutch: "Dutch",
    tableHebrew: "Hebrew",
    tableDescription: "Description",
    tableLofs: "Lofs",
    tableExamplesCount: "Examples",
    tableDescriptionLength: "Length",
    tableDescriptionLengthUnits: "Characters",
    tableWikiReady: "Explained",
    tableCardReady: "Card\nread",
    tableDoneSubtitles: "CC\nready",
    tableName: "Name",
    tableDate: "Date",
    tableComments: "Comments",
    tableLikes: "Likes",
    tableShares: "Shares",
    tableLength: "Length",
    tableFields: "Fields",
    tableVideo: "Video",
    tableSnooze: "Snooze",
    tableSize: "Size",
    tableMedia: "Card",
    tableIsDeleted: "Mark\ndeletion",
    menuAbout: "About the project",
    menuVolunteers: "Volunteers",
    menuCourse: "Course",
    menuWiki: "Glossary",
    menuContact: "Contact",
    menuHome: "Home",
    version: "Version",
    statsDebatesTotal: "Total",
    statsDebatesParsed: "Analyzed",
    statsDebatesPublished: "Published",
    statsDebatesNew: "New",
    statsPendingsTotal: "Total",
    statsPendingsVideo: "Video",
    statsPendingsImage: "Image",
    statsPendingsText: "Text",
    statsCcTotal: "Total",
    statsCcYoutube: "Youtube",
    statsCcFacebook: "Facebook",
    statsPersonasTotal: "Total",
    statsTagsTotal: "Total",
    statsTagsLofs: "Lofs",
    statsTagsNvcs: "Nvc",
    statsExamplesTotal: "Total",
    statsPendingsSnoozed: "Snooze",
    statsExamplesLofs: "Lofs",
    statsExamplesNvcs: "Nv",
    stepDebateKind: "Choose type",
    stepDebateContent: "Content",
    stepDebateCC: "Publish",
    findThumbSearchExists: "Image exists",
    findThumbSearchOk: "Image found",
    findThumbSearchError: "Not found",
    findCCfetchOk: "Subtitles",
    findCCfetchError: "Not found",
    bonIsRequired: "Tag params is required",
    bonCreateError: "Error adding ",
    bonCreateExists: "Tag already exist for this comment",
    bonCreateOk: "Tag successfully added",
    bonReadError: "Could not read tag",
    bonUpdateRequired: "Missing value for this param: tag",
    bonUpdateError: "Could not update tag",
    bonDeleteError: "Could not delete tag",
    bonsReadError: "Could not read tags",
    bonUpdateNoPermissions: "No permissions to update tag",
    bonCreateNoPermissions: "No permissions to create tag",
    bonDeleteNoPermissions: "No permissions to delete tag",
    contactIsRequired: "Required param: contact",
    contactCreateError: "Could not add a new contact",
    contactCreateExists: "Contact already exists with those values",
    contactCreateOk: "Contact added successfully",
    contactReadError: "Could not read Contact",
    contactUpdateRequired: "Required param: contact",
    contactUpdateError: "Could not update Contact",
    contactDeleteError: "Could not delete Contact",
    contactsReadError: "Could not read contacts",
    contactUpdateNoPermissions: "No permissions to update Contact",
    contactCreateNoPermissions: "No permissions to create Contact",
    contactDeleteNoPermissions: "No permissions to delete Contact",
    debateIsRequired: "Missing value for this param: debate",
    debateCreateError: "Could not add a new debate",
    debateCreateExists: "Debate already exists with those values",
    debateCreateOk: "Debate added successfully!",
    debateReadError: "Could not read debate",
    debateUpdateRequired: "Missing value for this param: debate",
    debateUpdateError: "Could not update debate",
    debateDeleteError: "Could not delete debate",
    debatesReadError: "Could not read debates",
    debateUpdateNoPermissions: "No permissions to update debate",
    debateCreateNoPermissions: "No permissions to create debate",
    debateDeleteNoPermissions: "No permissions to delete debate",
    debateStatsIsRequired: "Missing value for this param: stats",
    debateStatsReadError: "Could not read stats",
    debateStatsUpdateRequired: "Missing value for this param: stats",
    debateStatsUpdateError: "Could not update stats",
    debateStatsDeleteError: "Could not delete stats",
    debateStatsUpdateNoPermissions: "No permissions to update stats",
    debateStatsDeleteNoPermissions: "No permissions to delete stats",
    debateInfoIsRequired: "Missing value for this param: info",
    debateInfoReadError: "Could not read info",
    debateInfoUpdateRequired: "Missing value for this param: info",
    debateInfoUpdateError: "Could not update info",
    debateInfoDeleteError: "Could not delete info",
    debateInfoUpdateNoPermissions: "No permissions to update info",
    debateInfoDeleteNoPermissions: "No permissions to delete info",
    debateProgressIsRequired: "Missing value for this param: progress",
    debateProgressReadError: "Could not read progress",
    debateProgressUpdateRequired: "Missing value for this param: progress",
    debateProgressUpdateError: "Could not update progress",
    debateProgressDeleteError: "Could not delete progress",
    debateProgressUpdateNoPermissions: "No permissions to update progress",
    debateProgressDeleteNoPermissions: "No permissions to delete progress",
    debateRootIsRequired: "Missing value for this param: root",
    debateRootReadError: "Could not read root",
    debateRootUpdateRequired: "Missing value for this param: root",
    debateRootUpdateError: "Could not update root",
    debateRootDeleteError: "Could not delete root",
    debateRootsReadError: "Could not read rootים",
    debateRootUpdateNoPermissions: "No permissions to update root",
    debateRootDeleteNoPermissions: "No permissions to delete root",
    exampleIsRequired: "Missing value for this param: example",
    exampleCreateError: "Could not add a new example",
    exampleCreateExists: "Example already exist for this comment",
    exampleCreateOk: "Example successfully added!",
    exampleReadError: "Could not read example",
    exampleUpdateRequired: "Missing value for this param: example",
    exampleUpdateError: "Could not update example",
    exampleDeleteError: "Could not delete example",
    examplesReadError: "Could not read examples",
    exampleUpdateNoPermissions: "No permissions to update example",
    exampleCreateNoPermissions: "No permissions to create example",
    exampleDeleteNoPermissions: "No permissions to delete example",
    participantIsRequired: "Missing value for this param: participant",
    participantCreateError: "Could not add a new participant",
    participantCreateExists: "Participant already exists with those values",
    participantCreateOk: "Participant added successfully!",
    participantReadError: "Could not read participant",
    participantUpdateRequired: "Missing value for this param: participant",
    participantUpdateError: "Could not update participant",
    participantDeleteError: "Could not delete participant",
    participantsReadError: "Could not read participants",
    participantUpdateNoPermissions: "No permissions to update participant",
    participantCreateNoPermissions: "No permissions to create participant",
    participantDeleteNoPermissions: "No permissions to delete participant",
    sessionIsRequired: "Missing value for this param: session",
    sessionCreateError: "Could not add a new session",
    sessionCreateViewError: "Could not add a new session view",
    sessionCreateExists: "Session already exists with those values",
    sessionCreateOk: "Session added successfully!",
    sessionReadError: "Could not read session",
    sessionUpdateRequired: "Missing value for this param: session",
    sessionUpdateError: "Could not update session",
    sessionDeleteError: "Could not delete session",
    sessionsReadError: "Could not read sessions",
    sessionUpdateNoPermissions: "No permissions to update session",
    sessionCreateNoPermissions: "No permissions to create session",
    sessionDeleteNoPermissions: "No permissions to delete session",
    tagIsRequired: "Missing value for this param: tag",
    tagCreateError: "Could not add a new tag",
    tagCreateExists: "Tag already exist for this comment",
    tagCreateOk: "Tag successfully added!",
    tagReadError: "Could not read tag",
    tagUpdateRequired: "Missing value for this param: tag",
    tagUpdateError: "Could not update tag",
    tagDeleteError: "Could not delete tag",
    tagsReadError: "Could not read tags",
    tagtUpdateNoPermissions: "No permissions to update tag",
    tagtCreateNoPermissions: "No permissions to create tag",
    tagtDeleteNoPermissions: "No permissions to delete tag",
    voteIsRequired: "Missing value for this param: vote",
    voteCreateError: "Could not add a new vote",
    voteCreateExists: "Vote already exist for this comment",
    voteCreateOk: "Vote successfully added!",
    voteReadError: "Could not read vote",
    voteUpdateRequired: "Missing value for this param: vote",
    voteUpdateError: "Could not update vote",
    voteDeleteError: "Could not delete vote",
    votesReadError: "Could not read votes",
    voteUpdateNoPermissions: "No permissions to update vote",
    voteCreateNoPermissions: "No permissions to create vote",
    voteDeleteNoPermissions: "No permissions to delete vote",
    commentIsRequired: "Missing value for this param: comment",
    commentCreateError: "Could not add a new comment",
    commentCreateExists: "Comment already exist for this comment",
    commentCreateOk: "Comment successfully added!",
    commentReadError: "Could not read comment",
    commentUpdateRequired: "Missing value for this param: comment",
    commentUpdateError: "Could not update comment",
    commentDeleteError: "Could not delete comment",
    commentsReadError: "Could not read comments",
    commentUpdateNoPermissions: "No permissions to update comment",
    commentCreateNoPermissions: "No permissions to create comment",
    commentDeleteNoPermissions: "No permissions to delete comment",
    personasIsRequired: "Missing value for this param: persona",
    personasCreateError: "Could not add a new persona",
    personasCreateExists: "Persona already exists with those values",
    personasCreateOk: "Persona added successfully!",
    personasReadError: "Could not read persona",
    personasUpdateRequired: "Missing value for this param: persona",
    personasUpdateError: "Could not update persona",
    personasDeleteError: "Could not delete persona",
    personassReadError: "Could not read personas",
    personasUpdateNoPermissions: "No permissions to update persona",
    personasCreateNoPermissions: "No permissions to create persona",
    personasDeleteNoPermissions: "No permissions to delete persona",
    pendingIsRequired: "Missing value for this param: pending",
    pendingCreateError: "Could not add a new pending",
    pendingCreateExists: "Pending already exist for this comment",
    pendingCreateOk: "Pending successfully added!",
    pendingReadError: "Could not read pending",
    pendingUpdateRequired: "Missing value for this param: pending",
    pendingUpdateError: "Could not update pending",
    pendingDeleteError: "Could not delete pending",
    pendingsReadError: "Could not read pendings",
    pendingReadNoPermissions: "No permissions to read pending",
    pendingUpdateNoPermissions: "No permissions to update pending",
    pendingCreateNoPermissions: "No permissions to create pending",
    pendingDeleteNoPermissions: "No permissions to delete pending",
    pendingPromoteNoPermissions: "No permissions to promote pending",
    pendingPromoteNotFound: "Pending for promotion not found",
    pendingPromoteFailed: "Could not promote pending",
    dumpIsRequired: "Missing value for this param: dump",
    dumpCreateError: "Could not add a new data dump",
    dumpCreateOk: "Data dump successfully added!",
    dumpReadError: "Could not read data dump",
    dumpUpdateRequired: "Missing value for this param: data dump",
    dumpUpdateError: "Could not update data dump",
    dumpDeleteError: "Could not delete data dump",
    dumpsReadError: "Could not read data dumps",
    dumpReadNoPermissions: "No permissions to read data dump",
    dumpUpdateNoPermissions: "No permissions to update data dump",
    dumpCreateNoPermissions: "No permissions to create data dump",
    dumpDeleteNoPermissions: "No permissions to delete data dump",
    dumpPromoteNoPermissions: "No permissions to promote dump",
    dumpPromoteNotFound: "Data dump to promote was not found",
    dumpPromoteFailed: "Could not promote data dump",
    adminStatsFailed: "Could not calculate stats",
    adminStatsNoPermissions: "No permissions for stats",
    fileUploadError: "No permissions to create file",
    ccRequiredError: "Requires param: cc",
    ccDownloadError: "Could not download CC from Youtube",
    ccParseError: "Could not parse downloaded CC file",
    ccNoPermissions: "No permissions to parse CC",
    optionSizeSingle: "Single post",
    optionSizeFull: "Full debate",
    optionMediaText: "Post",
    optionMediaImage: "Image",
    optionMediaVideo: "Video clip",
    optionLayerLofs: "Lofs",
    optionLayerNvcs: "NVC",
    optionLayerSubtext: "Subtext",
    optionLayerFactCheck: "Fact check",
    optionFlavourTagging: "Taging",
    optionFlavourRephrasing: "Rephrasing",
    optionSourcesFacebook: "Facebook",
    optionSourcesTwitter: "Twitter",
    optionSourcesYoutube: "Youtube",
    optionViewDebatesPreTag: "Waiting for tagging",
    optionViewDebatesPrePublish: "Waiting for publish",
    optionViewDebatesPublished: "Running",
    optionViewDebatesPreRemove: "Marked for deletion",
    optionViewDebatesAll: "All debates",
    optionViewCCText: "Transcript",
    optionViewCCTiming: "Timing",
    optionViewCCTDone: "End CC process",
    buttonCCTimeSentence: "Mark sentence",
    buttonCCSlow: "Change speed",
    buttonCCForward5: "Jump 5 seconds",
    buttonCCPlay: "Play",
    buttonCCBack5: "Go back 5 seconds",
    breadcrumbsManager: "Manage",
    breadcrumbsDebates: "Debates",
    breadcrumbsAddDebate: "Add debate",
    breadcrumbsAddPersona: "Add persona",
    breadcrumbsAddTag: "Add tag",
    breadcrumbsAddCC: "Add subtitles",
    breadcrumbsAddExample: "Add examples",
    breadcrumbsSubtitles: "Subtitles",
    breadcrumbsAddSubtitles: "Add subtitles",
    breadcrumbsExamples: "Examples",
    breadcrumbsPersonas: "Personas",
    breadcrumbsPending: "Pending",
    breadcrumbsDumps: "Data dumps",
    breadcrumbsTags: "Tags",
    breadcrumbsWikis: "Glossary",
    breadcrumbsOneByOne: "One by one",
    placeholderSizes: "Thread size",
    placeholderLayers: "Layer type",
    placeholderFlavours: "Action",
    placeholderSources: "Source",
    featuredVideos: "Featured videos",
    placeholderShowInVideos: "Show in featured videos",
    placeholderOrder: "Order",
    placeholderCardImage: "Card image",
    placeholderCardImageRatio: "Image ratio",
    placeholderImage: "Main image",
    placeholderImageRatio: "Image ratio",
    placeholderAuthorName: "Author name",
    placeholderReadingTime: "Reading time",
    placeholderAuthorImage: "Author image",
    placeholderRedirect: "Redirect to debate index",
    placeholderAuthorImageRatio: "Image ratio",
    placeholderCardTopic: "Card topic",
    placeholderPersona: "Persona",
    labelVideoUrl: "Youtube or Facebook video",
    videoInstructions: "After the video starts playing, exit fullscreen mode",
    examplesTags: "Examples for tag",
    examplesTag: "Example for ",
    exampleAdd: "Add an example",
    exampleAddOk: "Example successfully added",
    exampleSave: "Save example",
    exampleAdded: "Example successfully added",
    debateAdded: "Debate successfully added",
    debateSavedOk: "Debate successfully saved",
    debateSavedError: "Could not save debate",
    exampleSaveOk: "Example successfully added",
    exampleDeletedOk: "Example deleted",
    personaAddOk: "Persona successfully added",
    personaSaveOk: "Persona successfully saved",
    personaDeletedOk: "Persona deleted",
    tagSaveOk: "Tag successfully added",
    tagDeletedOk: "Tag deleted",
    dumpTitle: "Initial filtering",
    items: "Items",
    clear: "Clear",
    placeholderPersonaName: "Persona's name",
    placeholderPersonaImage: "Image",
    placeholderPersonaFacebook: "Facebook",
    placeholderPersonaTwitter: "Twitter",
    placeholderPersonaInstagram: "Instagram",
    placeholderPersonaWiki: "Wikipedia",
    personaAdd: "Add persona",
    savePersona: "Save persona",
    placeholderTag: "Tag",
    placeholderDescription: "Description",
    placeholderExplanation: "Explanations",
    placeholderTitle: "Name",
    placeholderTitleEasy: "Easy name",
    placeholderTitleOrg: "Source name",
    placeholderTitleEn: "English name",
    placeholderSignExists: "Sign posted",
    areYouSure: "Are you sure?",
    contactAdded: "Form successfully saved!",
    tagAdd: "Add a tag",
    tagAddOk: "Tag successfully added",
    tagSave: "Save tag",
    imageCalcRatio: "Calculate ratio",
    tableEdit: "Edit",
    bonSave: "Save tag",
    bonSavedOk: "Tag successfully saved",
    bonDeletedOk: "Tag sent to recycle bin",
    formName: "First name",
    formEmailOrPhone: "Phone number or Email",
    formNotes: "Notes (optional)",
    formSend: "Save",
    caeserEmpty: "No debates currently pending",
    loginMissingFields: "Please enter your email and password",
    loginError: "Could not login",
    loginTitle: "Internet Debate Database",
    loginSubtitle: "Login",
    placeholderEmail: "Email",
    placeholderPassword: "Password",
    loginButtonText: "Login",
    wikiExample: "Example",
    wikiExamples: "Examples",
    wikiChildren: "Subtypes",
    webbarAbout: "About",
    webbarVolunteers: "Volunteers",
    webbarWiki: "Glossary",
    webbarContact: "Contact us",
    contact: "Contact",
    contactTitle: "Contact",
    ccStudioTitle: "Subtitles studio",
    view: "View",
    replies: "Comments",
    ccTranscript: "Transcript",
    emptyTable: "Empty list",
    adminHomeTitle: "Dashboard",
    adminDebatesTitle: "Debates",
    adminDebatesAdd: "Add a debate",
    adminDebatesSave: "Save debate",
    save: "Save",
    adminPersonasTitle: "Personas",
    adminPersonasAdd: "Add a persona",
    adminTagsTitle: "Tags",
    adminTagsAdd: "Add a tag",
    adminPendingTitle: "Pending",
    card: "Card",
    definition: "Definition",
    panel: "Panel",
    linkToPage: "Link",
    caeserAdd: "Add to debates",
    caeserSnooze: "Snooze",
    caeserMarkForDeletion: "Mark for deletion",
    caeserLeftDebates: "remaining debates",
    add: "Add",
    wikiTags: "Glossary",
    editBonModal: "Edit tag",
    editExampleModal: "Edit example",
    editTagModal: "Edit tag",
    editPersonaModal: "Edit persona",
    addTagModal: "Add tag",
    addPersonaModal: "Add persona",
    addExampleModal: "Add examples",
    missingParameters: "Missing parameters",
    thankYouDebateIcon: "done",
    thankYouDebateTitle: "Thank you for the link",
    thankYouDebateSubtitle: "Our team will check it out ASAP!",
    thankYouTakePart: "Do you want to tag debates too?",
    thankYouContact: "Contact us here",
    thankYouBack: "Back to browsing",
    errorSending: "How awkward, a server glitch. Please try again later.",
    suggestionTitle: "Saw an interesting debate online?",
    suggestionSend: "Send",
    suggestionSubtitle:
        "Share it with us and a team of experts will analyze it",
    suggestionTakePart: "Do you want to tag debates too?",
    suggestionContact: "Contact us here",
};
