const storage = {
    getItem: key => localStorage.getItem(key),
    setItem: (key, value) => localStorage.setItem(key, value),
    removeItem: key => localStorage.removeItem(key),
};

export const setString = (key, value) => {
    storage.setItem(key, value);
};

export const getString = key => {
    return storage.getItem(key);
};

export const setBoolean = (key, value) => {
    setString(key, value ? "YES" : "NO");
};

export const getBoolean = key => {
    return getString(key) === "YES";
};

export const setNumber = (key, value) => {
    setString(key, value);
};

export const getNumber = key => {
    return parseInt(getString(key), 10) || 0;
};

export const saveJson = (key, value) => {
    setString(key, JSON.stringify(value));
};

export const getJson = key => {
    try {
        return JSON.parse(getString(key));
    } catch (e) {
        return {};
    }
};

export const patchJson = (key, change) => {
    try {
        let json = getJson(key);
        json = { ...json, ...change };
        saveJson(key, json);
    } catch (e) {
        return {};
    }
};

export const clearJson = key => {
    removeItem(key);
};

export const saveCC = (id, value) => {
    patchJson("cc_" + id, value);
};

export const getCC = id => {
    return getJson("cc_" + id);
};

export const clearCC = id => {
    return clearJson("cc_" + id);
};

export const removeItem = key => {
    storage.removeItem(key);
};
