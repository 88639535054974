// @flow
import React, { Component } from "react";
import "./Loader.scss";
import contextTypes from "../contextTypes";
import LoaderImage from "./loader.svg";

export class Loader extends Component {
    state = {};

    render() {
        const { size } = this.props;

        return (
            <div className="Loader-container">
                <img src={LoaderImage} alt="Loader" width={size} />
            </div>
        );
    }
}

Loader.contextTypes = contextTypes;

export default Loader;
