import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";
import dayjs from "dayjs";

export const dumps = createSelector(
    raw.dumpsRaw,
    _dumps => {
        return Object.values(_dumps).reduce((output, dump) => {
            const { id, snoozeUntil, content = "" } = dump || {};

            const isSnoozed = snoozeUntil && dayjs(snoozeUntil).isFuture();

            output[id] = {
                ...dump,
                isSnoozed,
                snoozeUntil: isSnoozed ? snoozeUntil : "",
                length: content.length,
            };

            return output;
        }, {});
    },
);

export const dump = raw.dump;

export const dumpsSingle = createSelector(
    dump,
    appState.dumpId,
    (_dump, _dumpId) => {
        if (!_dump || !_dumpId) {
            return null;
        }
        return {
            [_dumpId]: _dump,
        };
    },
);

export const dumpsFiltered = createSelector(
    dumps,
    _dumps => {
        return Object.values(_dumps).filter(dump => {
            const { bucketId = 0 } = dump;

            return bucketId === 1;
        });
    },
);
