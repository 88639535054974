// @flow
import React, { Component } from "react";
import "./TextualPost.scss";
import contextTypes from "../contextTypes";
import Comment from "../comment/Comment";
import CommentRoot from "../comment/CommentRoot";
import Icon from "../icons/Icon";

export class TextualPost extends Component {
    state = {};

    get debate() {
        const { debate } = this.props;
        return debate || {};
    }

    get info() {
        const { info } = this.debate;
        return info || {};
    }

    get comments() {
        const { comments } = this.debate;
        return comments || [];
    }

    get otherComments() {
        return this.comments.slice(1);
    }

    get firstComment() {
        return this.comments[0];
    }

    get rootComment() {
        const { root } = this.debate;
        return root;
    }

    setFirstComment = () => {
        setTimeout(() => {
            if (this.rootComment) {
                const { id } = this.rootComment;
                this.props.changeCommentId(id);
            }
        });
    };

    componentDidMount() {
        this.setFirstComment();
    }

    componentWillReceiveProps(props, prevProps) {
        if (props.debId !== prevProps.debId) {
            this.setFirstComment();
        }
    }

    renderComment = comment => {
        const { commentId, editMode } = this.props;

        return (
            <Comment
                {...this.props}
                key={comment.id}
                editMode={editMode}
                onClick={this.props.onCommentClick}
                selected={comment.id === commentId}
                comment={comment}
            />
        );
    };

    renderRoot() {
        const { commentId } = this.props;
        const { media } = this.info;

        if (!this.rootComment) return null;

        return (
            <CommentRoot
                {...this.props}
                onClick={this.props.onCommentClick}
                selected={commentId === "i0"}
                comment={this.rootComment}
                media={media}
            />
        );
    }

    renderFirst() {
        if (!this.firstComment) return null;

        return (
            <div className="comments">
                {this.renderComment(this.firstComment)}
            </div>
        );
    }

    renderOthers() {
        if (!this.otherComments) return null;

        return (
            <div className="replies sub">
                {this.otherComments.map(comment => this.renderComment(comment))}
            </div>
        );
    }

    renderReference = () => {
        const { postUrl } = this.info;

        return (
            <div className="reference">
                <Icon name="link" href={postUrl} color={"#ccc"} />
            </div>
        );
    };

    render() {
        return (
            <article className={"TextualPost-container"}>
                {this.renderReference()}
                {this.renderRoot()}
                {this.renderFirst()}
                {this.renderOthers()}
            </article>
        );
    }
}

TextualPost.contextTypes = contextTypes;

export default TextualPost;
