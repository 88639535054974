// @flow
import React, { Component } from "react";
import "./BlogH.scss";
import contextTypes from "../../contextTypes";

const H1 = props => <h1 {...props} />;
const H2 = props => <h2 {...props} />;
const H3 = props => <h3 {...props} />;
const H4 = props => <h4 {...props} />;
const H5 = props => <h5 {...props} />;
const H6 = props => <h6 {...props} />;
const H7 = props => <h7 {...props} />;
const H8 = props => <h8 {...props} />;

const all = {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    H7,
    H8,
};

export class BlogH extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    get data() {
        const { data } = this.element;
        return data || {};
    }

    render() {
        const { content = "" } = this.element || {};
        const { size = 2 } = this.data;

        const Cmp = all[`H${size}`];

        return <Cmp>{content}</Cmp>;
    }
}

BlogH.contextTypes = contextTypes;

export default BlogH;
