// @flow
import React, { Component } from "react";
import "./Sentences.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";
import { fromContent } from "../../utils/sentences";

export class Sentences extends Component {
    constructor(props) {
        super();

        this.state = {
            highlights: props.highlights,
            selectedIndex: props.selectedSentences,
            editMode: props.editMode,
        };

        this.container = React.createRef();
    }

    get sentences() {
        const { content } = this.props;
        return fromContent(content, /[.?!]+/gi);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.mousedown);
        document.addEventListener("mouseup", this.mouseup);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.mousedown);
        document.removeEventListener("mouseup", this.mouseup);
    }

    componentWillReceiveProps(props) {
        const { editMode, highlights } = props;

        if (editMode !== this.state.editMode) {
            this.setState({ editMode });

            if (editMode) {
                // clear on start of edit
                this.props.onSentenceClick({});
                this.setState({ selectedIndex: {} });
            }
        }

        if (highlights !== this.state.highlights) {
            this.setState({ highlights });
        }
    }

    mousedown = ev => {
        this.setState({ mouseDown: true });
    };

    mouseup = ev => {
        this.setState({ mouseDown: false });
    };

    shouldClear = index => {
        let { selectedIndex } = this.state;

        let i, on, clear;

        for (i = index + 1; i <= this.sentences.length; i++) {
            const c = selectedIndex[i];

            if (c && on) {
                clear = true;
            }

            if (!c) {
                on = true;
            }
        }

        on = false;

        for (i = index - 1; i >= 0; i--) {
            const c = selectedIndex[i];
            if (c && on) {
                clear = true;
            }

            if (!c) {
                on = true;
            }
        }

        return clear;
    };

    isSplit = selectedIndex => {
        let on1, on2, clear;

        for (let i = 0; i <= this.sentences.length; i++) {
            const c = selectedIndex[i];

            if (c) {
                on1 = true;
            }

            if (!c && on1) {
                on2 = true;
            }

            if (c && on2) {
                clear = true;
            }
        }

        return clear;
    };

    set = selectedIndex => {
        this.setState({ selectedIndex });
        this.props.onSentenceClick(selectedIndex);
    };

    select = index => {
        let { editMode } = this.props;
        let { selectedIndex } = this.state;

        if (!editMode) return;

        selectedIndex[index] = !selectedIndex[index];

        if (
            (selectedIndex[index] && this.shouldClear(index)) ||
            this.isSplit(selectedIndex)
        ) {
            selectedIndex = { [index]: true };
        }

        this.set(selectedIndex);
    };

    over = index => {
        let { editMode } = this.props;
        let { selectedIndex } = this.state;
        const { mouseDown } = this.state;

        if (!editMode) return;

        if (!mouseDown || selectedIndex[index]) return;

        const all = Object.keys(selectedIndex)
                .filter(key => selectedIndex[key])
                .map(i => parseInt(i, 10)),
            min = all.reduce((output, i) => Math.min(i, output), 1000),
            max = all.reduce((output, i) => Math.max(i, output), 0);

        if (all.length === 0) {
            this.set({ [index]: true });
            return;
        }

        for (let i = max; i <= index; i++) {
            selectedIndex[i] = true;
        }

        for (let i = min; i >= index; i--) {
            selectedIndex[i] = true;
        }

        this.set(selectedIndex);
    };

    click = (ev, index, highlight) => {
        const { isMobile } = this.context;
        if (!isMobile || !highlight) return;

        this.props.changeSentence(ev, index);
    };

    renderLines = (sentence, divider, index) => {
        let output = [];

        const baseIndex1 = (index + 1) * 1000;

        sentence.split(/[\r\n]/g).forEach((s, i) => {
            const baseIndex2 = baseIndex1 + i * 50;

            if (i > 0) {
                output.push(<br key={baseIndex2 + 1} />);
            }

            output.push(
                s && s !== " " ? <span key={baseIndex2 + 2}>{s}</span> : null,
            );
        });

        output.push(<span key={baseIndex1 + 3}>{divider}</span>);

        return output;
    };

    renderSentence([sentence, divider], index) {
        const { selectedIndex, highlights = {} } = this.state;

        const highlight = (highlights || {})[index];

        const className = classnames("sentence", highlight, {
            selected: selectedIndex[index],
        });

        return (
            <span
                className={className}
                key={index}
                onMouseOver={() => this.over(index)}
                onMouseDown={() => this.select(index)}
                onClick={ev => this.click(ev, index, highlight)}>
                {this.renderLines(sentence, divider, index)}
            </span>
        );
    }

    render() {
        const { editMode } = this.props;

        const className = classnames(
            "Sentences-container",
            this.props.className,
            {
                editMode,
            },
        );

        return (
            <div className={className} ref={this.container}>
                {this.sentences.map((sentence, index) =>
                    this.renderSentence(sentence, index),
                )}
            </div>
        );
    }
}

Sentences.contextTypes = contextTypes;

export default Sentences;
