let history,
    i18n,
    defaults,
    isAdmin,
    config,
    _fetch,
    api,
    firebase,
    components,
    sagaMiddleware,
    site,
    ga,
    offlineData,
    booklet;

export default {
    get history() {
        return history;
    },
    set history(value) {
        history = value;
    },
    get i18n() {
        return i18n;
    },
    set i18n(value) {
        i18n = value;
    },
    get offlineData() {
        return offlineData;
    },
    set offlineData(value) {
        offlineData = value;
    },
    get booklet() {
        return booklet;
    },
    set booklet(value) {
        booklet = value;
    },
    get ga() {
        return ga;
    },
    set ga(value) {
        ga = value;
    },
    get defaults() {
        return defaults;
    },
    set defaults(value) {
        defaults = value;
    },
    get defaults() {
        return defaults;
    },
    get defaultDebate() {
        return defaults.defaultDebate();
    },
    get defaultComment() {
        return defaults.defaultComment();
    },
    get defaultExample() {
        return defaults.defaultExample;
    },
    get defaultTag() {
        return defaults.defaultTag;
    },
    get defaultBlogPost() {
        return defaults.defaultBlogPost();
    },
    get defaultBlogElements() {
        return defaults.defaultBlogElements;
    },
    get isAdmin() {
        return isAdmin;
    },
    set isAdmin(value) {
        isAdmin = value;
    },
    get config() {
        return config;
    },
    set config(value) {
        config = value;
    },
    get fetch() {
        return _fetch;
    },
    set fetch(value) {
        _fetch = value;
    },
    get api() {
        return api;
    },
    set api(value) {
        api = value;
    },
    get firebase() {
        return firebase;
    },
    set firebase(value) {
        firebase = value;
    },
    get components() {
        return components;
    },
    set components(value) {
        components = value;
    },
    set sagaMiddleware(value) {
        sagaMiddleware = value;
    },
    get sagaMiddleware() {
        return sagaMiddleware;
    },
    set site(value) {
        site = value;
    },
    get site() {
        return site;
    },
};
