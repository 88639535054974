// @flow
import React, { Component } from "react";
import "./BlogAuthor.scss";
import contextTypes from "../../contextTypes";
import dayjs from "dayjs";

export class BlogAuthor extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element || {};
    }

    get data() {
        const { data } = this.element;
        return data || {};
    }

    get styleAvatar() {
        const { imageUrl } = this.data;

        return {
            backgroundImage: `url(${imageUrl})`,
        };
    }

    render() {
        const { i18n } = this.context;
        const { title, date, readTimeInMinutes } = this.data;

        const dateText = dayjs(date).fromNow();

        return (
            <div className="BlogAuthor-container">
                <div className="avatar" style={this.styleAvatar} />
                <div className="details">
                    <div className="title">{title}</div>
                    <div className="subtitle">
                        <span>{dateText}</span>
                        <span className="dot">·</span>
                        <span>
                            {readTimeInMinutes} {i18n.minutesToRead}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

BlogAuthor.contextTypes = contextTypes;

export default BlogAuthor;
