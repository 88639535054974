// @flow
import React, { Component } from "react";
import "./VideoPostLayer.scss";
import contextTypes from "../contextTypes";
import PanelVideo from "../panel-video/PanelVideo";
import Icon from "../icons/Icon";
import VideoTeleprompter from "../video-teleprompter/VideoTeleprompter";
import max from "lodash/max";
import min from "lodash/min";

export class VideoPostLayer extends Component {
    state = {};

    get debate() {
        const { debate } = this.props;

        return debate || {};
    }

    get root() {
        const { root } = this.debate;
        return root || {};
    }

    get comments() {
        const { comments } = this.debate;
        return comments;
    }

    get bonPositions() {
        const { videoStack = [] } = this.debate;
        return videoStack.map(bon => min(bon.selectedSentences));
    }

    get firstPosition() {
        return this.bonPositions[0];
    }

    get lastPosition() {
        const arr = this.bonPositions;
        return arr[arr.length - 1];
    }

    get isYoutube() {
        const { videoUrl } = this.root || {};

        if (!videoUrl) {
            return false;
        }

        return videoUrl.indexOf("youtube.com") >= 0;
    }

    get currentSentence() {
        return this.comments[this.currentIndex];
    }

    get currentIndex() {
        const { currentIndex } = this.props;
        return currentIndex;
    }

    previousBon = () => {
        const arr = this.bonPositions;
        const index = this.currentIndex;

        let previous =
            max(arr.filter(i => i < index)) ||
            max(arr.filter(i => i === index));

        this.props.jumpToSentence(previous);
    };

    nextBon = () => {
        const arr = this.bonPositions;
        const index = this.currentIndex;

        const next = min(arr.filter(i => i > index));

        this.props.jumpToSentence(next);
    };

    renderMessage() {
        const { i18n, isMobile } = this.context;
        const { neverPlayed } = this.state;

        if (!neverPlayed || this.isYoutube || !isMobile) return;

        return <div className="lofs message">{i18n.videoInstructions}</div>;
    }

    renderTelepromter() {
        return <VideoTeleprompter currentSentence={this.currentSentence} />;
    }

    renderPause() {
        const { isPlaying, neverPlayed } = this.props;

        let icon = isPlaying ? "pause" : "play_arrow";

        if (neverPlayed) {
            icon = "";
        }

        return (
            <div className="play-pause-next">
                {this.renderPrevious()}
                <div className="play" onClick={this.props.togglePlaying}>
                    <Icon name={icon} size={40} color="white" />
                </div>
                {this.renderNext()}
            </div>
        );
    }

    renderPrevious() {
        const disabled = this.currentIndex < this.firstPosition;

        return (
            <div className="previous">
                <Icon
                    name="skip_previous"
                    size={40}
                    color="white"
                    disabled={disabled}
                    onClick={this.previousBon}
                />
            </div>
        );
    }

    renderNext() {
        const disabled = this.currentIndex >= this.lastPosition;

        return (
            <div className="next">
                <Icon
                    name="skip_next"
                    size={40}
                    color="white"
                    disabled={disabled}
                    onClick={this.nextBon}
                />
            </div>
        );
    }

    renderPanel() {
        const { neverPlayed } = this.state;

        if (neverPlayed) return null;

        return (
            <div className="lofs">
                <PanelVideo
                    debate={this.debate}
                    currentIndex={this.currentIndex}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="VideoPostLayer-container">
                {this.renderTelepromter()}
                {this.renderMessage()}
                {this.renderPanel()}
                {this.renderPause()}
            </div>
        );
    }
}

VideoPostLayer.contextTypes = contextTypes;

export default VideoPostLayer;
