import * as options from "./options";

export const sizes = {
    type: "select",
    name: "size",
    placeholderKey: "placeholderSizes",
    options: Object.values(options.sizes),
    dataGroup: "info",
};

export const layers = {
    type: "select",
    name: "layer",
    placeholderKey: "placeholderLayers",
    options: Object.values(options.layers),
    dataGroup: "info",
};

export const flavours = {
    type: "select",
    name: "flavour",
    placeholderKey: "placeholderFlavours",
    options: Object.values(options.flavours),
    dataGroup: "info",
};

export const sources = {
    type: "select",
    name: "source",
    placeholderKey: "placeholderSources",
    options: Object.values(options.sources),
    dataGroup: "info",
};

export const adminDebatesViews = {
    type: "select",
    name: "adminDebatesId",
    placeholderKey: "view",
    options: Object.values(options.viewsAdminDebates),
};

export const adminBlogPostsViews = {
    type: "select",
    name: "adminBlogPostId",
    placeholderKey: "view",
    options: Object.values(options.viewsAdminBlogPosts),
};

export const image = {
    type: "image",
    name: "imageUrl",
    placeholderKey: "placeholderImage",
    size: 170,
    dataGroup: "root",
};

export const imageSmall = {
    type: "image",
    name: "imageUrl",
    placeholderKey: "placeholderImage",
    dataGroup: "root",
};

export const imageRatio = {
    type: "float",
    name: "imageRatio",
    placeholderKey: "placeholderImageRatio",
    dataGroup: "root",
};

export const authorImage = {
    type: "image",
    name: "authorImageUrl",
    placeholderKey: "placeholderAuthorImage",
    dataGroup: "root",
};

export const authorImageRatio = {
    type: "float",
    name: "authorImageRatio",
    placeholderKey: "placeholderAuthorImageRatio",
    dataGroup: "root",
};

export const cardImage = {
    type: "image",
    name: "cardImageUrl",
    placeholderKey: "placeholderCardImage",
    dataGroup: "root",
};

export const cardRatio = {
    type: "float",
    name: "cardImageRatio",
    placeholderKey: "placeholderCardImageRatio",
    dataGroup: "root",
};

export const cardTopic = {
    type: "input",
    name: "cardTopic",
    placeholderKey: "placeholderCardTopic",
    dataGroup: "root",
};

export const persona = {
    type: "select",
    name: "personaId",
    placeholderKey: "placeholderPersona",
    optionsKey: "personas",
    dataGroup: "root",
};

export const videoUrl = {
    type: "url",
    name: "videoUrl",
    labelKey: "labelVideoUrl",
    placeholderKey: "https://www.youtube.com/watch?v=a123",
    dataGroup: "root",
};

export const ccText = {
    type: "text",
    name: "content",
    placeholderKey: "ccTranscript",
};

export const personaName = {
    type: "input",
    name: "title",
    placeholderKey: "placeholderPersonaName",
};

export const personaImage = {
    type: "image",
    name: "imageUrl",
    placeholderKey: "placeholderPersonaImage",
};

export const personaFacebook = {
    type: "input",
    name: "facebookUrl",
    placeholderKey: "placeholderPersonaFacebook",
};

export const personaTwitter = {
    type: "input",
    name: "twitterUrl",
    placeholderKey: "placeholderPersonaTwitter",
};

export const personaInstagram = {
    type: "input",
    name: "instagramUrl",
    placeholderKey: "placeholderPersonaInstagram",
};

export const personaWiki = {
    type: "input",
    name: "wikiUrl",
    placeholderKey: "placeholderPersonaWiki",
};

export const description = {
    type: "text",
    name: "description",
    placeholderKey: "placeholderDescription",
};

export const tagId = {
    type: "select",
    name: "tagId",
    placeholderKey: "placeholderTag",
    optionsKey: "tags",
};

export const explanation = {
    type: "text",
    name: "explanation",
    placeholderKey: "placeholderExplanation",
};

export const title = {
    type: "input",
    name: "title",
    placeholderKey: "placeholderTitle",
};

export const titleEasy = {
    type: "input",
    name: "title",
    placeholderKey: "placeholderTitleEasy",
};

export const titleOrg = {
    type: "input",
    name: "titleOrg",
    placeholderKey: "placeholderTitleOrg",
};

export const titleEn = {
    type: "input",
    name: "titleEn",
    placeholderKey: "placeholderTitleEn",
    hideOnLocales: ["en2"],
};

export const signExists = {
    type: "checkbox",
    name: "signExists",
    placeholderKey: "placeholderSignExists",
    dataGroup: "root",
};

export const blogTitle = {
    type: "text",
    name: "title",
    placeholderKey: "placeholderTitle",
    dataGroup: "card",
};

export const blogSubtitle = {
    type: "text",
    name: "subtitle",
    placeholderKey: "placeholderSubtitle",
    dataGroup: "card",
};

export const blogDateArticle = {
    type: "date",
    name: "dateArticle",
    placeholderKey: "placeholderDate",
    dataGroup: "card",
};

export const blogImage = {
    type: "image",
    name: "imageUrl",
    placeholderKey: "placeholderImage",
    size: 170,
    dataGroup: "card",
};

export const blogImageRatio = {
    type: "float",
    name: "imageRatio",
    placeholderKey: "placeholderImageRatio",
    dataGroup: "card",
};

export const blogAuthorName = {
    type: "input",
    name: "authorName",
    placeholderKey: "placeholderAuthorName",
    dataGroup: "card",
};

export const blogReadingTime = {
    type: "input",
    name: "readingTimeInMinutes",
    placeholderKey: "placeholderReadingTime",
    dataGroup: "card",
};

export const blogRedirect = {
    type: "input",
    name: "redirectDebateIndex",
    placeholderKey: "placeholderRedirect",
    dataGroup: "card",
};

export const blogOrder = {
    type: "input",
    name: "order",
    placeholderKey: "placeholderOrder",
    dataGroup: "card",
};

export const inVideos = {
    type: "checkbox",
    name: "showInFeaturedVideos",
    placeholderKey: "placeholderShowInVideos",
    dataGroup: "card",
};
