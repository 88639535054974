// @flow
import React, { Component } from "react";
import "./ScreenVideoPanel.scss";
import contextTypes from "../contextTypes";

export class ScreenVideoPanel extends Component {
    state = {};

    render() {
        return (
            <div className="ScreenVideoPanel-container">
                <div className="lof">lof</div>

                <div className="av-buttons">av</div>
            </div>
        );
    }
}

ScreenVideoPanel.contextTypes = contextTypes;

export default ScreenVideoPanel;
