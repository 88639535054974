// @flow
import React, { Component } from "react";
import "./ToastMessage.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";

export class ToastMessage extends Component {
    get icon() {
        const { type } = this.props;
        switch (type) {
            case "success":
                return "done";
            case "warning":
                return "warning";
            case "error":
                return "error";
            default:
                return "done";
        }
    }

    render() {
        const { animations } = this.context;
        const { type, show, text } = this.props;

        const animation = show
            ? animations.fadeInRight
            : animations.fadeOutRight;

        const className = classnames(
            "ToastMessage-container animated",
            animation,
            type,
        );

        return (
            <div className={className}>
                <div className="bar">
                    <div className="filler" />
                    <div className="icon">
                        <span className="material-icons">{this.icon}</span>
                    </div>
                </div>
                <div className="content">
                    <div className="text">{text}</div>
                    <div className="icon">
                        <span
                            onClick={this.props.close}
                            className="material-icons">
                            close
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

ToastMessage.contextTypes = contextTypes;

export default ToastMessage;
