// @flow
import React, { Component } from "react";
import "./BlogDebateLink.scss";
import contextTypes from "../../contextTypes";
import { Link } from "react-router-dom";

export class BlogDebateLink extends Component {
    state = {};

    get element() {
        const { element } = this.props;
        return element;
    }

    get data() {
        const { data } = this.element || {};
        return data;
    }

    render() {
        const { content = "" } = this.element || {};
        const { debateIndex } = this.data;

        return (
            <div className="BlogDebateLink-container">
                <Link to={`/${debateIndex}`}>{content}</Link>
            </div>
        );
    }
}

BlogDebateLink.contextTypes = contextTypes;

export default BlogDebateLink;
