export const pending = (state = {}, action) => {
    switch (action.type) {
        case "ADD_PENDING":
            return action.value;

        case "PATCH_PENDING":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const pendings = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_PENDINGS":
            return action.value;

        case "ADD_PENDING":
        case "PATCH_PENDING":
            return {
                ...state,
                [action.pendingId]: pending(state[action.pendingId], action),
            };

        case "DELETE_PENDING":
            newState = { ...state };
            delete newState[action.pendingId];
            return newState;

        default:
            return state;
    }
};
