import actions from "../sparkActions";
import { pathFromParams } from "./helpers/path";
import globals from "../../utils/globals";

export default async (action, store) => {
    let data;

    const { value } = action;

    switch (action.type) {
        case "SET_BLOG_ELEMENT":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId/elements", action),
                "POST",
                {
                    element: value,
                },
            ); // prettier-ignore;
            action.value = data.element || {};
            action.elementId = action.value.id;
            break;

        case "SET_BLOG":
            data = await globals.fetch(
                pathFromParams("/blog", action),
                "POST",
                {
                    blogPost: value,
                },
            ); // prettier-ignore;
            action.value = data.blogPost || {};
            action.id = action.value.id;
            break;

        case "GET_BLOG_POSTS":
            data = await globals.fetch(pathFromParams("/blog", action), "GET"); // prettier-ignore;
            store.dispatch(actions.setBlogs(data.blogPosts));
            break;

        case "GET_BLOG_POST":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId", action),
                "GET",
            ); // prettier-ignore;
            store.dispatch(
                actions.setBlogPost(action.blogPostId, data.blogPost),
            );
            break;

        case "PATCH_BLOG":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId", action),
                "PUT",
                { blogPost: value },
            ); // prettier-ignore;
            break;

        case "DELETE_BLOG":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId", action),
                "DELETE",
                value,
            ); // prettier-ignore;
            break;

        case "PATCH_BLOG_PROGRESS":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId/progress", action),
                "PUT",
                { progress: value },
            ); // prettier-ignore;
            break;

        case "PATCH_BLOG_CARD":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId/card", action),
                "PUT",
                { card: value },
            ); // prettier-ignore;
            break;

        case "PATCH_BLOG_ELEMENT":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId/elements/:elementId", action),
                "PUT",
                { element: value },
            ); // prettier-ignore;
            break;

        case "DELETE_BLOG_ELEMENT":
            data = await globals.fetch(
                pathFromParams("/blog/:blogPostId/elements/:elementId", action),
                "DELETE",
                value,
            ); // prettier-ignore;
            break;

        case "PATCH_BLOG_ELEMENT_DATA":
            data = await globals.fetch(
                pathFromParams(
                    "/blog/:blogPostId/elements/:elementId/data",
                    action,
                ),
                "PUT",
                value,
            ); // prettier-ignore;
            break;
    }

    return data;
};
