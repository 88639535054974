// @flow
import React, { Component } from "react";
import "./EditButton.scss";
import contextTypes from "../contextTypes";
import BigButton from "./BigButton";

export class EditButton extends Component {
    state = {};

    onClick = () => {
        const { editMode } = this.props;
        this.props.onClick(!editMode);
    };

    render() {
        const { i18n } = this.context;
        const { editMode } = this.props;

        const title = editMode ? i18n.viewMode : i18n.editMode;

        return (
            <BigButton on={!editMode} label={title} onClick={this.onClick} />
        );
    }
}

EditButton.contextTypes = contextTypes;

export default EditButton;
