export const data = (state = {}, action) => {
    switch (action.type) {
        case "SET_BLOG_ELEMENT_DATA":
            return action.value;

        case "PATCH_BLOG_ELEMENT_DATA":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const element = (state = {}, action) => {
    switch (action.type) {
        case "SET_BLOG_ELEMENT":
            return action.value;

        case "PATCH_BLOG_ELEMENT":
            return {
                ...state,
                ...action.value,
            };

        case "SET_BLOG_ELEMENT_DATA":
        case "PATCH_BLOG_ELEMENT_DATA":
            return {
                ...state,
                data: data(state.data, action),
            };

        default:
            return state;
    }
};

export const elements = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_BLOG_ELEMENTS":
            return action.value;

        case "SET_BLOG_ELEMENT_DATA":
        case "PATCH_BLOG_ELEMENT_DATA":
        case "SET_BLOG_ELEMENT":
        case "PATCH_BLOG_ELEMENT":
            return {
                ...state,
                [action.elementId]: element(state[action.elementId], action),
            };

        case "DELETE_BLOG_ELEMENT":
            newState = { ...state };
            delete newState[action.elementId];
            return newState;

        default:
            return state;
    }
};

export const progress = (state = {}, action) => {
    switch (action.type) {
        case "SET_BLOG_PROGRESS":
            return action.value;

        case "PATCH_BLOG_PROGRESS":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const card = (state = {}, action) => {
    switch (action.type) {
        case "SET_BLOG_CARD":
            return action.value;

        case "PATCH_BLOG_CARD":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const blog = (state = {}, action) => {
    switch (action.type) {
        case "SET_BLOG":
            return action.value;

        case "PATCH_BLOG":
            return {
                ...state,
                ...action.value,
            };

        case "SET_BLOG_PROGRESS":
        case "PATCH_BLOG_PROGRESS":
            return {
                ...state,
                progress: progress(state.progress, action),
            };
        case "SET_BLOG_CARD":
        case "PATCH_BLOG_CARD":
            return {
                ...state,
                card: card(state.card, action),
            };
        case "SET_BLOG_ELEMENT_DATA":
        case "PATCH_BLOG_ELEMENT_DATA":
        case "SET_BLOG_ELEMENTS":
        case "SET_BLOG_ELEMENT":
        case "PATCH_BLOG_ELEMENT":
        case "DELETE_BLOG_ELEMENT":
            return {
                ...state,
                elements: elements(state.elements, action),
            };

        default:
            return state;
    }
};

export const blogs = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_BLOGS":
            return action.value || {};

        case "SET_BLOG_PROGRESS":
        case "PATCH_BLOG_PROGRESS":
        case "SET_BLOG_CARD":
        case "PATCH_BLOG_CARD":
        case "SET_BLOG_ELEMENTS":
        case "SET_BLOG_ELEMENT":
        case "PATCH_BLOG_ELEMENT":
        case "DELETE_BLOG_ELEMENT":
        case "SET_BLOG_ELEMENT_DATA":
        case "PATCH_BLOG_ELEMENT_DATA":
        case "SET_BLOG":
        case "PATCH_BLOG":
            return {
                ...state,
                [action.blogPostId]: blog(state[action.blogPostId], action),
            };

        case "DELETE_BLOG":
            newState = { ...state };
            delete newState[action.blogPostId];
            return newState;

        default:
            return state;
    }
};
