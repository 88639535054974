// @flow
import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import config from "../config/config";
import routes from "../config/client.routes";
import contextTypes from "../layout_modules/contextTypes";
import colors from "../constants/colors";
import { isMobile } from "../utils/screen";
import { addError } from "../utils/analytics";
import components from "../config/client.components";
import ScreenLoader from "../layout_modules/screen-loader/ScreenLoader";
import screens from "../config/client.screens";
import { withSelector } from "../utils/withSelector";
import thunks from "../spark_modules/sparkThunks";

class App extends Component {
    static getDerivedStateFromError(e) {
        addError(e.stack, e.message);
    }

    constructor() {
        super();

        this.routes = routes.routes;
        this.screens = screens.screens;
    }

    getChildContext = () => {
        return {
            i18n: config.i18n,
            colors,
            showToast: this.props.showToast,
            isMobile: isMobile(),
            icons: config.icons,
            animations: config.animations,
            fontFamily: config.fontFamily,
            locale: config.locale,
            isRTL: config.locale === "he",
        };
    };

    componentDidMount() {
        this.props.dispatch({ type: "START" });
    }

    componentDidCatch(error, info) {
        const { componentStack = "" } = info || {};
        addError("", componentStack);
    }

    renderRoute = route => {
        const screenId = this.routes[route];
        const components = this.screens[screenId];

        return <Route key={route} exact path={route} component={components} />;
    };

    render() {
        const { toast } = this.props;

        const UserBar = components.userBar;
        const Toast = components.toast;
        const SideMenu = components.sideMenu;
        const RootModal = components.rootModal;

        return (
            <div className="App">
                <UserBar onMenu={this.props.showSideMenu} />

                <Suspense fallback={<ScreenLoader />}>
                    <Switch>
                        {Object.keys(this.routes).map(this.renderRoute)}
                    </Switch>
                </Suspense>

                <RootModal />
                <Toast toast={toast} />
                <SideMenu onClose={this.props.hideSideMenu} />
            </div>
        );
    }
}

App.childContextTypes = contextTypes;

export default withSelector("I", thunks)(App);
