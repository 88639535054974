import actions from "../sparkActions";

export default dispatch => {
    return {
        patchTag: (layerId, tagId, tag) => {
            return dispatch(actions.patchTag(layerId, tagId, tag));
        },
        deleteTag: (layerId, tag) => {
            return dispatch(actions.deleteTag(layerId, tag.id));
        },
        showAddTag: () => {
            dispatch(actions.setModal({ id: "addTag" }));
        },
        showEditTag: tagId => {
            dispatch(actions.patchCurrentId({ tagId }));
            dispatch(actions.setModal({ id: "editTag" }));
        },
        addTag: (overlay, tag) => {
            return dispatch(actions.addTag(overlay, 0, tag));
        },
    };
};
