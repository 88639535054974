export default (option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.isFuture = function() {
        const now = dayjsFactory();
        return this.diff(now) > 0;
    };

    dayjsFactory.futureDate = function(seconds = 0) {
        const ts = dayjsFactory.timestamp(seconds);
        return dayjsFactory(ts).unixFull();
    };
};
