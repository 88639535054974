export default (option, dayjsClass, dayjsFactory) => {
    dayjsFactory.duration = function(seconds) {
        const date = dayjsFactory()
            .set("hour", 0)
            .set("minute", 0)
            .set("second", seconds);

        return date.format("mm:ss");
    };
};
