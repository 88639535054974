// @flow
import React, { Component } from "react";
import "./BlogTopHeader.scss";
import contextTypes from "../contextTypes";
import dayjs from "dayjs";

export class BlogTopHeader extends Component {
    state = {};

    render() {
        const date = dayjs().celebrate();

        return (
            <div className="BlogTopHeader-container">
                <div className="title">DebDB</div>
                <div className="date">{date}</div>
            </div>
        );
    }
}

BlogTopHeader.contextTypes = contextTypes;

export default BlogTopHeader;
