let routes = {
    "/course": "course",
    "/about": "about",
    "/roadmap": "roadmap",
    "/logout": "logout",
    "/volunteers": "volunteer",
    "/contact": "contact",
    "/egg": "egg",
    "/blog/:blogPostIndex": "blogPost",
    "/blog": "blog",
    "/wiki": "wikiHome",
    "/admin": "admin",
    "/admin/*": "admin",
    "/suggestion": "suggestion",
    "/thankyou/:which": "thankyou",
    "/booklet/": "booklet",
    "/:debIndex": "root",
    "/wiki/:tagIndex": "wiki",
    "/canvas/:debIndex": "canvasPreview",
    "/shop": "shop",
    "/shop/:productId": "product",
    "/shop/payment": "purchase",
    "/shop/thankYou": "purchaseDone",
    "/": "browse",
};

export default {
    get routes() {
        return routes;
    },
    addRoute: (key, value) => {
        routes[key] = value;
    },
};
