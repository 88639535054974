import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";

export const bon = raw.bon;

export const bonsGroupByComment = createSelector(
    raw.bons,
    raw.tags,
    (_bons, _tags) => {
        return Object.values(_bons || {})
            .filter(bon => {
                const { isDeleted } = bon;
                return !isDeleted;
            })
            .reduce((output, bon) => {
                const { tagId, commentId } = bon || {};
                const tag = _tags[tagId] || {};

                output[commentId] = output[commentId] || [];
                output[commentId].push({
                    ...bon,
                    tag,
                });
                return output;
            }, {});
    },
);

export const bonsForComment = createSelector(
    bonsGroupByComment,
    appState.commentId,
    appState.sentenceId,
    (_bons, _commentId, _sentenceId) => {
        const arr = _bons[_commentId] || [];

        return arr
            .filter(bon => {
                const { selectedSentences = [], isDeleted } = bon;

                const okSentence =
                    _sentenceId < 0 ||
                    selectedSentences.indexOf(_sentenceId) >= 0;

                return okSentence && !isDeleted;
            })
            .sort((a, b) => {
                const selA = (a.selectedSentences || [])[0];
                const selB = (b.selectedSentences || [])[0];

                if (selA === selB) return 0;

                return selA > selB ? 1 : -1;
            });
    },
);
