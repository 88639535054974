export default (option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.celebrate = function() {
        switch (this.locale()) {
            case "he":
                return this.format("dddd, D לMMMM, YYYY");
            default:
                return this.format("dddd, MMMM D, YYYY");
        }
    };
};
