// @flow
import React, { Component } from "react";
import "./ButtonWithLoader.scss";
import contextTypes from "../contextTypes";
import classnames from "classnames";

export class ButtonWithLoader extends Component {
    render() {
        const { name, full, isLoading, disabled } = this.props;

        const className = classnames("ButtonWithLoader-container", {
            full,
        });

        const inner = isLoading ? "..." : this.props.children;

        return (
            <div className={className}>
                <button
                    name={name}
                    disabled={disabled}
                    onClick={this.props.onClick}
                    className={className}>
                    {inner}
                </button>
            </div>
        );
    }
}

ButtonWithLoader.contextTypes = contextTypes;

export default ButtonWithLoader;
