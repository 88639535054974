export const tag = (state = {}, action) => {
    switch (action.type) {
        case "SET_TAG":
        case "ADD_TAG":
            return action.value;

        case "PATCH_TAG":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const layer = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_TAGS":
            return action.value;

        case "SET_TAG":
        case "ADD_TAG":
        case "PATCH_TAG":
            return {
                ...state,
                [action.tagId]: tag(state[action.tagId], action),
            };

        case "DELETE_TAG":
            newState = { ...state };
            delete newState[action.tagId];
            return newState;

        default:
            return state;
    }
};

export const tags = (state = {}, action) => {
    switch (action.type) {
        case "SET_TAGS":
        case "SET_TAG":
        case "ADD_TAG":
        case "PATCH_TAG":
        case "DELETE_TAG":
            return {
                ...state,
                [action.layerId]: layer(state[action.layerId], action),
            };

        default:
            return state;
    }
};
