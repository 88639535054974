import actions from "../sparkActions";

export default dispatch => {
    return {
        patchExample: (layerId, exampleId, example) => {
            return dispatch(actions.patchExample(layerId, exampleId, example));
        },
        getExamples: layerId => {
            return dispatch(actions.getExamples(layerId));
        },
        showAddExample: () => {
            dispatch(actions.setModal({ id: "addExample" }));
        },
        showEditExample: exampleId => {
            dispatch(actions.patchCurrentId({ exampleId }));
            dispatch(actions.setModal({ id: "editExample" }));
        },
        addExample: (layerId, exampleId, example) => {
            return dispatch(actions.addExample(layerId, exampleId, example));
        },
    };
};
