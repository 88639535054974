export default class Canvas {
    constructor(selector, fontFamily) {
        this.canvas = document.querySelector(selector);
        this.ctx = this.canvas.getContext("2d");
        this._extraHeight = {};

        this._dx = 0;
        this._dy = 0;
        this._scale = 1;
        this.fontFamily = fontFamily;
    }

    set scale(value) {
        this._scale = value;
    }

    set dx(value) {
        this._dx = value;
    }

    set dy(value) {
        this._dy = value;
    }

    get filter() {
        return this._filter;
    }

    set filter(value) {
        this._filter = value;
    }

    set extraHeight(value) {
        this._extraHeight = value;
    }

    clear() {
        this._extraHeight = {};
        // this.canvas.width = this.canvas.width;

        this.ctx.save();
        this.ctx.globalCompositeOperation = "copy";
        this.ctx.strokeStyle = "transparent";
        this.ctx.beginPath();
        this.ctx.lineTo(0, 0);
        this.ctx.stroke();
        this.ctx.restore();
    }

    drawText(
        {
            x,
            y,
            fontSize = 18,
            fontColor = "brown",
            fontWeight = "normal",
            fontFamily = this.fontFamily,
            textAlign = "start",
            direction = "ltr",
            opacity = 1.0,
        },
        text,
    ) {
        this.ctx.globalAlpha = opacity;
        this.ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
        this.ctx.fillStyle = fontColor;
        this.ctx.textAlign = textAlign;
        this.ctx.direction = direction;
        this.ctx.fillText(text, x, y);
    }

    blur() {
        this.ctx.filter = "blur(10px)";
    }

    toLines(content, length) {
        let lines = [""],
            idx = 0;

        content.split(/\s/g).forEach(word => {
            if ((lines[idx] + " " + word).length > length) {
                idx++;
                lines[idx] = word;
            } else {
                lines[idx] += " " + word;
            }
        });

        if (lines.length > 10) {
            lines = lines.slice(0, 9);
            lines[8] += "...";
        }

        return lines;
    }

    drawContent(style, data) {
        const lines = this.toLines(data, 65);

        this._extraHeight[this.id] = lines.length * style.lineHeight;

        lines.forEach((line, index) => {
            style.y += style.lineHeight;
            this.drawText(style, line);
        });
    }

    getX(x) {
        return (x + this._dx) * this._scale;
    }

    getY(y) {
        return (y + this._dy) * this._scale;
    }

    getScaled(value) {
        return value * this._scale;
    }

    getScaledStyle(options) {
        let output = { ...options };
        const { x, y, width, height, lineHeight, r, fontSize } = options;

        if (x) {
            output.x = this.getX(x);
        }

        if (y) {
            output.y = this.getY(y);
        }

        if (width) {
            output.width = this.getScaled(width);
        }

        if (height) {
            output.height = this.getScaled(height);
        }

        if (lineHeight) {
            output.lineHeight = this.getScaled(lineHeight);
        }

        if (r) {
            output.r = this.getScaled(r);
        }

        if (fontSize) {
            output.fontSize = this.getScaled(fontSize);
        }

        return output;
    }

    drawRoundedRect({ x, y, width, height, r, color = "green", dropShadow }) {
        if (width < 2 * r) r = width / 2;
        if (height < 2 * r) r = height / 2;

        if (dropShadow) {
            this.ctx.filter = `drop-shadow(${dropShadow})`;
        }

        this.ctx.beginPath();
        this.ctx.moveTo(x + r, y);
        this.ctx.arcTo(x + width, y, x + width, y + height, r);
        this.ctx.arcTo(x + width, y + height, x, y + height, r);
        this.ctx.arcTo(x, y + height, x, y, r);
        this.ctx.arcTo(x, y, x + width, y, r);
        this.ctx.closePath();
        this.ctx.fillStyle = color;
        this.ctx.fill();

        this.ctx.filter = "";
    }

    drawAvatar = ({ x, y, width, clip }, url) => {
        var img = new Image();
        img.onload = () => {
            width = width || img.width;

            const r = Math.floor(width / 2);

            const center = {
                x: x + Math.floor(width / 2),
                y: y + Math.floor(width / 2),
            };

            if (clip) {
                this.clipCicle(center.x, center.y, r);
            }

            this.ctx.filter = this._filter;
            this.ctx.drawImage(img, x, y, width, width);
            this.ctx.filter = "none";
        };
        img.src = url;
    };

    drawImage({ x, y, width, height, color, rotate = 0, opacity = 1.0 }, url) {
        var img = new Image();

        img.onload = () => {
            width = width || img.width;
            height = height || img.height;

            this.ctx.filter = this._filter;

            if (color) {
                this.ctx.fillStyle = color;
            }

            if (rotate) {
                this.ctx.translate(x, y);
                this.ctx.rotate(rotate);
            }

            this.ctx.globalAlpha = opacity;

            this.ctx.drawImage(img, x, y, width, height);

            if (rotate) {
                this.ctx.rotate(-rotate);
                this.ctx.translate(-x, -y);
            }

            this.ctx.filter = "none";
        };

        img.onerror = err => {
            console.log("err -> ", err);
        };

        img.src = url;
    }

    drawLine({ x, y, width = 0, height = 0, opacity = 1.0, color = "#333" }) {
        this.ctx.beginPath();
        this.ctx.globalAlpha = opacity;
        this.ctx.lineWidth = 0.5;
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + width, y + height);
        this.ctx.strokeStyle = color;
        this.ctx.stroke();
    }

    clipCicle(x, y, r) {
        this.ctx.beginPath();
        this.ctx.arc(x, y, r, 0, 2 * Math.PI);
        this.ctx.clip();
    }

    draw(element, data) {
        const { style } = element,
            { yAddByLine = [] } = style;

        const scaledStyle = this.getScaledStyle(style);

        this.id = element.field;

        yAddByLine.forEach(key => {
            scaledStyle.y += this._extraHeight[key] || 0;
        });

        this.ctx.globalAlpha = 1.0;
        this.ctx.filter = this.filter;

        switch (element.type) {
            case "avatar":
                this.drawAvatar(scaledStyle, data);
                break;
            case "image":
                this.drawImage(scaledStyle, data || scaledStyle.url);
                break;
            case "content":
                this.drawContent(scaledStyle, data);
                break;
            case "line":
                this.drawLine(scaledStyle, data);
                break;
            case "rounded-rect":
                this.drawRoundedRect(scaledStyle, data);
                break;
            case "date":
            case "text":
                this.drawText(scaledStyle, data);

                break;
        }
        this.ctx.filter = "none";
    }
}
